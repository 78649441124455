import React, { useCallback, useEffect, useState } from 'react';
import session from '../../modules/session';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import { IReferralRecord } from '../../modules/rest';
import Loadable from '../../components/Loadable';
import { UserStates } from '../../modules/directory';
import {useTranslation} from "react-i18next";
import {Icon} from "../../components/fontawesome";
import moment from "moment";
import Dash from "../../components/Dash";
import Empty from "../../components/Empty";

const ReferralsList = () => {
  const {t} = useTranslation();
  const [source, setSource] = useState<string | undefined>();

  const [list, setList] = useState<IReferralRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetch = useCallback(() => {
    setLoading(true);
    API.Referrals.getList()
      .then(setList)
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setList, setLoading]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <div>
      <h4 className="mb-3">{t('AFFILIATE_PROGRAM')}</h4>
      <div className="card">
        <div className="card-body">
          <p dangerouslySetInnerHTML={{__html: t('YOU_RECEIVE')}}/>

          <div className="row" style={{ width: 700 }}>
            <div className="col">
              <label>{t('YOUR_LINK')}</label>
              <input
                type="url"
                readOnly
                className="form-control bg-light"
                value={`https://monetize.club/r/${session.user?.refTag}${source ? `.${source}` : ''}`}
              />
            </div>
            <div className="col-4">
              <label>{t('SOURCE')}</label>
              <input
                placeholder="mychannel"
                type="text"
                className="form-control"
                value={source || ''}
                maxLength={32}
                onChange={(e) => setSource(e.target.value || undefined)}
              />
            </div>
          </div>
        </div>
      </div>

      <h5 className="mb-3 mt-4">{t('MY_REFERRALS')}</h5>
      <div className="card">
        <div className="card-body">
          <Loadable loading={loading}>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>E-mail</th>
                  <th>{t('STATUS')}</th>
                  <th>{t('SOURCE')}</th>
                  <th></th>
                  <th>{t('CHANNELS')}</th>
                  <th>{t('TRACKS')}</th>
                  <th>{t('VIEWS')}</th>
                </tr>
              </thead>
              <tbody>
                {list.map((r) => (
                  <tr key={r.id}>
                    <td>
                      <span className="badge bg-success">{r.id}</span>
                    </td>
                    <td>{r.email}</td>
                    <td className={`user-status-${r.status}`}>
                      {t(UserStates[r.status])}
                      {r.rejectReason ? <span title={r.rejectReason}> <Icon icon={['fas', 'question-circle']} /></span> : null}
                      </td>
                    <td>{r.source ?? <Dash />}</td>
                    <td>{moment(r.createdAt).format('DD.MM.YYYY')}</td>
                    <td>{r.channels}</td>
                    <td>{r.tracks}</td>
                    <td>{r.views}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Empty show={!loading && list.length === 0} />
          </Loadable>
        </div>
      </div>
    </div>
  );
};

export default ReferralsList;
