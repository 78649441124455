import {FC, useCallback} from 'react';

import {ITrack} from '../modules/rest';
import {thumb} from '../modules/utils';
import './Player.scss';
import {Icon} from './fontawesome';

interface Props {
  track: ITrack;
  onChange?: (id?: number) => void;
  active?: boolean;
}

const Player: FC<Props> = ({track, onChange, active}) => {
  const click = useCallback((e: any) => {
    e.stopPropagation();
    onChange && onChange(active ? undefined : track.id);
  }, [onChange, track.id, active]);

  return (
    <div onClick={click} className={`player ${active ? 'player-active' : ''}`}>
      <img alt="" src={thumb(track.cover?.id, 96)}/>
      {active ? (
        <>
          <div className="ctrl ctrl-animation">
            <div className="box">
              <div className="bar"/>
              <div className="bar"/>
              <div className="bar"/>
            </div>
          </div>
          <div className="ctrl ctrl-pause">
            <Icon icon={['fas', 'pause']}/>
          </div>
          <audio src={track.sample?.url!} autoPlay={true} controls={false}/>
        </>
      ) : (
        <div className="ctrl ctrl-play">
          <Icon icon={['fas', 'play']}/>
        </div>
      )}
    </div>
  );
};

export default Player;
