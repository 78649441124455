import { IAuthor, IBatch, ITrack } from '../../modules/rest';
import asyncModal from 'react-async-modal';
import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import Track from '../../components/Track';
import Button from '../../components/Button';
import Loadable from '../../components/Loadable';
import Empty from '../../components/Empty';
import {useTranslation} from "react-i18next";

interface Props {
  author: IAuthor;

  resolve(batch: IBatch | null): void;
}

const CreateBatchModal: FC<Props> = ({ author, resolve }) => {
  const {t} = useTranslation();
  const [tracks, setTracks] = useState<ITrack[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    API.Batches.getApprovedTracksForBatch(author.id).then(setTracks).catch(toast.error);
  }, [author.id, setTracks]);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Batches.createBatch(author.id)
        .then((batch) => {
          toast.success(t('CONTRACT_CREATED'));
          resolve(batch);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [author.id, setLoading, resolve, t]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <h5 className="mb-4">{t('NEW_CONTRACT')}</h5>

        {!tracks && <Loader text="LIST_AVAILABLE_TRACK_LOADING" />}

        {tracks && tracks.length === 0 && <Empty show />}

        {tracks && tracks.length > 0 && (
          <>
            <p>{t('BUTCH_TRACKS_MODAL_TEXT', {count: tracks.length})}</p>
            <div className="row">
              {tracks?.map((t) => (
                <div className="col-4 mb-3" key={t.id}>
                  <Track track={t} />
                </div>
              ))}
            </div>
          </>
        )}

        <div className="d-flex justify-content-end mt-3">
          <Button text="CONTINUE" type="submit" disabled={tracks?.length === 0} />
          <Button text="CANCEL" color="secondary" className="ms-2" outline onClick={() => resolve(null)} />
        </div>
      </form>
    </Loadable>
  );
};

const openCreateBatchModal = (author: IAuthor): Promise<IBatch | null> => {
  return asyncModal(CreateBatchModal, { author }, { styles: { modal: { width: 800 } } });
};

export { openCreateBatchModal };
