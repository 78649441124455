import React, {FC} from 'react';
import {Icon} from './fontawesome';
import {IPagedData} from '../modules/rest';
import {useTranslation} from "react-i18next";

interface Props {
  all?: boolean;
  limits?: number[];
  pager?: IPagedData<any>;

  onPageChange(page: number): void;

  onLimitChange?(limit: number): void;
}

const Pagination: FC<Props> = ({
                                 pager,
                                 limits = [15, 50, 100, 500, 1000],
                                 onPageChange,
                                 onLimitChange,
                                 all,
                               }) => {
  const {t} = useTranslation();
  if (!pager?.count) return null;

  return (
    <div className="d-flex align-items-center justify-content-between small text-muted bg-white bdrs-20 p-2">
      <div className='d-flex flex-nowrap'>
        <button
          className="btn btn-outline btn-sm"
          disabled={pager.page <= 1}
          onClick={() => onPageChange(pager.page - 1)}
        >
          <Icon icon={['fas', 'arrow-left']} fixedWidth/>
        </button>
        <button
          className="btn btn-outline btn-sm ms-2"
          disabled={pager.pages !== null && pager.page >= pager.pages}
          onClick={() => onPageChange(pager.page + 1)}
        >
          <Icon icon={['fas', 'arrow-right']} fixedWidth/>
        </button>
      </div>
      <span>
        {t('PAGE')}: {pager.page}
        {pager.count !== null && <> {t('from')} {pager.pages}</>}
      </span>
      <div className='d-flex align-items-center pe-1'>
        <div>{t('RECORDS')}</div>
        {!!onLimitChange && (
          <select
            className="form-select form-select-sm pagination-limit"
            value={pager.limit}
            style={{maxWidth: 80}}
            onChange={(e) => onLimitChange(Number(e.target.value))}
          >
            {!limits.includes(pager.limit) && <option value={pager.limit}>{pager.limit}</option>}
            {limits.map((limit) => (
              <option key={limit} value={limit}>
                {limit}
              </option>
            ))}
            {/*{all ? <option value={9999999}>{t('ALL')}</option> : null}*/}
          </select>
        )}
        {pager.count !== null && <span className='ps-2 text-nowrap'>{t('from')} {pager.count}</span>}
      </div>
    </div>
  );
};

export default Pagination;
