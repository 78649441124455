import React, {useEffect, useMemo, useState} from 'react';
import {Icon} from './fontawesome';
import asyncModal from 'react-async-modal';
import Loader from './Loader';
import {IDoc} from '../modules/rest';
import {API} from '../modules/api';
import './Helper.scss';
import {useTranslation} from "react-i18next";

const Helper = ({tag, className = ''}: { tag: string; className?: string }) => {
  return (
    <span className={`helper ${className}`} onClick={() => showContextHelp(tag)}>
      <Icon icon={['fas', 'question']} fixedWidth/>
    </span>
  );
};

const ContextHelpModal = ({tag}: { tag: string }) => {
  const {t,i18n} = useTranslation()
  const [doc, setDoc] = useState<IDoc|undefined|null>();
  const langTag = useMemo(() => `${i18n.language}.${tag}`, [i18n.language, tag]);

  useEffect(() => {
    API.Help.getByTag(langTag)
      .then(setDoc)
      .catch(() => setDoc(null));
  }, [langTag, setDoc]);

  if (doc === undefined) {
    return (
      <div className="p-5">
        <Loader size="2x"/>
      </div>
    );
  }
  if (doc === null) {
    return (
      <div style={{minWidth: 400}}>
        <h5 className="mb-4">{t('UPS')}</h5>
        <p>{t('NO_HELP')}</p>
        <div className='small mt-3 text-muted'>{langTag}</div>
      </div>
    );
  }
  return (
    <div>
      <h5 className="mb-4">{doc.title}</h5>
      <div dangerouslySetInnerHTML={{__html: doc.content || ''}}/>
      <div className='small mt-3 text-muted'>{langTag}</div>
    </div>
  );
};

const showContextHelp = (tag: string): Promise<void> => {
  return asyncModal(ContextHelpModal, {tag});
};

export default Helper;
