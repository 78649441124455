import { EVideoType, ITrack, IVideo } from '../../modules/rest';
import asyncModal from 'react-async-modal';
import { FormEvent, useCallback, useState } from 'react';
import Loadable from '../../components/Loadable';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import Track from '../../components/Track';
import Checkbox from '../../components/Checkbox';
import { VideoTypes } from '../../modules/directory';
import {useTranslation} from "react-i18next";

const AddVideoModal = ({ track, resolve }: { track: ITrack; resolve(video: IVideo | null): void }) => {
  const {t} = useTranslation();
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showForce, setShowForce] = useState<boolean>(false);
  const [force, setForce] = useState<boolean>(false);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Videos.addVideo({ url, trackId: track.id, force })
        .then((video) => {
          toast.success(t('CLIP_ADDED_SUCCESS'));
          resolve(video);
        })
        .catch((error) => {
          toast.error(error);
          if (API.getStatusCode() === 404) {
            setShowForce(true);
          }
        })
        .finally(() => setLoading(false));
    },
    [url, setLoading, track.id, resolve, force, t]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit} style={{ width: 400 }}>
        <h5 className="mb-4">{t('ADD_CLIP_TO_TRACK')}</h5>
        <div className="mb-3">
          <label className="req">{t('TRACK')}</label>
          <Track track={track} />
        </div>
        <div className="mb-3">
          <label className="req">
            {t('LINK_TO')} <span className='text-lowercase'>{t(track.videoType ? VideoTypes[track.videoType] : 'CLIP')}</span>
          </label>
          <input
            type="url"
            className="form-control"
            required
            autoFocus
            placeholder={
              track.videoType === EVideoType.Short
                ? 'https://www.youtube.com/shorts/INtgdE6eqOQ'
                : 'https://www.youtube.com/watch?v=UtakVRO4u9A'
            }
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              setShowForce(false);
            }}
          />
        </div>

        {showForce && (
          <div className="mb-3">
            <Checkbox label={'CLIP_EXIST'} checked={force} onChange={setForce} />
            <div className="form-text">
              {t('CLIP_PUBLISH_STUB')}
            </div>
          </div>
        )}

        <div className="d-flex justify-content-end mt-4">
          <button type="submit" className="btn btn-primary me-2">
            {t('ADD')}
          </button>
          <button type="button" onClick={() => resolve(null)} className="btn btn-outline-secondary">
            {t('CLOSE')}
          </button>
        </div>
      </form>
    </Loadable>
  );
};

const addVideoModal = (track: ITrack): Promise<IVideo | null> => asyncModal(AddVideoModal, { track });

export { addVideoModal };
