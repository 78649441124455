import { NavLink, useNavigate, useParams } from 'react-router-dom';
import AuthorCompose from './compose';
import { useEffect, useState } from 'react';
import { IAuthor } from '../../../modules/rest';
import Loader from '../../../components/Loader';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import {useTranslation} from "react-i18next";

const EditAuthor = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ author: string }>();
  const [author, setAuthor] = useState<IAuthor>();

  useEffect(() => {
    API.Authors.getAuthor(params.author!).then(setAuthor).catch(toast.error);
  }, [setAuthor, params.author]);

  if (!author) return <Loader />;
  return (
    <div>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <NavLink to="/author/">{t('PERFORMERS')}</NavLink>
        </li>
        <li className="breadcrumb-item active">{t('EDIT_PERFORMERS')}</li>
      </ul>
      <AuthorCompose mode="edit" author={author} onChange={() => navigate(`/author/`)} />
    </div>
  );
};

export default EditAuthor;
