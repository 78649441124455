import {ITrack} from '../modules/rest';
import Player from './Player';
import React, {FC} from 'react';
import './TrackBlock.scss';
import {Genres} from '../modules/directory';
import {buyTrackModal} from '../modals/channels/BuyTrackModal';
import {Icon} from './fontawesome';
import {formatNumber} from '../modules/utils';
import {useTranslation} from "react-i18next";
import OneMinTrack from "./OneMinTrack";
import {TrackTitle, TrackTypeFromContentId} from "./Track";

interface Props {
  track: ITrack;
  free?: boolean;

  onChange?(track: ITrack|null): void;

  className?: string;
  changePlayedTrackId: (id?: number) => void;
  activeTrackId?: number;
}

const TrackBlock: FC<Props> = ({track, free, onChange, className, changePlayedTrackId, activeTrackId}) => {
  const {t} = useTranslation();

  const activeTrack = activeTrackId === track.id;

  return (
    <div className={`track-block${free ? ' free' : ''} ${className || ''}`}>
      <div className="d-flex align-items-center position-relative p-1">
        <Player track={track} active={activeTrack} onChange={changePlayedTrackId}/>
        <div className='flex-grow-1'>
        <TrackTitle track={track} active={activeTrack} width={track.isMusic || true ? 136 : 168} className='pe-2'/>
        </div>
        <OneMinTrack visible={track.isMusic || true} className='align-self-start'/>
      </div>
      <div className="tags_type">
        <div className="tags">
          <span title={t(Genres[track.genre!]) || ''}>{t(Genres[track.genre!])}</span>
          <span title={t(`langs:${track.language}`) || ''}>{t(`langs:${track.language}`)}</span>
        </div>
        <TrackTypeFromContentId hasContentId={track.hasContentId} />
      </div>
      <div className='px-1 my-2'>
        <div className="separator"/>
      </div>
      <div className="price">
        <div className='d-flex align-items-center'>
          <Icon icon={['fas', 'tag']} fixedWidth/>
          <span className="bold-14 ps-1">{t('PRICE')}:</span>
          <span className="value">{formatNumber(free ? 0 : track.buyPrice!)} ₽</span>
        </div>
        {/*{free && <Helper tag="tracks.free" className="darker"/>}*/}
      </div>
      <div className='track-block-btn'>
        {free ? (
          <button className="btn btn-success btn-sm w-100" onClick={() => buyTrackModal(track, free).then(onChange)}>
            <span className="text">{t('USE_FREE')}</span>
          </button>
        ) : (
          <button className="btn btn-success btn-sm w-100" onClick={() => buyTrackModal(track).then(onChange)}>
            <span className="btn-icon">
              <Icon icon={['fas', 'shopping-cart']} fixedWidth/>
            </span>
            <span className="text">{t('BUY_FOR')} {formatNumber(free ? 0 : track.buyPrice!)} ₽</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default TrackBlock;
