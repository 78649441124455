import React, {FC, useEffect, useLayoutEffect, useRef, useState} from 'react';
import './header.scss';
import session from '../../modules/session';
import {observer} from 'mobx-react';
import Link from '../Link';
import logo from '../../assets/images/logo-black-square.svg';
import {EUserRole} from '../../modules/rest';
import {Icon} from '../fontawesome';
import {formatNumber} from '../../modules/utils';
import Notifications from '../Notifications';
import {openProfileModal} from '../../modals/ProfileModal';
import AccountSwitch from '../AccountSwitch';
import {useTranslation} from "react-i18next";

interface MenuObj {
  to: string;
  exact: boolean;
  text: string;
  className: string;
}

const howWorkLink: any = {
  ru: '/help/29/',
  en: '/help/31/',
}

interface Props {

}

const Header: FC<Props> = () => {
  const {t, i18n} = useTranslation();
  const _wrap: any = useRef(null);
  const [mainMenu, setMainMenu] = useState<MenuObj[]>([]);
  const [hiddenMenu, setHiddenMenu] = useState<MenuObj[]>([]);

  useLayoutEffect(() => {
    computedMenus();
    window.addEventListener('resize', computedMenus);
    return () => window.removeEventListener('resize', computedMenus);
  }, []);

  const computedMenus = () => {
    const offset = 24;
    let wrapWidth = _wrap?.current?.offsetWidth;
    let currentMenuWidth = offset;
    const mainMenu: MenuObj[] = [];
    const hiddenMenu: MenuObj[] = [];
    const arr = Array.from(_wrap?.current?.children) || [];
    const allItemsWidth: any = arr.reduce((accumulator, item: any) => accumulator + item?.offsetWidth, offset);
    if (allItemsWidth >= wrapWidth) {
      wrapWidth -= 90
    }
    arr.forEach((item: any) => {
      const itemWidth = item.offsetWidth;
      const itemObj = {
        to: item.getAttribute('data-targetpath'),
        className: item.getAttribute('data-targetclassname'),
        exact: Boolean(item.getAttribute('data-targetexact')),
        text: item.innerText,

      };
      if (itemWidth + currentMenuWidth <= wrapWidth) {
        mainMenu.push(itemObj);
      } else {
        hiddenMenu.push(itemObj);
      }
      currentMenuWidth += itemWidth;
    });
    setMainMenu(mainMenu);
    setHiddenMenu(hiddenMenu);
  };

  useEffect(() => {
    computedMenus();
  }, [i18n?.language])

  if (!session.user) return null;
  return (
    <header>
      <div className='d-flex align-items-center h-100 w-100'>
        <Link to='/'>
          <img src={logo} alt='Monetize' className='logo'/>
        </Link>
        <div className='position-relative flex-grow-1 w-100 align-items-center d-flex'>
          <div className='computed-menu' ref={_wrap}>
            {session.user.role === EUserRole.Owner && (
              <>
                <div data-targetpath='/owner/tracks/my/'>{t('MY_TRACKS')}</div>
                <div data-targetexact={'1'} data-targetpath='/owner/tracks/'>
                  {t('CATALOG')}
                </div>
                <div data-targetpath='/owner/stats/'>{t('STATS')}</div>
              </>
            )}
            {session.user.role === EUserRole.Author && (
              <>
                <div data-targetpath='/author/'>{t('MY_TRACKS')}</div>
              </>
            )}
            {session.user.role === EUserRole.Scout && (
              <>
                <div data-targetpath={howWorkLink[i18n.language]} data-targetclassname='disabled-active'>{t('HOW_WORK')}</div>
              </>
            )}
            {[EUserRole.Owner, EUserRole.Author, EUserRole.Scout].includes(session.user.role) && (
              <>
                <div data-targetpath='/billing/'>{t('BILLING')}</div>
                <div data-targetpath='/referral/'>{t('PARTNERS')}</div>
                <div data-targetpath='/help/'>{t('HELP')}</div>
              </>
            )}
          </div>
          <nav className='nav'>
            {mainMenu.map(item => (
              <Link key={item.to} to={item.to} exact={item.exact} className={item.className || ''}>{t(item.text)}</Link>
            ))}
            {!hiddenMenu?.length
              ?
              null
              :
              <div className={`hidden-menu`}>
                <div className='menu-more'>
                  <span className='pe-2'>{t('MORE')}</span>
                  <Icon icon={['fas', 'chevron-down']} fixedWidth/>
                </div>
                <div className='hidden-menu-list'>
                  {hiddenMenu.map(item => (
                    <Link key={item.to} to={item.to} exact={item.exact} className='text-center '>{t(item.text)}</Link>
                  ))}
                </div>
              </div>
            }
          </nav>
        </div>
      </div>
      <div className='d-flex align-items-center ws-nw'>
        {[EUserRole.Owner, EUserRole.Author].includes(session.user.role) && (
          <div className='balance me-4 d-flex align-items-center me-5'>
            <span className='text-muted'>
              <Icon icon={['fas', 'coins']} size={session.user.balanceUsd && i18n.language === 'en' ? '2x' : '1x'} fixedWidth className='me-1'/>
            </span>
            <div className="ms-2" style={{lineHeight: 1.1}}>
              <div className="x-small text-muted">{t('BALANCE')}</div>
              <span className="bold">{formatNumber(session.user.balance!, 2)} ₽</span>
              {i18n.language === 'en' ? <div className='text-right small text-muted'>≈ $ {formatNumber(session.user.balanceUsd!, 2)}</div> : null}
            </div>
            {session.user.credit ? <>
              <div className="ms-3" style={{lineHeight: 1.1}}>
                <div className="x-small text-muted">{t('CREDIT')}</div>
                <span className="bold">{formatNumber(session.user.credit!, 2)} ₽</span>
                {i18n.language === 'en' ? <div className='text-right small text-muted'>≈ $ {formatNumber(session.user.creditUsd!, 2)}</div> : null}
              </div>
            </> : null}
            {session.user.role === EUserRole.Owner && (
              <Link to='/billing/charge/' className='ms-3 nav-btn'>
                <Icon icon={['fas', 'plus']} fixedWidth/>
              </Link>
            )}

          </div>
        )}

        <Notifications/>

        <button onClick={openProfileModal} className='nav-btn ms-3'>
          <Icon icon={['fas', 'cog']}/>
        </button>

        <button onClick={session.logout} className='nav-btn ms-3 me-5'>
          <Icon icon={['fas', 'sign-out-alt']}/>
        </button>
        <AccountSwitch/>
      </div>
    </header>
  );
};


export default observer(Header);
