import React, {FC} from 'react';
import { Icon } from './fontawesome';
import {useTranslation} from "react-i18next";

interface Props {
  show: boolean;
  text?: string;
  className?: string
}

const Empty:FC<Props> = ({ show, text = 'NO_DATA', className } ) => {
  const {t} = useTranslation();
  if (!show) return null;
  return (
    <div className={`alert alert-warning ${className || ''}`}>
      <Icon icon={['fas', 'info-circle']} fixedWidth />
      <span className="ms-1">{t(text)}</span>
    </div>
  );
};

export default Empty;
