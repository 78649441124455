import { FC } from 'react';
import { Icon } from './fontawesome';
import {useTranslation} from "react-i18next";

interface Props {
  url: string;
  className?: string;
}

const Help: FC<Props> = ({ url, className }) => {
  const {t} = useTranslation();
  return (
    <a href={url} target="_blank" rel="noreferrer" className={`btn btn-outline-primary ${className ?? ''}`}>
      <Icon icon={['fas', 'question-circle']} fixedWidth className="me-1" />
      <span>{t('SECTION_HELP')}</span>
    </a>
  );
};

export default Help;
