import React, {FC, useState} from 'react';
import {ITrack} from "../../../modules/rest";
import {ArrowContainer, Popover} from 'react-tiny-popover';
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../../modules/utils";
import session from "../../../modules/session";
import Timer from "../../../components/Timer";
import {buyTrackModal} from "../../../modals/channels/BuyTrackModal";
import {Icon} from "../../../components/fontawesome";
import ChartPie from '@garvae/react-pie-chart'

interface Props {
  track: ITrack;
  onSuccessBuy: () => void;
}

const MyTracksFreeBtn: FC<Props> = ({track, onSuccessBuy}) => {
  const {t} = useTranslation();
  const chart = React.useRef<HTMLDivElement>(null)
  const [opened, setOpened] = useState(false);

  const handleBuy = () => {
    setOpened(false);
    buyTrackModal(track).then(onSuccessBuy)
  }

  const limitViews = (session?.limits?.free[track.videoType!] || 0);
  const viewsLimitsStr = `${formatNumber(track.views!)} ${(track?.videoType && session.limits) && `/ ${formatNumber(limitViews)}`}`

  return (
    <div onClick={e => e.stopPropagation()}>
      <Popover
        isOpen={opened}
        padding={4}
        positions={['top', 'bottom', 'left', 'right']}
        onClickOutside={() => setOpened(false)}
        content={({position, childRect, popoverRect}) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'white'}
            arrowSize={10}
          >
            <div className='my__tracks__free__btn-popover'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className="my__tracks__free__btn-popover-title bold-14">
                  {t('FREE_TERMS')}
                </div>
                <div className='my__tracks__free__btn-popover-views'>
                  {viewsLimitsStr}
                </div>
              </div>
              <div className='text-muted-14 pt-3'>
                {t('GET_VIEWS_TIME', {limit: formatNumber(limitViews)})}
              </div>
              <div className="separator my-2"/>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='text-muted-14'>
                  {t('TIME_LEFT')}
                </div>
                <Timer date={track.leasedTill!}/>
              </div>
              <button className="button-buy mt-3" onClick={handleBuy}>
              <span className="icon">
                <Icon icon={['fas', 'shopping-cart']} fixedWidth/>
              </span>
                <div className='flex-grow-1 text-center pe-4'>
                  {t('REDEEM_BY')} {formatNumber(track.buyPrice!)} ₽
                </div>
              </button>
            </div>
          </ArrowContainer>
        )}
      >
        <div className={`my__tracks__free__btn${opened ? ' popover-opened' : ''}`} onClick={(e) => {
          e.stopPropagation();
          setOpened(true);
        }}>
          <div className='my__tracks__free__btn-chart' ref={chart}>
            <ChartPie
              parentRef={chart}
              data={[{
                color: '#8CEF9C',
                order: 1,
                segmentId: '001',
                value: track.views!,
              },
                {
                  color: 'transparent',
                  order: 2,
                  segmentId: '002',
                  value: limitViews - track.views! < 0 ? 0 : limitViews - track.views!,
                }
              ]}/>
          </div>
          <div className="my__tracks__free__btn-bar"/>
          {opened
            ?
            null
            :
            <div className="my__tracks__free__btn-popover-views">
              {viewsLimitsStr}
            </div>
          }
        </div>
      </Popover>
    </div>
  );
}

export default MyTracksFreeBtn;