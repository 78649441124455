import {FC, ReactNode} from 'react';
import './Marquee.scss';

interface Props {
  width?: number;
  children: ReactNode;
  active: boolean
}

const Marquee: FC<Props> = ({children, active = true, width}) => {
  if (!active) return <>{children}</>;
  return (
    <div className="marquee">
      <div className="items-wrap" style={{maxWidth: width || 200}}>
        <div className="items marquee">
          <div className="item">{children}</div>
          <div className="item">{children}</div>
          <div className="item">{children}</div>
          <div className="item">{children}</div>
          <div className="item">{children}</div>
          <div className="item">{children}</div>
          <div className="item">{children}</div>
          <div className="item">{children}</div>
          <div className="item">{children}</div>
          <div className="item">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Marquee;
