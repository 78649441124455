import React, {FC, ReactNode, useState} from 'react';
import './DropDown.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon } from './fontawesome';
import { ArrowContainer, Popover, PopoverPosition } from 'react-tiny-popover';
import {useTranslation} from "react-i18next";

interface Props  {
  children: ReactNode;
  positions?: PopoverPosition[];
}

const DropDown:FC<Props> = ({ children, positions } ) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className={`drop-down ${opened ? 'opened' : ''}`}>
      <Popover
        isOpen={opened}
        positions={positions || ['bottom', 'top', 'left', 'right']}
        onClickOutside={() => setOpened(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'white'}
            arrowSize={10}
          >
            <div className="drop-down__items" onClick={() => setOpened(false)}>
              {children}
            </div>
          </ArrowContainer>
        )}
      >
        <div className="drop-down__button btn btn-secondary" onClick={() => setOpened(!opened)} />
      </Popover>
    </div>
  );
};

const DropDownItem = ({
  icon,
  text,
  onClick,
  danger,
}: {
  icon: IconProp;
  text: string;
  onClick(): void;
  danger?: boolean;
}) => {
  const {t} = useTranslation();
  return (
    <div onClick={onClick} className={`drop-down__items__item ${danger ? 'danger' : ''}`}>
      <span className="icon">
        <Icon icon={icon} fixedWidth />
      </span>
      <span className="text">{t(text)}</span>
    </div>
  );
};

export default DropDown;
export { DropDown, DropDownItem };
