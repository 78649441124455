import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';

import { EUserRole } from './modules/rest';
import session from './modules/session';
import Loader from './components/Loader';
import Button from './components/Button';
import LoginRoute from './routes/Login';
import { openProfileModal } from './modals/ProfileModal';
import Help from './routes/Help';
import { UserRoles } from './modules/directory';
import { adminUrl } from './modules/api';
import Header from './components/Header';
import OwnerRoutes from './routes/owner';
import AuthorRoutes from './routes/author';
import BillingRoutes from './routes/billing';
import ReferralRoutes from './routes/referral';
import ProfileRoutes from './routes/profile';
import PasswordRoute from './routes/Password';
import { useTranslation } from 'react-i18next';
import CheckAuth from './routes/CheckAuth';
import ApiKeyPage from './routes/ApiKeyPage';
import NeedPaymentPanel from './components/NeedPaymentPanel';

const App = observer(() => {

  const { t } = useTranslation();
  if (!session.ready)
    return (
      <div className='app p-5 d-flex justify-content-center'>
        <Loader text='SESSION_RESTORE' />
      </div>
    );
  return (
    <div className='app'>
      {session.user && <Header />}
      <div className='content container-fluid' id='content'>
        {session.user?.email.match(/^[a-z0-9]{8,32}@monetize\.club$/) && (
          <div className='alert alert-danger d-flex justify-content-between align-items-center '>
            <span>{t('MUST_ACTUAL_EMAIL')}</span>
            <Button text='GO_SETTINGS' size='sm' onClick={() => openProfileModal()} />
          </div>
        )}
        <NeedPaymentPanel user={session.user} />
        <Routes>
          <Route path='/checkAuth/*' element={<CheckAuth />} />
          {session.user ? (
            <>
              <Route path='/apikey/*' element={<ApiKeyPage />} />
              {session.user.role === EUserRole.Owner && <Route path='/owner/*' element={<OwnerRoutes />} />}
              {session.user.role === EUserRole.Author && <Route path='/author/*' element={<AuthorRoutes />} />}
              {(session.user.role === EUserRole.Admin || session.user.role === EUserRole.Registrar) &&
              <Route path='*' element={<div>
                <h4 className='mb-4'>{t(UserRoles[session.user.role])}</h4>
                <p className='lead text-muted'>{t('USER_ACC_MOVED')}<br /><a href={adminUrl}>{adminUrl}</a></p>
              </div>} />}

              {[EUserRole.Owner, EUserRole.Author, EUserRole.Scout].includes(session.user.role) && (
                <>
                  <Route path='/billing/*' element={<BillingRoutes />} />
                  <Route path='/referral/*' element={<ReferralRoutes />} />
                </>
              )}
              <Route path='/profile/*' element={<ProfileRoutes />} />
              <Route path='/help/:id/' element={<Help />} />
              <Route path='/help/' element={<Help />} />
              <Route path='/scout/*' element={<Navigate to='/referral/' replace />} />
              <Route path='*' element={<Navigate to={`/${session.user.role}/`} replace />} />
            </>
          ) : (
            <>
              <Route path='/login' element={<LoginRoute />} />
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Route path='/password' element={<PasswordRoute />} />
              <Route path='*' element={<Navigate to='/login' replace />} />
            </>
          )}
        </Routes>

      </div>
    </div>
  );
});

export default App;
