import React, {Component} from 'react';

interface Props {
  outsideClickEvent: (e: any) => void;
  onClick?: (e: any) => void;
  className?: string;
  disable?: boolean;
  children: any;
}

class OutsideClick extends Component<Props> {
  wrapperRef:any;
  constructor(props: Props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  setWrapperRef(node: any) {
    this.wrapperRef = node;
  }

  handleClickOutside(event: any) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !this.props.disable) {
      this.props.outsideClickEvent(event);
    }
  }

  render() {
    return (
      <div ref={this.setWrapperRef} className={this.props.className || ''} onClick={this.props.onClick}>
        {this.props.children}
      </div>
    );
  }
}

export default OutsideClick;
