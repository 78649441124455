import React, {FC, FormEvent, useCallback, useEffect, useState} from 'react';

import {runInAction} from 'mobx';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react';
import {openSideModal} from '../components/SideModal';
import session from '../modules/session';
import {API} from '../modules/api';
import Loadable from '../components/Loadable';
import Button from '../components/Button';
import {useTranslation} from "react-i18next";
import moment from "moment";

interface Props {
  resolve: () => void;
}

const ProfileModal: FC<Props> = observer(({resolve}) => {
  const {t, i18n} = useTranslation();
  const [telegram, setTelegram] = useState<string>(session.user?.telegram || '');
  const [email, setEmail] = useState<string|undefined>();
  const [password, setPassword] = useState<string|undefined>();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Users.updateProfile({telegram, email, password: password || undefined})
        .then((user) => {
          runInAction(() => (session.user = user));
          toast.success(t('PARAMS_SAVED'));
          resolve();
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [telegram, email, password, setLoading, resolve, t]
  );

  useEffect(() => {
    const timer = setTimeout(() => setDisabled(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [setDisabled]);

  const handleChangeLanguage = useCallback((ln: string) => () => {
    localStorage.setItem('ln', ln);
    i18n.changeLanguage(ln);
    moment.locale(ln);
  }, [i18n])

  return (
    <Loadable loading={disabled || loading} className='d-flex flex-column flex-grow-1'>
      <h3 className="mb-5">{t('ACC_SETTINGS')}</h3>
      <form onSubmit={submit} style={{width: 300}} className='flex-grow-1'>
        <div className="mb-3">
          <label className="req">Telegram</label>
          <input
            disabled={disabled}
            type="text"
            value={telegram || ''}
            onChange={(e) => setTelegram(e.target.value)}
            required
            className="form-control"
            placeholder="@mynameis"
          />
        </div>

        <div className="mb-3 mt-5">
          <label>{t('CHANGE_EMAIL')}</label>
          <input
            type="email"
            autoComplete="off"
            value={email || ''}
            onChange={(e) => setEmail(e.target.value || undefined)}
            className="form-control"
            placeholder="my@email.com"
          />
        </div>
        <div className="mb-3">
          <label>{t('CHANGE_PASSWORD')}</label>
          <input
            autoComplete="new-password"
            disabled={disabled}
            type="password"
            value={password || ''}
            onChange={(e) => setPassword(e.target.value || undefined)}
            className="form-control"
            placeholder="******"
          />
        </div>

        <div>
          <Button type="submit" icon={['fas', 'save']} text="SAVE"/>
        </div>
      </form>
      {/*<div onClick={() => navigate('/apikey/')}>{t('API_KEY')}</div>*/}
      <div className='d-flex mt-2'>
        <div className={`sider-ln${i18n.language === 'ru' ? ' active' : ''}`}
             onClick={handleChangeLanguage('ru')}>RU
        </div>
        <div className={`sider-ln${i18n.language === 'en' ? ' active' : ''}`}
             onClick={handleChangeLanguage('en')}>EN
        </div>
      </div>
    </Loadable>
  );
});

const openProfileModal = () => {
  return openSideModal<void>((resolve) => <ProfileModal resolve={resolve}/>);
};

export {openProfileModal};
