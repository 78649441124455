import React, {FC, useCallback} from 'react';
import {ITrack} from "../../../modules/rest";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import Player from "../../../components/Player";
import {TrackHistoryBar, TrackTitle} from "../../../components/Track";
import {TrackTypeFromContentId} from "../../../components/Track";
import {downloadTrackFile, formatNumber} from "../../../modules/utils";
import {API} from "../../../modules/api";
import {toast} from "react-toastify";
import {Icon} from "../../../components/fontawesome";
import OneMinTrack from "../../../components/OneMinTrack";
import {myTracksVideosModal} from "../../../modals/channels/MyTracksVideosModal";
import button from "../../../components/Button";
import {confirmDialog} from "../../../modules/confirm";
import MyTracksFreeBtn from "./MyTracksFreeBtn";
import Loader from "../../../components/Loader";
import {addVideoModal} from "../../../modals/channels/AddVideoModal";
import DropDown, {DropDownItem} from "../../../components/DropDown";

interface Props {
  track: ITrack;
  onChange: () => void;
  changePlayedTrackId: (id?: number) => void;
  activeTrackId?: number;
}

const MyTracksListItem: FC<Props> = ({track, onChange, changePlayedTrackId, activeTrackId}) => {
  const {t} = useTranslation();

  const activate = useCallback((e: any) => {
    e.stopPropagation();
    confirmDialog(
      t('MONETIZATION_CONFIRM_TEXT'),
      {
        title: t('MONETIZATION_ACTIVATION') || '',
        confirmText: t('ACTIVATE') || '',
      }
    ).then((agree) => {
      if (!agree) return;
      // setLoading(true);
      API.Tracks.activate(track.id)
        .then(() => {
          toast.success(t('MONETIZATION_ACTIVATION_PROCESS'));
          onChange();
        })
        .catch(toast.error);
      // .finally(() => setLoading(false));
    });
  }, [onChange, track.id, t]);

  const freeBuy = useCallback(() => {
    confirmDialog(
      t('MONETIZATION_CONFIRM_TEXT'),
      {
        title: t('MONETIZATION_ACTIVATION') || '',
        confirmText: t('ACTIVATE') || '',
      }
    ).then((agree) => {
      if (!agree) return;
      // setLoading(true);
      API.Tracks.activate(track.id)
        .then(() => {
          toast.success(t('MONETIZATION_ACTIVATION_PROCESS'));
          onChange();
        })
        .catch(toast.error);
      // .finally(() => setLoading(false));
    });
  }, [onChange, track.id, t]);

  const danger = track.views! > track.limit! * 0.9;
  const activeTrack = activeTrackId === track.id;

  return (
    <div className={`d-flex mt-3 align-items-center my__tracks__list__item${track.isLeased ? ' free' : ''}`}>
      <div className='text-muted-14' style={{minWidth: 70, width: 70}}>#{track.id}</div>
      <div className="card card-active white w-100 cursor-pointer"
           onClick={() => {
             changePlayedTrackId();
             myTracksVideosModal(track).then(() => onChange())
           }}>
        <div className="card-body d-flex align-items-center">
          <div className='d-flex pe-3 position-relative align-items-center' style={{minWidth: 300, width: 300}}>
            <Player
              track={track}
              active={activeTrack}
              onChange={changePlayedTrackId}
            />
            <div className='ps-2 d-flex flex-column justify-content-center'>
              <TrackTitle track={track} inline active={activeTrack} width={195}/>
              <TrackTypeFromContentId hasContentId={track.hasContentId} free={track.isLeased}/>
              {/*<div className="text-muted-14 cursor-pointer ms-2 my__tracks__list__item-hover-visible"*/}
              {/*     onClick={() => downloadTrackFile(track)}>*/}
              {/*  {t('DOWNLOAD')}*/}
              {/*  <Icon icon={['fas', 'arrow-down']} fixedWidth className="ms-1"/>*/}
              {/*</div> */}
            </div>
            <OneMinTrack small visible={track.isMusic} className='ms-2'/>
          </div>
          <div className='pe-4 d-flex align-items-center' style={{minWidth: 280, width: 280}}>
            {(track.isMonetize && track.hasContentId) && (
              <div style={{width: 220}} className='me-2'>
                <div className="d-flex justify-content-between text-12 pb-2">
                  <div className='text-muted'>{t('VIEWS')}</div>
                  <div>
                    <span className={danger ? 'text-danger' : 'text-dark'}>{formatNumber(track.views!)}</span> /{' '}
                    {formatNumber(track.limit!) || 0}
                  </div>
                </div>
                <div className="progress">
                  <div
                    className={`progress-bar ${danger ? 'danger' : ''}`}
                    style={{width: `${(track.views! / track.limit!) * 100}%`}}
                  />
                </div>
              </div>
            )}
            {(track.isMonetize && !track.hasContentId) && (
              <div className='text-muted-14 d-flex'><Loader className='me-2'/>{t('ACTIVATE_PROCESS')} </div>
            )}
            {!track.isMonetize && (
              <div className='d-flex align-items-center'>
                <button className='btn btn-success' onClick={activate}>
                  <Icon icon={['fas', 'power-off']} size='lg' fixedWidth/>
                </button>
                <div className='text-muted-14 ms-2'>{t('CAN_ACTIVATE_MONETIZE')}</div>
              </div>
            )}
            {track.isLeased && <MyTracksFreeBtn track={track} onSuccessBuy={onChange}/>}
          </div>
          <div className='d-flex align-items-center' style={{minWidth: 200, width: 200}}>
            {!track.hasContentId ? null : (
              <>
                <div className={`my__tracks__list-${track.videoType || ''} me-2 text-muted-14 cursor-pointer`}>
                  {track.videoCount || ''}
                  {!track.videoCount
                    ?
                    t('NO_VIDEO')
                    :
                    <span className='text-lowercase'> {t('VIDEO')}</span>
                  }
                </div>
                <button
                  className="btn btn-secondary my__tracks__list__item-hover-visible"
                  onClick={(e) => {
                    e.stopPropagation();
                    addVideoModal(track).then(() => onChange())
                  }}
                >
                  <Icon icon={['fas', 'plus']} fixedWidth/>
                </button>
              </>
            )}
          </div>
          <div className='flex-grow-1 justify-content-start'>
            <TrackHistoryBar track={track}/>
          </div>
          <div onClick={e => e.stopPropagation()}>
            <DropDown positions={['bottom', 'top', 'right', 'left']}>
              <DropDownItem icon={['fas', 'download']} text="DOWNLOAD" onClick={() => downloadTrackFile(track)}/>
            </DropDown>
          </div>
        </div>
      </div>
    </div>
  );
}


export default observer(MyTracksListItem);