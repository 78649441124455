import {observer, useLocalObservable} from 'mobx-react';
import {
  ECountry,
  ELanguage,
  EMusicGenre,
  EMusicMood, EUserRole,
  IAddTrackRequest,
  IAuthor,
  ITrack,
  IUpdateTrackRequest,
  IUpload,
} from '../../../modules/rest';
import {runInAction} from 'mobx';
import InputFile from '../../../components/InputFile';
import Button from '../../../components/Button';
import React, {FC, FormEvent, useCallback, useEffect} from 'react';
import Loadable from '../../../components/Loadable';
import {API} from '../../../modules/api';
import {toast} from 'react-toastify';
import {thumb} from '../../../modules/utils';
import {Genres, Languages, Moods} from '../../../modules/directory';
import Help from '../../../components/Help';
import Checkbox from "../../../components/Checkbox";
import Helper from "../../../components/Helper";
import {useTranslation} from "react-i18next";
import session from "../../../modules/session";
import {Route} from "react-router-dom";
import OwnerRoutes from "../../owner";
import Empty from "../../../components/Empty";

interface State {
  file: IUpload|null;
  sample: IUpload|null;
  cover: IUpload|null;
  request: Partial<IAddTrackRequest&IUpdateTrackRequest>;
  loading: boolean;
  mode: 'sell'|'share';
}

interface Props {
  mode: 'add'|'edit';
  author: IAuthor;
  track?: Partial<ITrack>;

  onUpdated(): void;
}

const TrackCompose: FC<Props> = observer(({track, author, mode, onUpdated}) => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    request: {
      title: track?.title,
      artist: track?.artist,
      alias: track?.alias || author.alias,
      album: track?.album,
      authorId: author.id,
      coverId: track?.cover?.id,
      fileId: track?.file?.id,
      sampleId: track?.sample?.id,
      lyrics: track?.lyrics || '',
      authorLyrics: track?.authorLyrics,
      authorMusic: track?.authorMusic,
      feat: track?.feat,
      version: track?.version,
      genre: track?.genre,
      mood: track?.mood,
      language: track?.language,
      sellPrice: track?.sellPrice,
      buyPrice: track?.buyPrice,
      ownerId: track?.ownerId,
      isMusic: track?.isMusic
    },
    file: track?.file || null,
    sample: track?.sample || null,
    cover: track?.cover || null,
    loading: false,
    mode: track?.ownerId ? 'share' : 'sell',
  }));

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      runInAction(() => (st.loading = true));

      let promise;

      if (mode === 'add') {
        promise = API.Tracks.addTrack(st.request as IAddTrackRequest);
      } else {
        promise = API.Tracks.updateTrack(track!.id!, st.request as IUpdateTrackRequest);
      }

      promise
        .then(() => {
          toast.success(t('TRACK_SAVE_TO_DRAFT'));
          onUpdated();
        })
        .catch(toast.error)
        .finally(() => runInAction(() => (st.loading = false)));
    },
    [st, onUpdated, mode, track, t]
  );

  const init = useCallback(() => {
    if (mode === 'add') {
      API.Tracks.getLastAuthorTrack(author.id).then((track) => {
        if (!track) return;
        runInAction(() => {
          st.request = {
            ...st.request,
            album: track.album,
            authorMusic: track.authorMusic,
            authorLyrics: track.authorLyrics,
            language: track.language,
            genre: track.genre,
            mood: track.mood,
            sellPrice: track.sellPrice,
            isMusic: track.isMusic || false
          };
        });
      });
    }
  }, [author.id, mode, st]);

  useEffect(() => {
    init();
  }, [init]);

  if (session?.user?.role === EUserRole.Author && mode === 'add') return (
    <div>
      <h4>{t('ADD')} {t('track')}</h4>
      <Empty show text={'NEW_TRACKS_DISABLED'} className='mt-4'/>
    </div>
  )

  return (
    <Loadable loading={st.loading}>
      <div className="mb-3 d-flex justify-content-between">
        <h4>{t(mode === 'add' ? 'ADD' : 'EDIT')} {t('track')}</h4>
        <Help url="https://docs.google.com/document/d/1O4uwC4q5GXqsh2iev0wOXIKIXLsmi4lt7S12_dgdcrQ"/>
      </div>
      <form onSubmit={submit} className="card">
        <div className="row card-body p-4">
          <div className="col">
            <div className="row mb-3">
              <div className="col">
                <label className="req">{t('TRACK_NAME')}</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder={t('TRACK_NAME') || ''}
                  value={st.request.title || ''}
                  maxLength={100}
                  onChange={(e) => runInAction(() => (st.request.title = e.target.value))}
                />
              </div>

              <div className="col">
                <label className="req">{t('NICKNAME')}</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder={t('ARTIST') || ''}
                  value={st.request.alias || ''}
                  maxLength={100}
                  onChange={(e) => runInAction(() => (st.request.alias = e.target.value))}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="req">{t('PERFORMER')}</label>
              <input
                required
                type="text"
                className="form-control"
                placeholder={t('PERFORMER_PLACEHOLDER') || ''}
                value={st.request.artist || ''}
                maxLength={100}
                onChange={(e) => runInAction(() => (st.request.artist = e.target.value))}
              />
            </div>

            <div className="mb-3">
              <label className="req">{t('LYRICIST')}</label>
              <input
                type="text"
                required
                className="form-control"
                placeholder={t('PERFORMER_PLACEHOLDER') || ''}
                value={st.request.authorLyrics || ''}
                maxLength={100}
                onChange={(e) => runInAction(() => (st.request.authorLyrics = e.target.value))}
              />
            </div>
            <div className="mb-3">
              <label className="req">{t('MUSIC_AUTHOR')}</label>
              <input
                type="text"
                required
                className="form-control"
                placeholder={t('PERFORMER_PLACEHOLDER') || ''}
                value={st.request.authorMusic || ''}
                maxLength={100}
                onChange={(e) => runInAction(() => (st.request.authorMusic = e.target.value))}
              />
            </div>

            <div className="mb-3">
              <div className="row">
                <div className="col">
                  <label>{t('ALBUM')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('ALBUM_TITLE') || ''}
                    value={st.request.album || ''}
                    maxLength={100}
                    onChange={(e) => runInAction(() => (st.request.album = e.target.value || null))}
                  />
                </div>

                <div className="col">
                  <label>{t('STARRING')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('STARRING_PLACEHOLDER') || ''}
                    value={st.request.feat || ''}
                    maxLength={100}
                    onChange={(e) => runInAction(() => (st.request.feat = e.target.value || null))}
                  />
                </div>
                <div className="col">
                  <label>{t('VERSION')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('VERSION_PLACEHOLDER') || ''}
                    value={st.request.version || ''}
                    maxLength={100}
                    onChange={(e) => runInAction(() => (st.request.version = e.target.value || null))}
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <div className="row">
                <div className="col">
                  <label className="req">{t('TRACK')} (.wav)</label>
                  <InputFile
                    deletable={false}
                    value={st.file || null}
                    accept="audio/wav, audio/x-wav"
                    fileIcon={['fas', 'music']}
                    onChange={(upload) =>
                      runInAction(() => {
                        st.request.fileId = upload?.id!;
                        st.file = upload;
                      })
                    }
                  />
                </div>
                <div className="col">
                  <label className="req">{t('EXCERPT')} (.mp3)</label>
                  <InputFile
                    deletable={false}
                    value={st.sample || null}
                    accept="audio/mpeg"
                    fileIcon={['fas', 'music']}
                    onChange={(upload) =>
                      runInAction(() => {
                        st.request.sampleId = upload?.id!;
                        st.sample = upload;
                      })
                    }
                  />
                </div>
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <label>{t('ADDITIONALLY')}</label>
                    <Helper tag='track.isMusic'/>
                  </div>
                  <Checkbox label={'MIN_PURE_MUSIC'} checked={st.request.isMusic || false}
                            onChange={checked => runInAction(() => st.request.isMusic = checked)}/>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="req">{t('COVER')} (3000х3000.jpg)</label>
              <div className="d-flex align-items-center">
                {st.cover && (
                  <img src={thumb(st.cover.id, 128)} className="img-thumbnail me-2" style={{height: 64}} alt="cover"/>
                )}
                <InputFile
                  deletable={false}
                  value={st.cover || null}
                  accept="image/jpeg"
                  fileIcon={['fas', 'image']}
                  onChange={(upload) =>
                    runInAction(() => {
                      st.request.coverId = upload?.id!;
                      st.cover = upload;
                    })
                  }
                />
              </div>
            </div>

            {st.mode === 'sell' ? (
              <div className="w-30">
                <label className="req">{t('PRICE_1')}</label>
                <div className="input-group">
                  <input
                    required
                    type="number"
                    min={0}
                    step={0.01}
                    className="form-control"
                    placeholder="1000"
                    value={st.request.sellPrice || ''}
                    maxLength={100}
                    onChange={(e) => runInAction(() => (st.request.sellPrice = Number.parseFloat(e.target.value)))}
                  />
                  <div className="input-group-text">RUB</div>
                </div>

                {mode === 'add' && (
                  <Button
                    onClick={() => runInAction(() => (st.mode = 'share'))}
                    text={'SUBMIT_TRACK_TO'}
                    size="xs"
                    className="mt-2"
                    color="secondary"
                    outline
                  />
                )}
              </div>
            ) : (
              <div className="w-30">
                <label className="req">{t('ID_ACC_YOUTUBER')}</label>

                <input
                  required
                  disabled={mode === 'edit'}
                  type="number"
                  className="form-control"
                  placeholder="1234"
                  value={st.request.ownerId || ''}
                  onChange={(e) => runInAction(() => (st.request.ownerId = Number.parseInt(e.target.value)))}
                />

                {mode === 'add' && (
                  <Button
                    outline
                    size="xs"
                    color="secondary"
                    onClick={() =>
                      runInAction(() => {
                        st.mode = 'sell';
                        st.request.ownerId = null;
                      })
                    }
                    text={'SELL_TRACK'}
                    className="mt-2"
                  />
                )}
              </div>
            )}
          </div>

          <div className="col">
            <div className="mb-3">
              <label className="req">{t('MUSIC_LN')}</label>
              <select
                required
                className="form-select"
                value={st.request.language || ''}
                onChange={(e) => runInAction(() => (st.request.language = e.target.value as ELanguage))}
              >
                <option value="">-- {t('SELECT')} --</option>
                <option value="ru">{t(`langs:ru`)}</option>
                <option value="en">{t(`langs:en`)}</option>
                <option value="uk">{t(`langs:uk`)}</option>
                <option value="-" disabled>
                  -------------
                </option>
                {Object.entries(Languages).map(([k, v]) => (
                  <option key={k} value={k}>
                    {t(`langs:${v}`)}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <div className="row">
                <div className="col">
                  <label className="req">{t('GENRE')}</label>
                  <select
                    required
                    className="form-select"
                    value={st.request.genre || ''}
                    onChange={(e) => runInAction(() => (st.request.genre = e.target.value as EMusicGenre))}
                  >
                    <option value="">-- {t('SELECT')} --</option>

                    {Object.entries(Genres).map(([k, v]) => (
                      <option key={k} value={k}>
                        {t(v)}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col">
                  <label className="req">{t('MOOD')}</label>
                  <select
                    required
                    className="form-select"
                    value={st.request.mood || ''}
                    onChange={(e) => runInAction(() => (st.request.mood = e.target.value as EMusicMood))}
                  >
                    <option value="">-- {t('SELECT')} --</option>

                    {Object.entries(Moods).map(([k, v]) => (
                      <option key={k} value={k}>
                        {t(v)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="">
              <label className="req">{t('MUSIC_TEXT')}</label>
              <textarea
                required
                className="form-control"
                value={st.request.lyrics}
                style={{height: 300}}
                onChange={(e) => runInAction(() => (st.request.lyrics = e.target.value))}
              />
            </div>
          </div>
        </div>

        <div className="card-footer p-4">
          <Button type="submit" text={mode === 'add' ? 'ADD_TRACK' : 'SAVE_TRACK'}/>
        </div>
      </form>
    </Loadable>
  );
});

export default TrackCompose;
