import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import BillingHistory from './history';
import ChangeRoutes from './charge';
import PayoutRoutes from './payout';

const BillingRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BillingHistory />} />
      <Route path="/charge/*" element={<ChangeRoutes />} />
      <Route path="/payout/*" element={<PayoutRoutes />} />
      <Route path="*" element={<Navigate to="/billing/" replace />} />
    </Routes>
  );
};

export default BillingRoutes;
