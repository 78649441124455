import React, {FC, useCallback, useLayoutEffect, useState} from 'react';
import my_tracks_ru from './images/my_tracks_ru.jpg';
import my_tracks_en from './images/my_tracks_en.jpg';
import partners_en from './images/partners_en.jpg';
import partners_ru from './images/partners_ru.jpg';
import './extension_panel.scss'
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Icon} from "../fontawesome";
import moment from "moment";

const images: any = {
  my_tracks: {
    ru: my_tracks_ru,
    en: my_tracks_en,
  },
  partners: {
    ru: partners_ru,
    en: partners_en,
  }
}

interface Props {
  className?: string;
  type: 'my_tracks'|'partners'
}

const ExtensionPanel: FC<Props> = ({type, className}) => {
  const {t, i18n} = useTranslation();
  const [visible, setVisible] = useState(false);

  useLayoutEffect(() => {
    if (!localStorage.getItem('chrome-monetize-extension')) {
      const date = localStorage.getItem('chrome-monetize-extension-date');
      if (!date) {
        setVisible(true);
      } else {
        const diff = moment().diff(date, "days");
        if (diff >= 7) {
          setVisible(true);
          localStorage.removeItem('chrome-monetize-extension-date');
        }
      }
    }
  }, []);

  const handleHide = useCallback(() => {
    localStorage.setItem('chrome-monetize-extension-date', moment().format('YYYY-MM-DD'));
    setVisible(false);
  }, []);

  if (!visible) return null;
  return (
    <div className={`px-3 mb-3 ${className || ''}`}>
      <div className='extension-panel'>
        <div className='d-flex align-items-center'>
          <div className='bold-14'>{t(`EXTENSION_PANEL_TITLE_${type}`)}</div>

          <div className="extension-panel-work">
            <div className='position-relative text-nowrap' style={{zIndex: 3}}>
              <span className='pe-2 bold-14'>{t('HOW_IT_WORK')}</span>
              <Icon icon={['fas', 'chevron-down']} fixedWidth/>
            </div>
            <div className="extension-panel-work-hidden">
              <img src={images[type]?.[i18n.language]} alt="screen"/>
              <p className='text-muted-14 p-3 mb-0'
                 dangerouslySetInnerHTML={{__html: t(`EXTENSION_PANEL_TEXT_${type}`)}}/>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <Link to={'/apikey'} className='btn btn-sm btn-outline extension-panel-btn mx-2'>
            <span className='btn-icon apikey-icon'></span>
            <span>{t('GET_APIKEY')}</span>
          </Link>
          <a href="https://chrome.google.com/webstore/detail/monetize/lmifinmhfaghgilomkfmdbpieelenlfl/" target='_blank'
             className='btn btn-sm extension-panel-btn btn-install'>
            <span className='btn-icon install-icon'></span>
            <span>{t('INSTALL')}</span>
          </a>
          <div className='extension-panel-close' onClick={handleHide}/>
        </div>
      </div>
    </div>
  );
}

export default ExtensionPanel;