import session from '../modules/session';
import { useCallback, useState } from 'react';
import Button from './Button';
import './AccountSwitch.scss';
import { observer } from 'mobx-react';
import User from './User';
import { IUser } from '../modules/rest';
import { Icon } from './fontawesome';

const AccountSwitch = () => {
  const [opened, setOpened] = useState<boolean>(false);

  const close = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const toggle = useCallback(() => {
    setOpened(!opened);
  }, [opened, setOpened]);

  return (
    <div className="account-switch">
      <div className="account-switch__current" onClick={toggle}>
        <User user={session.user!} showRole hideStatus />
        <Icon icon={['fas', 'chevron-down']} fixedWidth />
      </div>
      {opened && (
        <>
          <div className="account-switch__outside" onClick={close}></div>
          <div className="account-switch__options shadow border">
            <SwitchAccountItems />
            <div className="m-2">
              <Button
                onClick={session.addAccountLogin}
                text="ADD_ACCOUNT"
                size="xs"
                icon={['fas', 'user-plus']}
                outline
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const SwitchAccountItems = observer(() => {
  return (
    <div>
      {session.accounts.map((a) => {
        if (a.id === session.user?.id) return null;
        return (
          <div
            className="account-switch__options__item hover p-2 border-bottom d-flex justify-content-between align-items-start"
            key={a.id}
            onClick={() => session.switchAccount(a)}
          >
            <User user={{ id: a.id, email: a.email, role: a.role } as IUser} showRole />

            {a.id !== session.user?.id && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  session.removeAccountByToken(a.token);
                }}
                icon={['fas', 'times']}
                size="sm"
                outline
                color="secondary"
                className="ms-3"
              />
            )}
          </div>
        );
      })}
    </div>
  );
});

export default AccountSwitch;

export { SwitchAccountItems };
