import { NavLink, useNavigate } from 'react-router-dom';
import { IAuthor } from '../../../modules/rest';
import { FC } from 'react';
import TrackCompose from './compose';
import {useTranslation} from "react-i18next";

interface Props {
  author: IAuthor;
}

const AddTrack: FC<Props> = ({ author }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <NavLink to="/author/">{t('PERFORMERS')}</NavLink>
        </li>
        <li className="breadcrumb-item">
          <NavLink to={`/author/${author.id}/tracks/`}>{author.alias}</NavLink>
        </li>
        <li className="breadcrumb-item active">{t('ADD_TRACK')}</li>
      </ul>
      <div className="mb-3">
        <TrackCompose mode="add" author={author} onUpdated={() => navigate(`/author/${author.id}/tracks`)} />
      </div>
    </div>
  );
};

export default AddTrack;
