import React, {FC, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import session from "../modules/session";

interface Props {
}

const CheckAuth: FC<Props> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    let successPath = urlParams.get('successPath');
    let successURL = urlParams.get('successURL');
    let errorURL = urlParams.get('errorURL');
    if (session.user) {
      if (successPath) {
        navigate(successPath, {replace: true});
      } else if (successURL) {
        window.location.href = successURL;
      } else {
        navigate('/', {replace: true});
      }
    } else {
      if (errorURL) {
        window.location.href = errorURL;
      } else {
        navigate('/', {replace: true});
      }
    }

  }, []);

  return null;
}

export default CheckAuth;