import React, {useCallback, useEffect} from 'react';
import Empty from '../../../components/Empty';
import Loadable from "../../../components/Loadable";
import {observer, useLocalObservable} from "mobx-react";
import cache from "../../../modules/cache";
import {ECountry, ESortOrder, IGetStatsRequest, IGetStatsResponse, ITrack,} from "../../../modules/rest";
import {runInAction} from "mobx";
import {API} from "../../../modules/api";
import {toast} from "react-toastify";
import StatsListItem from "./StatsListItem";
import './stats.scss'
import StatsHeadItem from "./StatsHeadItem";
import {Icon} from "../../../components/fontawesome";
import StatsFilters from "./StatsFilters";
import {Countries, StatsGroup, StatsSort} from "../../../modules/directory";
import Loader from "../../../components/Loader";
import SortItem from "../../../components/SortItem";
import moment from "moment";
import {useTranslation} from "react-i18next";

interface State {
  pager?: IGetStatsResponse<unknown>;
  request: IGetStatsRequest;
  loading: boolean;
  ready: boolean;
  tracksFilter: ITrack[];
}

// const startPeriod = moment().add('month', -3).format('YYYY-MM');

const StatsPage = observer(() => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    ready: false,
    loading: false,
    request: {
      group: 'track',
      sort: 'profit',
      trackId: [] as number[],
      countryCode: [] as ECountry[],
      order: ESortOrder.DESC
    },
    tracksFilter: [],
  }));

  useEffect(() => {
    fetch();
  }, []);

  const fetch = useCallback(() => {
    runInAction(() => (st.loading = true));
    API.Stats.getTracksStats(st.request)
      .then((pager) => {
        runInAction(() => {
          st.pager = pager;
          cache.set('owner.tracks', st.pager);
        });
      })
      .catch((e) => toast.error(e, {toastId: 'stats_fetch'}))
      .finally(() => runInAction(() => {
        st.loading = false;
        st.ready = true;
      }));
  }, [st]);

  const handleGroup = useCallback((group: IGetStatsRequest['group']) => () => {
    if (group === st.request.group) return;
    runInAction(() => {
      st.request.group = group;
      fetch();
    })
  }, [st.request.group]);

  const handleSort = useCallback((sort: IGetStatsRequest['sort']) => () => {
    runInAction(() => {
      if (sort === st.request.sort) {
        st.request.order = st.request.order === ESortOrder.ASC ? ESortOrder.DESC : ESortOrder.ASC;
      } else {
        st.request.order = ESortOrder.DESC;
        st.request.sort = sort;
      }
      fetch();
    })
  }, [st.request.sort, st.request.order]);

  const handleSortCountries = useCallback((country: ECountry, clear?: boolean) => {
    runInAction(() => {
      if (clear) {
        st.request.countryCode = [];
      } else if (st.request.countryCode?.includes(country)) {
        st.request.countryCode = (st.request.countryCode as ECountry[]).filter((c: string) => c !== country);
      } else {
        st.request.countryCode = [...st.request.countryCode as ECountry[], country];
      }
      fetch();
    });
  }, [st.request.countryCode]);

  const handleSortTracks = useCallback((track?: ITrack, clear?: boolean) => {
    runInAction(() => {
      if (clear) {
        st.tracksFilter = [];
        st.request.trackId = [];
      } else {
        const idx = st.tracksFilter.findIndex(tr => tr.id === track?.id);
        if (idx !== -1) {
          st.tracksFilter = (st.tracksFilter as ITrack[]).filter(tr => tr.id !== track?.id);
          st.request.trackId = (st.request.trackId as number[]).filter(id => id !== track?.id);
        } else {
          st.tracksFilter = [...st.tracksFilter, track!];
          st.request.trackId = [...st.request.trackId as number[], track?.id!];
        }
      }
      fetch();
    });
  }, [st.request.countryCode]);

  const handleSortDate = useCallback((date: { periodBegin?: string, periodEnd?: string }) => {
    runInAction(() => {
      st.request.periodBegin = date.periodBegin;
      st.request.periodEnd = date.periodEnd;
      fetch();
    });
  }, [st.request.periodBegin, st.request.periodEnd]);

  const sortCount = ((st.request?.trackId as number[])?.length || 0) + (st.request.countryCode?.length || 0) + (st.request.periodBegin || st.request.periodEnd ? 1 : 0);

  return (
    <>
      <div className='page__title__line mt-2 pt-1'>
        <h1 className='mb-0 page-title'>{t('STATS')}</h1>
      </div>
      {!st.ready
        ?
        <Loader/>
        :
        <div className='left__content__with__filter stats__page mt-3'>
          <div className='stats__group bold text-muted'>
            <div className='d-flex'>
              {Object.entries(StatsGroup).map(([key, value], i) => (
                <div
                  className={`page__group__item${key === st.request.group ? ' active' : ''}`}
                  key={i}
                  onClick={handleGroup(key as IGetStatsRequest['group'])}
                >
                  {t(value)}
                </div>
              ))}
            </div>
            {sortCount
              ?
              <div className='d-flex flex-wrap pt-3'>
                <div className='bold me-2 text-nowrap mb-2' style={{lineHeight: '32px'}}>
                  {t('FILTER_RESULT')}
                  <span className='text-muted'> {sortCount}</span>
                </div>
                {st.tracksFilter?.map(track => (
                  <SortItem
                    text={track.title}
                    className='ms-2 mb-2'
                    key={track.id}
                    onClick={() => handleSortTracks(track)}
                  />
                ))}
                {Array.isArray(st.request.countryCode) && st.request.countryCode?.map(c => (
                  <SortItem
                    text={t(`countries:${c}`) || ''}
                    className='ms-2 mb-2'
                    key={c}
                    onClick={() => handleSortCountries(c)}
                  />
                ))}
                {st.request.periodBegin
                  ?
                  <SortItem
                    text={`${t('FROM')} ${moment(st.request.periodBegin).format('MMMM YYYY')}`}
                    className='ms-2 mb-2'
                    onClick={() => handleSortDate({periodBegin: undefined, periodEnd: st.request.periodEnd || undefined})}
                  />
                  :
                  null
                }
                {st.request.periodEnd
                  ?
                  <SortItem
                    text={`${t('TILL')} ${moment(st.request.periodEnd).format('MMMM YYYY')}`}
                    className='ms-2 mb-2'
                    onClick={() => handleSortDate({periodBegin: st.request.periodBegin || undefined, periodEnd: undefined})}
                  />
                  :
                  null
                }
              </div>
              :
              null
            }
          </div>
          <div className='d-flex'>
            <StatsHeadItem
              title={'VIEWS'}
              amount={st.pager?.totalViews}
              color={'#AFB2BB'}
              help={'stats.totalViews'}
              icon='eye'
            />
            <StatsHeadItem
              title={'MONETIZED_VIEWS'}
              amount={st.pager?.monetizedViews}
              color={'#34353C'}
              help={'stats.monetizedViews'}
              icon='eye_rub'
            />
            <StatsHeadItem
              title={'INCOME'}
              amount={st.pager?.profit}
              amountUsd={st.pager?.profitUsd}
              color={'#8CEF9C'}
              help={'stats.profit'}
              icon='dollars'
              rub
            />
            <StatsHeadItem
              title={'CPM'}
              amount={st.pager?.cpm}
              amountUsd={st.pager?.cpmUsd}
              color={'#FFC700'}
              help={'stats.cpm'}
              icon='dollars'
              rub
            />
          </div>
          <div className='stats__sort'>
            <div className='text-muted' style={{minWidth: 50, width: 50}}>#</div>
            <div className='d-flex px-4 w-100'>
              <div className='stats__sort__item cursor-default'>{t(StatsGroup[st.request.group])}</div>
              {Object.entries(StatsSort).map(([key, value], i) => (
                <div
                  key={i}
                  className={`stats__sort__item${st.request.sort === key ? ' active' : ''}`}
                  onClick={handleSort(key as IGetStatsRequest['sort'])}
                >
                  <span>{t(value)} </span>
                  <Icon
                    icon={['fas', st.request.sort === key ? (st.request.order === ESortOrder.ASC ? 'sort-amount-desc' : 'sort-amount-asc') : 'bars']}
                    fixedWidth/>
                </div>
              ))}
            </div>
          </div>
          <Loadable loading={st.loading}>
            {st.pager && (
              <div className='row mb-3'>
                {st.pager.data?.map((item, i) => (
                  <StatsListItem item={item} key={i} index={i} group={st.request.group} sort={st.request.sort}/>
                ))}
              </div>
            )}
            <Empty
              show={!st.loading && !st.pager?.data?.length}
              text='STATS_AWAIT'
            />
          </Loadable>
        </div>
      }
      <StatsFilters
        onSortCountries={handleSortCountries}
        onSortTracks={handleSortTracks}
        onSortDate={handleSortDate}
        onClear={() => {
          runInAction(() => {
            st.request.periodBegin = undefined;
            st.request.periodEnd = undefined;
            st.request.countryCode = [];
            st.request.trackId = [];
            st.tracksFilter = [];
            fetch();
          });
        }}
        {...st.request}
        sortCount={sortCount}
        trackId={st.request.trackId as number[]}
        countryCode={st.request.countryCode as ECountry[]}
        tracksFilter={st.tracksFilter}
      />
    </>
  );
});

export default StatsPage;
