import React, { FC } from 'react';
import { Icon } from '../fontawesome';

import './sort_item.scss';
import {useTranslation} from "react-i18next";

interface Props {
  text?: string | number;
  className?: string;
  white?: boolean;
  yellow?: boolean;
  sm?: boolean;
  hideClose?: boolean;
  active?: boolean;
  onClick?: () => void;
}

const SortItem: FC<Props> = ({
                               text,
                               sm,
                               white,
                               yellow,
                               className,
                               onClick,
                               active,
                               hideClose
                             }) => {
  const {t} = useTranslation();
  const handleClick = (e: any) => {
    e.stopPropagation();
    if(onClick) onClick();
  };

  return (
    <div
      className={`sort__item${active ? ' active' : ''}${sm ? ' sm' : ''}${white ? ' white' : ''}${yellow ? ' yellow' : ''} ${className || ''}`}
      onClick={handleClick}
    >
      {active
        ?
        <div className='pe-2'>
          <Icon icon={['fas', 'check']} color={white ? '#FFFFFF' : ''} fixedWidth />
        </div>
        :
        null
      }
      <div>{t(text + '')}</div>
      {!hideClose ? <div className='ps-2 d-flex'><Icon icon={['fas', 'times']} color={yellow ? '' : '#AFB2BB'} fixedWidth /></div> : null}
    </div>
  );
};

export default SortItem;