import { IPayoutDetails, IPayoutRequest } from '../../modules/rest';
import asyncModal from 'react-async-modal';
import { FC, FormEvent, useCallback, useEffect } from 'react';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import session from '../../modules/session';
import ModalActions from '../ModalActions';
import Loadable from '../../components/Loadable';
import Button from '../../components/Button';
import { openCreatePayoutDetailsModal } from './CreatePayoutDetailsModal';
import { observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import {useTranslation} from "react-i18next";

interface Props {
  resolve(request: IPayoutRequest | false): void;
}

interface State {
  detailsList: IPayoutDetails[];
  details?: IPayoutDetails | null;
  amount?: number;
  loading: boolean;
  error?: string | null;
  payout?: IPayoutRequest | null;
}

const CreatePayoutRequestModal: FC<Props> = observer(({ resolve }) => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    detailsList: [],
    loading: false,
  }));

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      runInAction(() => (st.loading = true));
      API.Billing.createPayoutRequest(st.details!.id, { amount: st.amount || 0 })
        .then((res) => {
          toast.success(t('APPLICATION_CREATED'));
          resolve(res);
          return session.fetch();
        })
        .catch(toast.error)
        .finally(() => runInAction(() => (st.loading = false)));
    },
    [resolve, st, t]
  );

  const dryRun = useCallback(() => {
    if (!st.amount || !st.details!.id) {
      runInAction(() => {
        st.error = null;
        st.payout = null;
      });
      return;
    }
    API.Billing.createPayoutRequest(st.details!.id, {
      amount: st.amount || 0,
      dry: true,
    })
      .then((payout) => {
        runInAction(() => {
          st.error = null;
          st.payout = payout;
        });
      })
      .catch((error) => {
        runInAction(() => {
          st.error = error;
          st.payout = null;
        });
      })
      .finally(() => runInAction(() => (st.loading = false)));
  }, [st]);

  const addDetails = useCallback(() => {
    openCreatePayoutDetailsModal().then((details) => {
      if (details) {
        session.editPayoutDetails([details]);
        runInAction(() => {
          st.details = details;
          st.detailsList = [details, ...st.detailsList];
          dryRun();
        });
      }
    });
  }, [st, dryRun]);

  useEffect(() => {
    API.Billing.getPayoutDetails()
      .then((list) => {
        runInAction(() => {
          st.detailsList = list;
          if (list.length > 0) {
            st.details = list[0];
            st.amount = st.details?.minimalAmount;
            dryRun();
          }
        });
      })
      .catch(toast.error);
  }, [st, dryRun]);

  return (
    <Loadable loading={st.loading}>
      <form onSubmit={submit}>
        <h4 className="mb-4">{t('PAYOUT_REQUEST')}</h4>
        <div className="mb-3">
          <label className="req">{t('REQUISITES')}</label>
          <div className="input-group">
            <select
              className="form-select"
              required
              value={st.details?.id || ''}
              onChange={(e) =>
                runInAction(() => {
                  st.details = st.detailsList.find((d) => d.id === Number(e.target.value));
                  st.amount = st.details?.minimalAmount;
                  dryRun();
                })
              }
            >
              <option value="">-- {t('SELECT')} --</option>
              {st.detailsList.map((d) => (
                <option key={d.id} value={d.id}>
                  {d.title}
                </option>
              ))}
            </select>
            <Button icon={['fas', 'plus']} outline title="ADD_REQUISITES" onClick={addDetails} />
          </div>
          {st.details && <div className="form-text">{st.details.description}</div>}
        </div>

        {st.details && (
          <div className="mb-3">
            <label className="req">{t('AMOUNT_RECEIVABLE')}</label>
            <div className="d-flex">
              <div className="input-group w-50 me-1">
                <input
                    autoFocus
                    type="number"
                    min={st.details.minimalAmount}
                    max={st.details.maximalAmount}
                    step={0.01}
                    className="form-control"
                    value={st.amount}
                    onChange={(e) => {
                      runInAction(() => (st.amount = e.target.valueAsNumber));
                      dryRun();
                    }}
                />
                <div className="input-group-text">{st.details.currency}</div>
              </div>
              {st.payout?.amountUsd && <div className="ms-1 input-group w-50">
                <input
                    disabled
                    readOnly
                    type="text"
                    className="form-control"
                    value={`≈ ${st.payout.amountUsd}`}
                />
                <div className="input-group-text">USD</div>
              </div>}
            </div>
            {st.error && <div className="form-text text-danger">{st.error}</div>}
            {st.payout && (
              <div className="form-text">
                {t('COMMISSION')}{' '}
                <b>
                  {st.payout.fee} {st.payout.currency}
                </b>
                , {t('all_to_pay_off')} <b>{st.payout.cost} RUB</b>
              </div>
            )}
          </div>
        )}

        <ModalActions
          submitText="CREATE_APPLICATION"
          submitIcon={['fas', 'check']}
          submitColor="success"
          disabled={!st.payout}
          onCancelClick={() => resolve(false)}
        />
      </form>
    </Loadable>
  );
});

const openCreatePayoutRequestModal = (): Promise<IPayoutRequest | false> => {
  return asyncModal(CreatePayoutRequestModal, {}, { styles: { modal: { width: 400 } } });
};

export { openCreatePayoutRequestModal };
