import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon } from './fontawesome';
import {useTranslation} from "react-i18next";

interface Props {
  icon?: IconProp;
  text?: string;
  title?: string;
  size?: 'sm' | 'lg' | 'md' | 'xs';
  color?: 'primary' | 'success' | 'secondary' | 'danger' | 'warning';
  outline?: boolean;
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  reverse?: boolean;
  loading?: boolean;

  onClick?(event: React.MouseEvent<HTMLElement>): void;
}

const Button: FC<Props> = ({
  text,
  title,
  icon,
  className,
  onClick,
  size = 'md',
  color = 'primary',
  disabled = false,
  loading = false,
  outline = false,
  type = 'button',
  reverse = false,
}) => {
const {t} = useTranslation();
return (
  <button
    disabled={disabled}
    type={type}
    title={t(title || '') || ''}
    className={`d-flex align-items-center${reverse ? ' flex-row-reverse' : ''} btn btn-${size} btn${
      outline ? '-outline' : ''
    }-${color} ${className || ''}`}
    onClick={!loading ? (e) => onClick && onClick(e) : () =>{}}
  >
    {icon && <Icon icon={icon} fixedWidth className={text ? (reverse ? 'ms-1' : 'me-1') : ''} />}
    {text && <span>{t(text)}</span>}
  </button>
)};

export default Button;
