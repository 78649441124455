import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import card from '../../../assets/images/payment-card.png';
import ym from '../../../assets/images/payment-ym.png';
import usdt from '../../../assets/images/payment-usdt.png';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { IBillingConfig } from '../../../modules/rest';
import {useTranslation} from "react-i18next";

const BillingCharge = () => {
  const {t} = useTranslation();
  const [config, setConfig] = useState<IBillingConfig>();

  useEffect(() => {
    API.Billing.getConfig().then(setConfig).catch(toast.error);
  }, [setConfig]);

  return (
    <div>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/billing/">{t('BILLING')}</Link>
        </li>
        <li className="breadcrumb-item active">{t('REFILL')}</li>
      </ul>
      <h3 className="mb-3">{t('SELECT_PAYMENT')}</h3>
      <div style={{ width: 550 }}>
        <Link to="ym/" className="card text-decoration-none mb-3 text-muted small">
          <div className="card-body d-flex justify-content-between">
            <img src={ym} alt="YM" height={50} />
            <div>{t('COMMISSION')} 1%</div>
          </div>
        </Link>
        <Link to="card/" className="card text-decoration-none mb-3 text-muted small">
          <div className="card-body d-flex justify-content-between">
            <img src={card} alt="Card" height={50} />
            <div>{t('COMMISSION')} 3% + 100 ₽</div>
          </div>
        </Link>
        <Link to="usdt/" className="card text-decoration-none text-muted small">
          <div className="card-body d-flex justify-content-between">
            <img src={usdt} alt="USDT" height={50} />
            {config ? <div>{t('EXCHANGE')} {config.usdtRate} ₽</div> : <Loader />}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BillingCharge;
