import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {EFieldGroup, EReviewStatus, IAuthor} from '../../../modules/rest';
import {API} from '../../../modules/api';
import Loader from '../../../components/Loader';
import {ReviewStates} from '../../../modules/directory';
import {useTranslation} from "react-i18next";
import AuthorStatusIcon from "../tracks/AuthorStatusIcon";
import ProfileAuthorsList from "./ProfileAuthorsList";
import Button from "../../../components/Button";

const ManageAuthors = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [authors, setAuthors] = useState<IAuthor[]>();

  useEffect(() => {
    API.Authors.getAuthors([EFieldGroup.AuthorUrls]).then(setAuthors).catch(toast.error);
  }, []);

  if (!authors) return <Loader/>;

  return (
    <>
      <ProfileAuthorsList authors={authors}/>
      {!authors?.length
        ?
        <div style={{paddingTop: 220}} className='d-flex flex-column align-items-center'>
          <div className='bold-24'>{t('PERFORMERS_NOT_ADDED')}</div>
          <div className='text-muted mb-4 py-1 text-center' style={{maxWidth: 300}}>{t('PERFORMERS_NOT_ADDED_TEXT')}</div>
          <Button text="ADD_AUTHOR" icon={['fas', 'plus']} onClick={() => navigate('add/')}/>
        </div>
        :
        <>
          <div className='d-flex align-items-center text-muted-14 pb-2'>
            <div style={{width: 50}}>#</div>
            <div className='d-flex w-100'>
              <div className='flex-grow-1'>{t('NAME_NICKNAME')}</div>
              <div className='w-30'>{t('LINKS_COMMUNITY')}</div>
              <div className='text-right w-20'>{t('STATUS')}</div>
            </div>
          </div>
          <div className="separator"/>
          {authors.map((a) => (
            <div key={a.id} className='d-flex align-items-center mt-3'>
              <div style={{width: 50}} className='text-muted-14'>#{a.id}</div>
              <div className="card card-active dark w-100" onClick={() => navigate(`${a.id}/tracks/`)}>
                <div className="card-body px-4 d-flex">
                  <div className="flex-grow-1">
                    <div className="bold">{a.alias}</div>
                    <div className="text-muted">
                      {a.lastName} {a.firstName}
                    </div>
                  </div>
                  <div className="d-flex w-30 flex-wrap justify-content-start">
                    {a?.urls?.map((link, i) => (
                      <a
                        href={link}
                        target={'_blank'}
                        key={i}
                        className={'text-muted text-decoration-underline me-2 text-break'}
                        onClick={(e) => e.stopPropagation()}
                      >{link}</a>
                    ))}
                  </div>
                  <div className="w-20 d-flex flex-column align-items-end justify-content-center">
                    <div className='d-flex align-items-center'>
                      <span className={`small me-2`}>{t(ReviewStates[a.status!])}</span>
                      <AuthorStatusIcon status={a.status} className='card__item__status-icon'/>
                    </div>
                    {a.rejectReason && <div className="text-danger small">{a.rejectReason}</div>}
                    <div className="w-20 d-flex justify-content-end align-items-stretch">
                      {[EReviewStatus.Draft, EReviewStatus.Rejected].includes(a.status) && (
                        <Button
                          icon={['fas', 'edit']}
                          outline
                          size='xs'
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`${a.id}/edit/`);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      }
    </>
  );
};

export default ManageAuthors;
