import {ITrack} from '../../modules/rest';
import {FC, FormEvent, useCallback, useState} from 'react';
import Loadable from '../../components/Loadable';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import Track from '../../components/Track';
import asyncModal from 'react-async-modal';
import session from '../../modules/session';
import {Icon} from '../../components/fontawesome';
import {formatNumber} from "../../modules/utils";
import {useTranslation} from "react-i18next";
import Checkbox from "../../components/Checkbox";

interface Props {
  track: ITrack;
  free?: boolean;

  resolve(track: ITrack|null): void;
}

const BuyTrackModal: FC<Props> = ({track, resolve, free}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [agree, setAgree] = useState(false);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      let promise = free ? API.Tracks.leaseTrack(track.id) : API.Tracks.buyTrack(track.id);
      promise
        .then((track) => {
          toast.success(t('TRACKS_ADDED_TO_MY'));
          resolve(track);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [resolve, track.id, free, t]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}  style={{width: 600}}>
        <h5 className='mb-4'>{t(free ? 'GETTING_TRACK' : 'PURCHASING_TRACK')}</h5>
        <div className='mb-3'>
          <Track track={track}/>
          <hr/>
        </div>

        <div className='text-14'>
          <p>{t('PURCHASING_TRACK_TEXT_1')}</p>
          <p className='bold'>{t('START_LIMITS')}:</p>
          <ul>
            <li>{t('BUY_TRACK_MODAL_TEXT_1', {views: formatNumber(session?.limits?.tracks?.video!)})}</li>
            <li>{t('BUY_TRACK_MODAL_TEXT_2', {views: formatNumber(session?.limits?.tracks?.short!)})}</li>
          </ul>
          {!session.user?.telegramId && (
            <p className='bold'>
              <a href='https://t.me/monetizenotify_bot' target='_blank' rel='noreferrer'>
                {t('BIND_BOT')}
              </a>
              , {t('BIND_BOT_REASON')}
            </p>
          )}
          <p>{t('BUY_TRACK_MODAL_TEXT_3')}</p>

          <p className='bold'>{t('BUY_TRACK_MODAL_TEXT_4')}</p>

          <p>{t('BUY_TRACK_MODAL_TEXT_5')}</p>

          <p className='bold'>{t('EXAMPLE')}: </p>
          <p>{t('BUY_TRACK_MODAL_TEXT_6', {views: formatNumber(session?.limits?.tracks?.video!)})}</p>
          <p>{t('BUY_TRACK_MODAL_TEXT_7', {views: formatNumber(session?.limits?.tracks?.video!)})}</p>

          <p>{t('BRIEFLY')}:</p>
          <ul>
            <li>{t('BUY_TRACK_MODAL_BRIEFLY_1')}</li>
            <li>{t('BUY_TRACK_MODAL_BRIEFLY_2')}</li>
            <li>{t('BUY_TRACK_MODAL_BRIEFLY_3')}</li>
            <li>{t('BUY_TRACK_MODAL_BRIEFLY_4')}</li>
          </ul>

          <p className='bold'>{t('TRACK_USAGE')}:</p>
          <ul>
            <li>{t('BUY_TRACK_MODAL_TRACK_USAGE_1')}</li>
            <li>{t('BUY_TRACK_MODAL_TRACK_USAGE_2')}</li>
            <li>{t('BUY_TRACK_MODAL_TRACK_USAGE_3')}</li>
            <li>{t('BUY_TRACK_MODAL_TRACK_USAGE_4')}</li>
            <li>{t('BUY_TRACK_MODAL_TRACK_USAGE_5')}</li>
          </ul>

          <p>{t('BUY_TRACK_MODAL_TEXT_8')}</p>
          {free && (
            <div className='alert alert-warning' dangerouslySetInnerHTML={{
              __html: t('BUY_TRACK_MODAL_TEXT_9', {
                video_views: formatNumber(session?.limits?.free.video!),
                short_views: formatNumber(session?.limits?.free.short!)
              })
            }}/>
          )}
        </div>

          <Checkbox
            checked={agree}
            onChange={(e) => setAgree(e)}
            id='agree'
            label={'AGREE_CONDITIONS'}
          />

        <div className='d-flex justify-content-end'>
          {free ? (
            <button disabled={!agree} type='submit' className='btn btn-warning me-2'>
              <Icon icon={['fas', 'gift']} fixedWidth className='me-1'/>
              {t('TAKE_TRACK')}
            </button>
          ) : (
            <button disabled={!agree} type='submit' className='btn btn-success me-2'>
              {t('BUY_FOR')} {track.buyPrice} RUB
            </button>
          )}
          <button type='button' onClick={() => resolve(null)} className='btn btn-outline-secondary'>
            {t('CLOSE')}
          </button>
        </div>
      </form>
    </Loadable>
  );
};

const buyTrackModal = (track: ITrack, free: boolean = false): Promise<ITrack|null> =>
  asyncModal(BuyTrackModal, {track, free,});

export {buyTrackModal};
