import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import LinkTelegram from './telegram';

const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path="/telegram/:token" element={<LinkTelegram />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default ProfileRoutes;
