import React, {useCallback, useEffect} from 'react';
import './Notifications.scss';
import {observer, useLocalObservable} from 'mobx-react';
import {runInAction} from 'mobx';
import {toast} from 'react-toastify';
import {Icon} from './fontawesome';
import moment from 'moment';
import {thumb} from '../modules/utils';
import {INotification} from '../modules/rest';
import {API} from '../modules/api';
import {useNavigate} from 'react-router-dom';
import {ArrowContainer, Popover} from 'react-tiny-popover';
import session from '../modules/session';
import {useTranslation} from "react-i18next";

interface State {
  isOpened: boolean;
  notifications: INotification[];
  counter: number;
}

const Notifications = observer(() => {
  const {t} = useTranslation();
  const state = useLocalObservable<State>(() => ({
    isOpened: false,
    notifications: [],
    counter: 0,
  }));

  const navigate = useNavigate();

  const toggle = useCallback(() => {
    runInAction(() => (state.isOpened = !state.isOpened));
  }, [state]);

  // const open = useCallback(() => {
  //   runInAction(() => (state.isOpened = true));
  // }, [state]);

  const close = useCallback(() => {
    runInAction(() => (state.isOpened = false));
  }, [state]);

  const fetchList = useCallback(() => {
    API.Notifications.getNotifications()
      .then((notifications) =>
        runInAction(() => {
          state.notifications = notifications;
          state.counter = notifications.filter((n) => n.isNew).length + (session.user?.telegramId ? 0 : 1);
        })
      )
      .catch(toast.error);
  }, [state]);

  const clickItem = useCallback(
    (n: INotification) => {
      if (n.extra?.navigate) {
        navigate(n.extra?.navigate as string);
      }
      API.Notifications.readNotification(n.id).then(fetchList).catch(toast.error);
      close();
    },
    [fetchList, close, navigate]
  );

  const deleteItem = useCallback(
    (n: INotification, e: any): boolean => {
      e.stopPropagation();
      API.Notifications.deleteNotification(n.id).then(fetchList).catch(toast.error);
      return false;
    },
    [fetchList]
  );

  // const readAll = useCallback(() => {
  //   API.Notifications.readAllNotifications().then(fetchList).catch(toast.error);
  //   close();
  // }, [fetchList, close]);

  const deleteAll = useCallback(() => {
    API.Notifications.deleteAllNotifications().then(fetchList).catch(toast.error);
    close();
  }, [fetchList, close]);

  useEffect(() => {
    fetchList();
    let interval = setInterval(fetchList, 10000);
    return () => clearInterval(interval);
  }, [fetchList]);

  return (
    <div className="notifications">
      <Popover
        isOpen={state.isOpened}
        onClickOutside={close}
        positions={['bottom']}
        content={({position, childRect, popoverRect}) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="white"
            arrowSize={10}
            className="arrow"
          >
            <div className="notifications__container">
              <div className="notifications__container__header">
                <div className="title">{t('NOTIFICATIONS')}</div>
                {state.notifications.length > 0 && (
                  <div className="delete-all" onClick={deleteAll}>
                    {t('REMOVE_ALL')}
                  </div>
                )}
              </div>
              <div className="notifications__container__items">
                {!session.user?.telegramId && (
                  <div className="notifications__container__telegram">
                    <div className="d-flex align-items-start">
                      <div className="monetize-icon"/>
                      <div className="text1">
                        <span dangerouslySetInnerHTML={{__html: t('tg_bot_1')}} />
                        <a href="https://t.me/monetizenotify_bot" target="_blank" rel="noreferrer">
                          {t('tg_bot')}
                        </a>
                        <span> {t('tg_bot_2')}</span>
                      </div>
                    </div>
                    <div className="banner">
                      <div className="line1">
                        <div className="monetize-logo"/>
                        <span>{t('in')}</span>
                        <div className="telegram-logo"/>
                      </div>
                      <div className="line2">{t('TG_BOT_PLUG')}</div>
                      <button
                        className="button-buy button-telegram"
                        onClick={() => window.open('https://t.me/monetizenotify_bot')}
                      >
                        <span className="icon"/>
                        <span className="text">{t('PLUG')}</span>
                      </button>
                    </div>
                  </div>
                )}

                {state.notifications.map((n) => (
                  <div
                    className={`notifications__container__items__item ${n.isNew ? 'new' : ''}`}
                    key={n.id}
                    onClick={() => clickItem(n)}
                  >
                    {n.icon && (
                      <div className="icon">
                        <img alt="" src={thumb(n.icon.id, 80)}/>
                      </div>
                    )}
                    <div className="text">
                      <div className="message">{n.message}</div>
                      <div className="date">{moment(n.createdAt).from(moment())}</div>
                    </div>
                    <button className="delete-button" onClick={(e) => deleteItem(n, e)}>
                      <Icon icon={['fas', 'times']} fixedWidth/>
                    </button>
                  </div>
                ))}
              </div>
              {session.user!.telegramId && state.notifications.length === 0 && (
                <div className="text-center small text-muted">{t('EMPTY_LIST')}</div>
              )}
            </div>
          </ArrowContainer>
        )}
      >
        <button
          className={`nav-btn notifications__bell notifications__bell-${state.counter > 0 ? 'active' : 'empty'} `}
          onClick={toggle}
        >
          <Icon icon={['fas', 'bell']} fixedWidth/>
          {state.counter > 0 && (
            <span className="notifications__bell__badge">{state.counter < 100 ? state.counter : 99}</span>
          )}
        </button>
      </Popover>
    </div>
  );
});

export default Notifications;
