import React from 'react';
import ReferralsList from './list';
import ExtensionPanel from "../../components/ExtencionPanel";

const ReferralRoutes = () => {
  return (
    <div>
      <ExtensionPanel type='partners' />
      <ReferralsList/>
    </div>
  )
};

export default ReferralRoutes;
