import React, {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  hasContentId: boolean;
  className?: string;
  free?: boolean;
}

const TrackTypeFromContentId: FC<Props> = ({hasContentId, className, free}) => {
  const {t} = useTranslation();
  return (
    <div className={`track-type track-type-${hasContentId ? 'active' : 'pre'} ${className || ''}`}>
      {hasContentId ? 'ACTIVE' : 'PRE'}
      {free ? <span className='text-muted-14 text-medium'><span className='px-1 text-muted-12'>&#8226;</span>{t('FREE')}</span> : null}
    </div>
  );
}

export default TrackTypeFromContentId;