import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import PayoutRequests from './requests';

const PayoutRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PayoutRequests />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default PayoutRoutes;
