import { NavLink, useNavigate } from 'react-router-dom';
import AuthorCompose from './compose';
import {useTranslation} from "react-i18next";

const AddProfile = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <NavLink to="/author/">{t('PERFORMERS')}</NavLink>
        </li>
        <li className="breadcrumb-item active">{t('ADD_PERFORMER')}</li>
      </ul>
      <AuthorCompose mode="add" onChange={(author) => navigate(`${author.id}/`)} />
    </div>
  );
};

export default AddProfile;
