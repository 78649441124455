import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon } from './fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IUpload } from '../modules/rest';
import { API } from '../modules/api';
import './InputFile.scss';
import { download } from '../modules/utils';
import {useTranslation} from "react-i18next";

interface Props {
  value: IUpload | null;
  deletable?: boolean;
  editable?: boolean;
  accept?: string;
  text?: string;
  icon?: IconProp;
  fileIcon?: IconProp;

  onChange(file: IUpload | null): void;
}

const InputFile: FC<Props> = ({
  value,
  onChange,
  accept = '*/*',
  deletable = true,
  editable = true,
  text = 'SELECT',
  icon = ['fas', 'upload'],
  fileIcon = ['fas', 'file'],
}) => {
  const {t} = useTranslation();
  const [progress, setProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);

  const change = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file: File = e.target.files![0];

      e.target.value = '';

      if (value) onChange(null);

      const fileSize = file.size;
      const chunkSize = 1024 * 512;

      let offset = 0;
      let id: string;

      const chunkReaderBlock = () => {
        const reader = new FileReader();
        const blob = file.slice(offset, chunkSize + offset);
        reader.onload = () => {
          const chunk = (reader.result as string).split(',')[1];
          API.Uploads.chunkPartial({
            id,
            chunk,
          })
            .then((res) => {
              setProgress(res.progress);
              offset += chunkSize;
              if (offset < fileSize) {
                chunkReaderBlock();
              } else {
                setUploading(false);
                onChange(res.upload as IUpload);
              }
            })
            .catch((e) => {
              setUploading(false);
              toast.error(e);
            });
        };
        reader.readAsDataURL(blob);
      };

      setProgress(0);
      setUploading(true);

      API.Uploads.beginPartial({
        fileSize,
        chunkSize,
        fileType: file.type,
        fileName: file.name,
      })
        .then((res) => {
          id = res.id;
          chunkReaderBlock();
        })
        .catch((e) => {
          setUploading(false);
          toast.error(e);
        });
    },
    [onChange, value]
  );

  return (
    <div className="input-file">
      {editable && (
        <div className="input-file__holder">
          {uploading ? (
            <button className="btn btn-outline-primary btn-sm">
              <Icon icon={['fas', 'spinner']} spin fixedWidth />
              <span style={{ marginLeft: 4 }}>{t('LOADING')} ({progress}%)</span>
            </button>
          ) : (
            <button className={`btn  ${value ? 'btn-outline-primary ' : 'btn-primary '} btn-sm`}>
              <Icon icon={icon} fixedWidth />
              <span style={{ marginLeft: 4 }}>{t(text)}</span>
            </button>
          )}
          <input type="file" accept={accept} onChange={change} />
        </div>
      )}

      {value && (
        <div className="input-file__current-file">
          <span
            title={t('DOWNLOAD') || ''}
            className="input-file__current-file__name cursor-pointer"
            onClick={() => download(value)}
          >
            <Icon icon={fileIcon} fixedWidth />
            {value.name ? `${value.name.substring(0, 30)} ${value.name.length > 30 ? '...' : ''}` : ''}
          </span>
          {deletable && (
            <button
              title={t('REMOVE') || ''}
              type="button"
              className="input-file__current-file__delete-button text-danger"
              onClick={() => onChange(null)}
            >
              <Icon icon={['fas', 'times']} />
            </button>
          )}
        </div>
      )}

      {uploading && (
        <div className="input-file__progress">
          <div className="input-file__progress__bar" style={{ width: `${progress}%` }} />
        </div>
      )}
    </div>
  );
};

export default InputFile;
