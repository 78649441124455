import React, {FC, useEffect, useMemo, useState} from 'react';
import {Icon} from './fontawesome';
import OutsideClick from './OutsideClick';
import {ELanguage} from "../modules/rest";
import language_svg from '../assets/images/language.svg';
import {useTranslation} from "react-i18next";
import {Countries, Languages} from "../modules/directory";
import Checkbox from "./Checkbox";

interface Props {
  country?: boolean;
  selectedLanguages: string[];
  onChange: (ln: string) => void;
  availableLanguages?: (ELanguage)[];
}

function filterData(query: string, data: Record<ELanguage, string|undefined>) {
  let result: any = {};
  Object.entries(data).forEach(([key, value]) => {
    if (key.includes(query) || (value?.toLowerCase() || '').includes(query)) {
      result[key] = value;
    }
  });
  return result;
}

const SelectLanguages: FC<Props> = ({selectedLanguages, onChange, availableLanguages, country}) => {
  const {t} = useTranslation();
  const data = country ? Countries : Languages;
  const startLanguages: any = useMemo(() => {
    let languages: Partial<Record<ELanguage, string>> = {};
    if (availableLanguages?.length) {
      availableLanguages.forEach((ln: ELanguage) => languages[ln] = data[ln]);
    } else {
      languages = data;
    }
    return languages;
  }, [availableLanguages, data]);

  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [languages, setLanguages] = useState(data);

  useEffect(() => {
  }, [availableLanguages]);

  const showList = () => setVisible(true);
  const hideList = () => {
    setInputValue('');
    setLanguages(startLanguages);
    setVisible(false);
  };

  const handleClickLang = (ln: string) => (checked: boolean) => {
    onChange(ln);
  };

  const handleFilter = (e: any) => {
    const value = e.target.value.toLowerCase();
    setInputValue(value);
    if (!value) return setLanguages(data);
    const filteredLangs = filterData(value, startLanguages);
    setLanguages(filteredLangs);
  };

  return (
    <OutsideClick outsideClickEvent={hideList}>
      <div className={`custom-search-select${visible ? ' visible' : ''}`}>
        <div className='custom-search-select-control' style={{background: `url(${language_svg})`}}>
          <input
            value={inputValue}
            onChange={handleFilter}
            onFocus={showList}
            type='text'
            className='form-control'
            placeholder={t(country ? 'SELECT_COUNTRY' : 'SELECT_LANG') || ''}
          />
          <Icon icon={['fas', 'chevron-down']} color='#AFB2BB'/>
        </div>
        <div className='custom-search-select-list'>
          {!Object.keys(languages).length
            ?
            <div className='text-center mb-2'>{t('NOTHING_FOUND')}</div>
            :
            Object.entries(languages).map(([key, value], i) => (
              <Checkbox
                className='mb-2'
                key={i}
                checked={selectedLanguages?.includes(key)}
                onChange={handleClickLang(key)}
                label={`${country ? 'countries': 'langs'}:${key}`}
              />
            ))}
        </div>
      </div>
    </OutsideClick>
  );
};

export default SelectLanguages;