import {IGetTrackVideoRequest, IPagedData, ITrack, IVideo} from '../../modules/rest';
import React, {FC, useCallback, useEffect} from 'react';
import Loadable from '../../components/Loadable';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import asyncModal from 'react-async-modal';
import {Icon} from '../../components/fontawesome';
import {useTranslation} from "react-i18next";
import {addVideoModal} from "./AddVideoModal";
import {runInAction} from "mobx";
import {observer, useLocalObservable} from "mobx-react";
import Track, {TrackTitle, TrackTypeFromContentId} from "../../components/Track";
import TrackItemVideo from "../../routes/owner/tracks/track-item-video";
import SearchInput from "../../components/SearchInput";
import Pagination from "../../components/Pagination";
import {confirmDialog} from "../../modules/confirm";
import Empty from "../../components/Empty";
import Player from "../../components/Player";

interface Props {
  track: ITrack;
  free?: boolean;

  resolve(track: ITrack|null): void;
}

interface State {
  videos: IVideo[];
  selectedVideosIds: number[];
  loading: boolean;
  trackPlaying?: boolean;
  request: IGetTrackVideoRequest;
  pager?: IPagedData<IVideo>;
}

const MyTracksVideosModal: FC<Props> = ({track, free, resolve}) => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    loading: false,
    query: '',
    videos: [],
    selectedVideosIds: [],
    request: {
      query: '',
      page: 1,
      limit: 30,
    },
  }));

  const fetchVideos = useCallback(() => {
    runInAction(() => (st.loading = true));
    API.Tracks.getTrackVideos(track.id, st.request)
      .then((pager) => runInAction(() => {
        st.pager = pager;
        st.request.page = pager.page;
        st.request.limit = pager.limit;
        st.videos = pager.data;
      }))
      .catch(toast.error)
      .finally(() => runInAction(() => (st.loading = false)));
  }, [st, track?.id]);

  useEffect(() => {
    if (track?.id) fetchVideos();
  }, [track?.id])

  const handleSelectVideo = useCallback((id: number) => {
    runInAction(() => {
      if (st.selectedVideosIds.includes(id)) {
        st.selectedVideosIds = st.selectedVideosIds.filter(v => v !== id);
      } else {
        st.selectedVideosIds = [...st.selectedVideosIds, id];
      }
    })
  }, []);

  const handleSelectAllVideo = useCallback(() => {
    runInAction(() => {
      const videosIds = st.videos.map(v => v.id);
      st.selectedVideosIds = videosIds;
    })
  }, [st.videos, st.selectedVideosIds]);

  const openAllStudio = useCallback(() => {
    st.videos.forEach(video => {
      if (st.selectedVideosIds.includes(video.id)) {
        window.open(`https://studio.youtube.com/video/${video.extId}/edit`, '_blank')
      }
    })
  }, [st.videos, st.selectedVideosIds]);

  const cutAllTracks = useCallback(() => {
    confirmDialog(t('CUT_TRACK_CONFIRM_SELECTED'), {
      title: t('CUTTING_TRACKS') || '',
      danger: true,
      confirmText: t('TRACK_CUT') || '',
    }).then((confirmed) => {
      if (!confirmed) return;
      st.loading = true;
      try {
        st.videos.forEach(async video => {
          if (st.selectedVideosIds.includes(video.id) && video.hasTrack && video.type !== 'short') {
            await API.Videos.cutoutTrack(video.id);
          }
        })
        toast.success(t('TRACKS_CUT_SUCCESS'));
      } catch (e) {
      } finally {
        fetchVideos()
      }
    });
  }, [st.videos, st.selectedVideosIds]);

  return (
    <Loadable loading={st.loading}>
      <div style={{width: 784}}>
        <div className='tracks__videos__modal-header'>
          <div className='d-flex align-items-center'>
            <Player
              track={track}
              active={st.trackPlaying}
              onChange={() => {
                runInAction(() => {
                  st.trackPlaying = !st.trackPlaying;
                });
              }}
            />
            <div className='ps-2 d-flex flex-column justify-content-center'>
              <TrackTitle track={track} active={st.trackPlaying} width={195}/>
              {/*<div className="text-muted-14 cursor-pointer ms-2 my__tracks__list__item-hover-visible"*/}
              {/*     onClick={() => downloadTrackFile(track)}>*/}
              {/*  {t('DOWNLOAD')}*/}
              {/*  <Icon icon={['fas', 'arrow-down']} fixedWidth className="ms-1"/>*/}
              {/*</div> */}
            </div>
          </div>
          {/*<Track track={track} hideId/>*/}
          <div onClick={() => resolve(null)} className='action-icon align-self-start'>
            <Icon icon={['fas', 'close']} fixedWidth size='lg'/>
          </div>
        </div>
        <div className='my__tracks__modal__video__filters'>
          <div className='d-flex align-items-center mb-3'>
            <div className='flex-grow-1 bold-14 text-lowercase'>
              {track?.videoCount || 0} {t('VIDEO')}
            </div>
            <SearchInput
              small
              onStartTyping={() => {
                runInAction(() => {
                  st.loading = true;
                });
              }}
              value={st.request.query}
              onSearch={(value) => {
                runInAction(() => {
                  st.request.page = 1;
                  st.request.query = value;
                  fetchVideos();
                });
              }}
            />
            {st.selectedVideosIds?.length
              ?
              <>
                <div className="drop-down__items__item  ms-3 mb-0" title={t('CUT_TRACKS') || ''} onClick={cutAllTracks}>
                  <div className="icon me-0">
                    <Icon icon={['fas', 'cut']} fixedWidth/>
                  </div>
                </div>
                <div className="drop-down__items__item ms-3 mb-0" title={t('GO_TO_STUDIO') || ''}
                     onClick={openAllStudio}>
                  <div className="icon me-0">
                    <Icon icon={['fab', 'youtube']} fixedWidth/>
                  </div>
                </div>
              </>
              :
              <button onClick={() => addVideoModal(track).then(fetchVideos)} className="btn btn-primary btn-sm ms-3">
                <Icon icon={['fas', 'plus']} fixedWidth className="me-1"/>
                {t('ADD_VIDEO')}
              </button>
            }
          </div>

          {st.videos.length > 0 && (
            <>
              <div style={{height: 17}}>
                {st.selectedVideosIds?.length
                  ?
                  <div className='d-flex justify-content-between text-14'>
                    <div className='d-flex'>
                      <div className='text-muted pe-1'>{t('SELECTED')}: <span className='d-inline-block'
                                                                              style={{minWidth: 15}}>{st.selectedVideosIds?.length}</span>
                      </div>
                      <div className='cursor-pointer' onClick={handleSelectAllVideo}>{t('SELECT_ALL')}</div>
                    </div>
                    <div className='cursor-pointer' onClick={() => runInAction(() => {
                      st.selectedVideosIds = [];
                    })}>
                      {t('DESELECTED')}
                    </div>
                  </div>
                  :
                  <div className='d-flex text-muted-14'>
                    <div style={{minWidth: 70, width: 70}}>#</div>
                    <div className='flex-grow-1'>{t('NAME')}</div>
                    <div style={{minWidth: 150, width: 150}}>{t('STATUS')}</div>
                    <div style={{minWidth: 200, width: 200}}>{t('VIEWS')}</div>
                  </div>
                }
              </div>
              <div className="separator mt-2"/>
              <div className={`my__tracks__modal__video__list`}>
                {st.videos.map((video) => (
                  <TrackItemVideo
                    video={video}
                    key={video.id}
                    onChanged={fetchVideos}
                    onSelectVideo={handleSelectVideo}
                    selectedVideosIds={st.selectedVideosIds}
                  />
                ))}
              </div>
            </>
          )}
          <Empty show={!st.loading && !st.pager?.count}/>

        </div>
        <Pagination
          all
          onPageChange={(page) =>
            runInAction(() => {
              st.selectedVideosIds = [];
              st.request.page = page;
              fetchVideos();
            })
          }
          onLimitChange={(limit) =>
            runInAction(() => {
              st.selectedVideosIds = [];
              st.request.page = 1;
              st.request.limit = limit;
              fetchVideos();
            })
          }
          pager={st.pager}
        />
      </div>
    </Loadable>
  );
};

const myTracksVideosModal = (track: ITrack, free: boolean = false): Promise<ITrack|null> =>
  asyncModal(observer(MyTracksVideosModal), {track, free,}, {
    showCloseIcon: false,
    classNames: {modal: 'p-2 bg-light'}
  });

export {myTracksVideosModal};
