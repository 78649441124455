export type THttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
export type THeaders = Record<string, string>;

class RestAPI {
  private readonly url: string;
  private token: string | null = null;
  private statusCode: number = 0;
  private instances: Record<string, object> = {};
  private authErrorHandler?: () => void;
  private headersHandler?: (headers: THeaders) => void;
  public debug: boolean = false;

  constructor(url: string, debug: boolean) {
    this.url = url;
    this.debug = debug;
  }

  public getUrl = (): string => {
    return this.url;
  };

  setAuthErrorHandler = (handler?: () => void) => {
    this.authErrorHandler = handler;
  };

  setHeadersHandler = (handler?: (headers: THeaders) => void) => {
    this.headersHandler = handler;
  };

  setToken = (token: string | null): this => {
    this.token = token;
    return this;
  };

  getToken = (): string | null => {
    return this.token;
  };

  getStatusCode = (): number => {
    return this.statusCode;
  };

  get = (
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<any> => {
    return this.request("GET", endpoint, payload, fields);
  };

  post = (
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<any> => {
    return this.request("POST", endpoint, payload, fields);
  };

  put = (
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<any> => {
    return this.request("PUT", endpoint, payload, fields);
  };

  patch = (
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<any> => {
    return this.request("PATCH", endpoint, payload, fields);
  };

  delete = (
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<any> => {
    return this.request("DELETE", endpoint, payload, fields);
  };

  private request = (
    method: THttpMethod,
    endpoint: string,
    payload: object | FormData = {},
    fields: string[] = []
  ): Promise<unknown> => {
    // @ts-ignore
    return new Promise((resolve, reject) => {
      const processReject = (error: string, code: number) => {
        if (this.debug) console.error("Error", error);
        if (code === 401 && this.authErrorHandler) this.authErrorHandler();
        else reject(error);
      };

      const options: {
        method: string;
        headers: Record<string, string>;
        body?: FormData | string;
      } = {
        method: method.toUpperCase(),
        headers: {
          accept: "application/json",
        },
      };

      if (payload instanceof FormData) {
        payload.append("fields", fields.join(","));
        options.body = payload;
      } else {
        options.headers["content-type"] = "application/json";
        // @ts-ignore
        payload["fields"] = fields;
        if (payload && method !== "GET") options.body = JSON.stringify(payload);
      }

      if (this.token) {
        options.headers["authorization"] = "Bearer " + this.token;
      }

      this.statusCode = 0;

      if (payload && method === "GET") {
        endpoint += "?__payload=" + encodeURIComponent(JSON.stringify(payload));
      }

      if (this.debug)
        console.log(
          "Request",
          method,
          endpoint.split("?")[0],
          JSON.parse(JSON.stringify(payload))
        );

      if (this.headersHandler) {
        this.headersHandler(options.headers);
      }

      fetch(this.url + endpoint, options)
        .then((response) => {
          this.statusCode = response.status;
          response
            .json()
            .then((data) => {
              if (data.error) processReject(data.error, response.status);
              else {
                if (this.debug) console.info("Result", data.result);
                resolve(data.result);
              }
            })
            .catch((e) => processReject(e, -2));
        })
        .catch((e) => processReject(e, -1));
    });
  };

  /** Get Batches API */
  get Batches(): Batches {
    return (
      (this.instances["Batches"] as Batches) ??
      (this.instances["Batches"] = new Batches(this))
    );
  }

  /** Get Billing API */
  get Billing(): Billing {
    return (
      (this.instances["Billing"] as Billing) ??
      (this.instances["Billing"] = new Billing(this))
    );
  }

  /** Get Stats API */
  get Stats(): Stats {
    return (
      (this.instances["Stats"] as Stats) ??
      (this.instances["Stats"] = new Stats(this))
    );
  }

  /** Get Videos API */
  get Videos(): Videos {
    return (
      (this.instances["Videos"] as Videos) ??
      (this.instances["Videos"] = new Videos(this))
    );
  }

  /** Get Users API */
  get Users(): Users {
    return (
      (this.instances["Users"] as Users) ??
      (this.instances["Users"] = new Users(this))
    );
  }

  /** Get Uploads API */
  get Uploads(): Uploads {
    return (
      (this.instances["Uploads"] as Uploads) ??
      (this.instances["Uploads"] = new Uploads(this))
    );
  }

  /** Get Help API */
  get Help(): Help {
    return (
      (this.instances["Help"] as Help) ??
      (this.instances["Help"] = new Help(this))
    );
  }

  /** Get Referrals API */
  get Referrals(): Referrals {
    return (
      (this.instances["Referrals"] as Referrals) ??
      (this.instances["Referrals"] = new Referrals(this))
    );
  }

  /** Get Payouts API */
  get Payouts(): Payouts {
    return (
      (this.instances["Payouts"] as Payouts) ??
      (this.instances["Payouts"] = new Payouts(this))
    );
  }

  /** Get Contracts API */
  get Contracts(): Contracts {
    return (
      (this.instances["Contracts"] as Contracts) ??
      (this.instances["Contracts"] = new Contracts(this))
    );
  }

  /** Get Main API */
  get Main(): Main {
    return (
      (this.instances["Main"] as Main) ??
      (this.instances["Main"] = new Main(this))
    );
  }

  /** Get Channels API */
  get Channels(): Channels {
    return (
      (this.instances["Channels"] as Channels) ??
      (this.instances["Channels"] = new Channels(this))
    );
  }

  /** Get Reports API */
  get Reports(): Reports {
    return (
      (this.instances["Reports"] as Reports) ??
      (this.instances["Reports"] = new Reports(this))
    );
  }

  /** Get Admin API */
  get Admin(): Admin {
    return (
      (this.instances["Admin"] as Admin) ??
      (this.instances["Admin"] = new Admin(this))
    );
  }

  /** Get AdminStats API */
  get AdminStats(): AdminStats {
    return (
      (this.instances["AdminStats"] as AdminStats) ??
      (this.instances["AdminStats"] = new AdminStats(this))
    );
  }

  /** Get Tracks API */
  get Tracks(): Tracks {
    return (
      (this.instances["Tracks"] as Tracks) ??
      (this.instances["Tracks"] = new Tracks(this))
    );
  }

  /** Get Notifications API */
  get Notifications(): Notifications {
    return (
      (this.instances["Notifications"] as Notifications) ??
      (this.instances["Notifications"] = new Notifications(this))
    );
  }

  /** Get Authors API */
  get Authors(): Authors {
    return (
      (this.instances["Authors"] as Authors) ??
      (this.instances["Authors"] = new Authors(this))
    );
  }

  /** Get Storage API */
  get Storage(): Storage {
    return (
      (this.instances["Storage"] as Storage) ??
      (this.instances["Storage"] = new Storage(this))
    );
  }
}

export { RestAPI };

export type TDateTime = string;

export type TDateTimeZone = string;

export type TIdentifier = string | number;

export interface IChannel {
  id: number;
  extId: string;
  handle: string | null;
  name: string;
  icon: IUpload | null;
  owner?: IUser;
  subscribers: number;
  isAvailable: boolean;
  createdAt?: TDateTime;
  checkedAt?: TDateTime;
  url: string;
}

export interface IReport {
  id: number;
  file?: IUpload;
  type: EReportType;
  year: number;
  isPaid: boolean;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  extra?: Record<string, any>;
  title: string;
  periodBegin: TDateTime;
  periodEnd: TDateTime;
}

export interface IPayoutDetails {
  id: number;
  user?: IUser;
  method: EPayoutMethod;
  data?: Record<string, number | string | null>;
  isDeleted?: boolean;
  deletedAt?: TDateTime | null;
  title: string;
  description: string;
  currency: ECurrency;
  minimalAmount: number;
  maximalAmount: number;
}

export interface ITrack {
  id: number;
  title: string;
  alias: string;
  artist: string;
  album: string | null;
  feat: string | null;
  version: string | null;
  authorLyrics: string;
  authorMusic: string;
  file?: IUpload;
  sample?: IUpload;
  cover: IUpload;
  author?: IAuthor;
  owner?: IUser | null;
  limit?: number | null;
  views?: number;
  genre?: EMusicGenre;
  mood?: EMusicMood;
  lyrics?: string | null;
  isrc?: string | null;
  duration?: number;
  isMonetize: boolean;
  isExcluded: boolean;
  language?: ELanguage;
  status?: EReviewStatus;
  buyPrice?: number;
  sellPrice?: number;
  isPublished: boolean;
  batch?: IBatch | null;
  videoType: EVideoType | null;
  isPaid?: boolean;
  isLeased?: boolean;
  leasedTill?: TDateTime | null;
  isMusic: boolean;
  extra?: Record<string, any>;
  rejectReason?: string | null;
  hasContentId: boolean;
  ownerId: number | null;
  isWorking?: boolean;
  videoCount: number | null;
}

export interface IUpload {
  id: string;
  path?: string;
  name?: string;
  mime?: string;
  size?: number;
  createdAt?: TDateTime;
  touchedAt?: TDateTime;
  isLocked?: boolean;
  fullPath?: string;
  url: string;
}

export interface IPayoutRequest {
  id: number;
  user?: IUser;
  amount: number;
  fee: number;
  currency: ECurrency;
  cost: number;
  details: IPayoutDetails;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  status: EPayoutStatus;
  reason: string | null;
  amountUsd: number | null;
}

export interface ITransaction {
  id: number;
  user?: IUser;
  type: ETransactionType;
  balanceBefore: number;
  amount: number;
  balanceAfter: number;
  comment: string | null;
  createdAt: TDateTime;
  extra?: Record<string, any>;
  amountUsd: number | null;
}

export interface IBatch {
  id: number;
  author?: IAuthor;
  isMonetize: boolean;
  aiContract?: IContract | null;
  ilContract?: IContract | null;
  ldContract?: IContract | null;
  tracks?: ITrack[];
  isFullContracts: boolean;
  isFullContentId: boolean;
  createdAt: TDateTime;
  tag: string;
}

export interface IReportStat {
  date?: string;
  country?: ECountry;
  track?: ITrack;
  views?: number;
  profit?: number;
}

export interface IContract {
  id: number;
  status: EContractStatus;
  type: EContractType;
  blank?: IUpload | null;
  signedOne?: IUpload | null;
  signedTwo?: IUpload | null;
  updatedAt: TDateTime;
  batch?: IBatch;
  tag: string;
  fullTag: string;
  createdAt: TDateTime;
}

export interface INotification {
  id: number;
  user?: IUser;
  icon: IUpload | null;
  message: string;
  isNew: boolean;
  createdAt: TDateTime;
  extra?: Record<string, any>;
}

export interface IVideo {
  id: number;
  extId: string;
  channel?: IChannel | null;
  hasTrack: boolean;
  track?: ITrack;
  cover: IUpload | null;
  title: string | null;
  status: EVideoStatus;
  type: EVideoType | null;
  totalViews: number;
  views: number;
  duration: number;
  createdAt?: TDateTime;
  checkedAt?: TDateTime;
}

export interface IUser {
  id: number;
  status: EUserStatus;
  email: string;
  telegram?: string | null;
  telegramId?: number | null;
  role: EUserRole;
  password?: string;
  createdAt?: TDateTime;
  balance?: number;
  credit?: number;
  ref?: IUser | null;
  refTag?: string;
  source?: string | null;
  uids?: string[];
  extra?: Record<string, any>;
  apiKey?: string | null;
  userIdentifier?: string;
  roles?: [];
  balanceUsd?: number | null;
  creditUsd?: number | null;
  extraItem?: any | null;
  locale: "ru" | "en" | null;
  tracksCount?: number;
  defaultLimit?: number;
}

export interface IChannelExt {
  id?: number;
  extId?: string;
  handle: string | null;
  name: string | null;
  icon: IUpload | null;
  isMonetizationEnabled?: boolean;
  subscribers?: number | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
}

export interface IDoc {
  id: number;
  parent?: IDoc | null;
  tag: string | null;
  title: string;
  content?: string | null;
  position: number;
  isPublished: boolean;
}

export interface IViewStat {
  item?: EViewStatItem;
  itemId?: number;
  date?: TDateTime;
  views?: number;
}

export interface IAuthor {
  id: number;
  user?: IUser;
  status: EReviewStatus;
  nationality: ECountry;
  firstName: string;
  lastName: string;
  middleName?: string;
  alias: string;
  regAddress?: string;
  postAddress?: string;
  passport?: IPassport;
  phone?: string;
  urls?: string[];
  createdAt?: TDateTime;
  passportScan?: IUpload | null;
  extra?: Record<string, any>;
  rejectReason: string | null;
  fullName: string;
}

export interface IApproveTrackRequest {
  monetize?: boolean;
}

export interface ICommonRejectRequest {
  reason: string;
}

export interface IUpdateContractRequest {
  blankId?: string | null;
  signedOneId?: string | null;
  signedTwoId?: string | null;
}

export interface ISignContractRequest {
  uploadId: string;
}

export interface IAddAuthorRequest {
  nationality: ECountry;
  lastName: string;
  firstName: string;
  middleName: string;
  alias: string;
  regAddress: string;
  postAddress: string;
  passport: IPassport;
  passportScanId: string;
  phone: string;
  urls: string[];
}

export interface IUpdateAuthorRequest {
  nationality?: ECountry;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  alias?: string;
  regAddress?: string;
  postAddress?: string;
  passport?: IPassport;
  passportScanId?: string;
  phone?: string;
  urls?: string[];
}

export interface IPassport {
  series: string;
  number: string;
  issuedBy: string;
  issuedAt: TDateTime;
}

export interface ISetContentIdRequest {
  ids?: Record<number, string | null>;
}

export interface IBatchZipStatus {
  token: string;
  progress: number;
  zip: IUpload | null;
  error: string | null;
}

export interface IGetBatchesRequest {
  mode: "contracts" | "content_id";
  page?: number;
  limit?: number;
}

export interface IGetStatsResponse<T> {
  minPeriod: TDateTime | null;
  maxPeriod: TDateTime | null;
  data: IStatsDataItem<T>[];
  totalViews: number | null;
  monetizedViews: number;
  profit: number;
  profitUsd: number;
  cpm: number | null;
  cpmUsd: number | null;
}

export interface IGetStatsRequest {
  group: "track" | "country" | "date";
  trackId?: number | number[];
  countryCode?: ECountry | ECountry[];
  periodBegin?: TDateTime | null;
  periodEnd?: TDateTime | null;
  sort: "totalViews" | "monetizedViews" | "profit" | "cpm";
  order: ESortOrder;
}

export interface IStatsDataItem<T> {
  item: T | null;
  totalViews: number | null;
  monetizedViews: number;
  profit: number;
  profitUsd: number;
  cpm: number | null;
  cpmUsd: number | null;
}

export interface IAddChannelExtRequest {
  channelId: string;
}

export interface IAddChannelRequest {
  name?: string | null;
  urls: string[];
  monetizationStatus: EMonetizationStatus;
  isNetwork?: boolean;
}

export interface IInitPartialUploadRequest {
  chunkSize: number;
  fileSize: number;
  fileName: string;
  fileType?: string;
  extra?: boolean;
  resize?: number | null;
  lock?: boolean;
}

export interface IPartialUploadStatus {
  id: string;
  fileName?: string;
  fileType?: string;
  fileSize?: number;
  chunkSize?: number;
  uploaded?: number;
  progress: number;
  upload: IUpload | null;
  request?: IInitPartialUploadRequest;
}

export interface IUploadRequest {
  upload: { name: string; data: string };
  extra?: boolean;
  resize?: number | null;
  lock?: boolean;
}

export interface IPartialChunkUploadRequest {
  id: string;
  chunk: string;
}

export interface ISetStorageItemRequest {
  key: string;
  value?: any | null;
  ttl?: number | null;
  isSecured?: boolean;
  isGlobal?: boolean;
}

export interface IGetStorageItemRequest {
  key: string;
  isGlobal?: boolean;
}

export interface IDeleteStorageItemRequest {
  key: string;
  isGlobal?: boolean;
}

export interface IReferralRecord {
  id: number;
  email: string;
  status: EUserStatus;
  rejectReason: string | null;
  source: string | null;
  channels: number;
  tracks: number;
  views: number;
  createdAt: TDateTime;
}

export interface ICreatePayoutDetailsRequest {
  method: EPayoutMethod;
  data: Record<string, number | string | null>;
}

export interface IGetBillingHistoryRequest {
  type?: ETransactionType;
  page?: number;
  limit?: number;
}

export interface IBillingConfig {
  usdtAddress: string;
  usdtRate: number;
  ymAddress: string;
}

export interface IGetPayoutsRequest {
  status?: EPayoutStatus;
  actual?: boolean;
  page?: number;
  limit?: number;
}

export interface IConfirmUsdtTransactionRequest {
  hash: string;
}

export interface ICreatePayoutRequest {
  amount: number;
  dry?: boolean;
}

export interface IDocPlainNode {
  id: number;
  title: string;
  level: number;
  isPublished: boolean;
}

export interface ICreateDocRequest {
  parentId?: number | null;
  position?: number;
  title: string;
  content?: string | null;
  tag?: string | null;
  imageIds?: string[];
  isPublished?: boolean;
}

export interface ISortDocsRequest {
  docIds: number[];
}

export interface IGetDocsRequest {
  parentId?: number | null;
  onlyPublished?: boolean;
}

export interface IGetPlainTreeRequest {
  parentId?: number | null;
  excludeIds?: number[];
}

export interface IDocNode {
  id: number | null;
  title: string;
  children: IDocNode[];
}

export interface IDocItem {
  id: number;
  title: string;
}

export interface IUpdateDocRequest {
  parentId?: number | null;
  position?: number;
  title?: string;
  content?: string | null;
  tag?: string | null;
  imageIds?: string[];
  isPublished?: boolean;
}

export interface IBulkAddVideoReport {
  request: string;
  trackId: number;
  items: IBulkAddVideoReportItem[];
  addedCount: number;
  errorsCount: number;
  addingCount: number;
  isFinished: boolean;
}

export interface IBulkAddVideoRequest {
  trackId: number;
  extIds: [];
}

export interface ICheckVideoRequest {
  extIds: [];
}

export interface IAddVideoRequest {
  trackId: number;
  url: string;
  force?: boolean;
}

export interface IBulkAddVideoReportItem {
  extId: string;
  video: IVideo | null;
  status: "adding" | "success" | "error";
  error: string | null;
}

export interface ICountVideosRequest {
  trackIds: number[];
}

export interface IAdminUpdateTrackRequest {
  limit?: number;
}

export interface IGetMyTracksRequest {
  query?: string;
}

export interface IGetTracksRequest {
  query?: string;
  batchId?: number;
  authorId?: number;
  hasContentId?: boolean;
  hasOwner?: boolean;
  isPublished?: boolean;
  isPaid?: boolean;
  genre?: EMusicGenre | EMusicGenre[];
  mood?: EMusicMood | EMusicMood[];
  language?: ELanguage | ELanguage[];
  priceFrom?: number;
  priceTill?: number;
  status?: EReviewStatus;
  sort?: "id" | "views" | "price";
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface IGetTrackVideoRequest {
  query?: string;
  status?: EVideoStatus;
  page?: number;
  limit?: number;
}

export interface IAddTrackRequest {
  authorId: number;
  ownerId?: number | null;
  fileId: string;
  sampleId: string;
  coverId: string;
  title: string;
  artist: string;
  alias: string;
  album?: string | null;
  genre?: EMusicGenre;
  mood?: EMusicMood;
  lyrics: string;
  authorLyrics: string;
  authorMusic: string;
  feat?: string | null;
  version?: string | null;
  language?: ELanguage;
  sellPrice: number;
  isMusic?: boolean;
}

export interface ITracksLimits {
  free: Record<EVideoType, number>;
  tracks: Record<EVideoType, number>;
}

export interface IUpdateTrackRequest {
  fileId?: string;
  sampleId?: string;
  coverId?: string;
  title?: string;
  artist?: string;
  alias?: string;
  album?: string | null;
  genre?: EMusicGenre;
  mood?: EMusicMood;
  lyrics?: string;
  authorLyrics?: string;
  authorMusic?: string;
  feat?: string | null;
  version?: string | null;
  language?: ELanguage;
  sellPrice?: number;
  buyPrice?: number;
  isMusic?: boolean;
}

export interface IImportReportRequest {
  type: EReportType;
  year: number;
  uploadId: string;
}

export interface IImportStatus {
  token: string;
  reportId: number;
  status: "queued" | "processing" | "failed" | "done";
  progress: number;
  processedCount: number;
  skipCount: number;
  errorsCount: number;
  totalCount: number | null;
  errors: [];
}

export interface IGetUsersListRequest {
  query?: string | null;
  role?: EUserRole | null;
  status?: EUserStatus | null;
  page?: number;
  limit?: number;
}

export interface IUpdateUserRequest {
  password?: string;
  email?: string;
  defaultLimit?: number;
  status?: EUserStatus;
}

export interface IPasswordRequest {
  email: string;
}

export interface ILoginRequest {
  email: string;
  password: string;
  remember?: boolean;
}

export interface IChargeUserRequest {
  amount: number;
  type?: ETransactionType;
  comment: string;
}

export interface IUpdateProfileRequest {
  email?: string;
  telegram: string;
  password?: string;
}

export interface ISubmitFormRequest {
  token: string;
  form: Record<string, any>;
}

export interface IRegisterRequest {
  email: string;
  telegram?: string | null;
  password: string;
  role: EUserRole;
  ref?: string | null;
  source?: string | null;
  uid?: string | null;
  channels?: [];
}

export enum EVideoStatus {
  Public = "public",
  Unlisted = "unlisted",
  Unavailable = "unavailable",
}

export enum ECurrency {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTC = "BTC",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CLF = "CLF",
  CLP = "CLP",
  CNH = "CNH",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUC = "CUC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEL = "GEL",
  GGP = "GGP",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  IMP = "IMP",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JEP = "JEP",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRU = "MRU",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STD = "STD",
  STN = "STN",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VES = "VES",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XAG = "XAG",
  XAU = "XAU",
  XCD = "XCD",
  XDR = "XDR",
  XOF = "XOF",
  XPD = "XPD",
  XPF = "XPF",
  XPT = "XPT",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL",
}

export enum EMusicMood {
  Angry = "angry",
  Bright = "bright",
  Calm = "calm",
  Dark = "dark",
  Dramatic = "dramatic",
  Funky = "funky",
  Happy = "happy",
  Inspirational = "inspirational",
  Romantic = "romantic",
  Sad = "sad",
  Other = "other",
}

export enum ECountry {
  AU = "au",
  AT = "at",
  AZ = "az",
  AX = "ax",
  AL = "al",
  DZ = "dz",
  VI = "vi",
  AS = "as",
  AI = "ai",
  AO = "ao",
  AD = "ad",
  AQ = "aq",
  AG = "ag",
  AR = "ar",
  AM = "am",
  AW = "aw",
  AF = "af",
  BS = "bs",
  BD = "bd",
  BB = "bb",
  BH = "bh",
  BZ = "bz",
  BY = "by",
  BE = "be",
  BJ = "bj",
  BM = "bm",
  BG = "bg",
  BO = "bo",
  BQ = "bq",
  BA = "ba",
  BW = "bw",
  BR = "br",
  IO = "io",
  VG = "vg",
  BN = "bn",
  BF = "bf",
  BI = "bi",
  BT = "bt",
  VU = "vu",
  VA = "va",
  GB = "gb",
  HU = "hu",
  VE = "ve",
  UM = "um",
  TL = "tl",
  VN = "vn",
  GA = "ga",
  HT = "ht",
  GY = "gy",
  GM = "gm",
  GH = "gh",
  GP = "gp",
  GT = "gt",
  GF = "gf",
  GN = "gn",
  GW = "gw",
  DE = "de",
  GG = "gg",
  GI = "gi",
  HN = "hn",
  HK = "hk",
  GD = "gd",
  GL = "gl",
  GR = "gr",
  GE = "ge",
  GU = "gu",
  DK = "dk",
  JE = "je",
  DJ = "dj",
  DM = "dm",
  DO = "do",
  CD = "cd",
  EU = "eu",
  EG = "eg",
  ZM = "zm",
  EH = "eh",
  ZW = "zw",
  IL = "il",
  IN = "in",
  ID = "id",
  JO = "jo",
  IQ = "iq",
  IR = "ir",
  IE = "ie",
  IS = "is",
  ES = "es",
  IT = "it",
  YE = "ye",
  CV = "cv",
  KZ = "kz",
  KY = "ky",
  KH = "kh",
  CM = "cm",
  CA = "ca",
  QA = "qa",
  KE = "ke",
  CY = "cy",
  KG = "kg",
  KI = "ki",
  TW = "tw",
  KP = "kp",
  CN = "cn",
  CC = "cc",
  CO = "co",
  KM = "km",
  CR = "cr",
  CI = "ci",
  CU = "cu",
  KW = "kw",
  CW = "cw",
  LA = "la",
  LV = "lv",
  LS = "ls",
  LR = "lr",
  LB = "lb",
  LY = "ly",
  LT = "lt",
  LI = "li",
  LU = "lu",
  MU = "mu",
  MR = "mr",
  MG = "mg",
  YT = "yt",
  MO = "mo",
  MK = "mk",
  MW = "mw",
  MY = "my",
  ML = "ml",
  MV = "mv",
  MT = "mt",
  MA = "ma",
  MQ = "mq",
  MH = "mh",
  MX = "mx",
  FM = "fm",
  MZ = "mz",
  MD = "md",
  MC = "mc",
  MN = "mn",
  MS = "ms",
  MM = "mm",
  NA = "na",
  NR = "nr",
  NP = "np",
  NE = "ne",
  NG = "ng",
  NL = "nl",
  NI = "ni",
  NU = "nu",
  NZ = "nz",
  NC = "nc",
  NO = "no",
  AE = "ae",
  OM = "om",
  BV = "bv",
  IM = "im",
  CK = "ck",
  NF = "nf",
  CX = "cx",
  PN = "pn",
  SH = "sh",
  PK = "pk",
  PW = "pw",
  PS = "ps",
  PA = "pa",
  PG = "pg",
  PY = "py",
  PE = "pe",
  PL = "pl",
  PT = "pt",
  PR = "pr",
  CG = "cg",
  KR = "kr",
  RE = "re",
  RU = "ru",
  RW = "rw",
  RO = "ro",
  SV = "sv",
  WS = "ws",
  SM = "sm",
  ST = "st",
  SA = "sa",
  SZ = "sz",
  MP = "mp",
  SC = "sc",
  BL = "bl",
  MF = "mf",
  PM = "pm",
  SN = "sn",
  VC = "vc",
  KN = "kn",
  LC = "lc",
  RS = "rs",
  SG = "sg",
  SX = "sx",
  SY = "sy",
  SK = "sk",
  SI = "si",
  SB = "sb",
  SO = "so",
  SD = "sd",
  SR = "sr",
  US = "us",
  SL = "sl",
  TJ = "tj",
  TH = "th",
  TZ = "tz",
  TC = "tc",
  TG = "tg",
  TK = "tk",
  TO = "to",
  TT = "tt",
  TV = "tv",
  TN = "tn",
  TM = "tm",
  TR = "tr",
  UG = "ug",
  UZ = "uz",
  UA = "ua",
  WF = "wf",
  UY = "uy",
  FO = "fo",
  FJ = "fj",
  PH = "ph",
  FI = "fi",
  FK = "fk",
  FR = "fr",
  PF = "pf",
  TF = "tf",
  HM = "hm",
  HR = "hr",
  CF = "cf",
  TD = "td",
  ME = "me",
  CZ = "cz",
  CL = "cl",
  CH = "ch",
  SE = "se",
  SJ = "sj",
  LK = "lk",
  EC = "ec",
  GQ = "gq",
  ER = "er",
  EE = "ee",
  ET = "et",
  ZA = "za",
  GS = "gs",
  SS = "ss",
  JM = "jm",
  JP = "jp",
}

export enum EVideoType {
  Video = "video",
  Short = "short",
}

export enum EUserRole {
  Admin = "admin",
  Author = "author",
  Owner = "owner",
  Scout = "scout",
  Registrar = "registrar",
}

export enum EContractType {
  Author_Individual = "AI",
  Individual_LLC = "IL",
  LLC_Distributor = "LD",
}

export enum EPayoutStatus {
  Pending = "pending",
  Processing = "processing",
  Success = "success",
  Failed = "failed",
  Canceled = "canceled",
}

export enum ETransactionType {
  Payin = "payin",
  Payout = "payout",
  Referral = "referral",
  Spending = "spending",
  Selling = "selling",
  Promo = "promo",
  Other = "other",
}

export enum EMusicGenre {
  AlternativeAndPunk = "alternative-punk",
  Ambient = "ambient",
  Children = "children",
  Cinematic = "cinematic",
  Classical = "classical",
  CountryAndFolk = "country-folk",
  DanceAndElectronic = "dance-electronic",
  HipHopAndRap = "hip-hop-rap",
  Holiday = "holiday",
  JazzAndBlues = "jazz-blues",
  Pop = "pop",
  RnBAndSoul = "rnb-soul",
  Reggae = "reggae",
  Rock = "rock",
  Other = "other",
}

export enum EPayoutMethod {
  RuCard = "ru-card",
  OtherCard = "other-card",
  USDT = "usdt-trc20",
}

export enum ELanguage {
  Abkhazian = "ab",
  Afar = "aa",
  Afrikaans = "af",
  Akan = "ak",
  Albanian = "sq",
  Amharic = "am",
  Arabic = "ar",
  Aragonese = "an",
  Armenian = "hy",
  Assamese = "as",
  Avaric = "av",
  Avestan = "ae",
  Aymara = "ay",
  Azerbaijani = "az",
  Bambara = "bm",
  Bashkir = "ba",
  Basque = "eu",
  Belarusian = "be",
  Bengali = "bn",
  Bislama = "bi",
  Bosnian = "bs",
  Breton = "br",
  Bulgarian = "bg",
  Burmese = "my",
  Catalan = "ca",
  Chamorro = "ch",
  Chechen = "ce",
  Chichewa = "ny",
  Chinese = "zh",
  ChurchSlavonic = "cu",
  Chuvash = "cv",
  Cornish = "kw",
  Corsican = "co",
  Cree = "cr",
  Croatian = "hr",
  Czech = "cs",
  Danish = "da",
  Divehi = "dv",
  Dutch = "nl",
  Dzongkha = "dz",
  English = "en",
  Esperanto = "eo",
  Estonian = "et",
  Ewe = "ee",
  Faroese = "fo",
  Fijian = "fj",
  Finnish = "fi",
  French = "fr",
  WesternFrisian = "fy",
  Fulah = "ff",
  Gaelic = "gd",
  Galician = "gl",
  Ganda = "lg",
  Georgian = "ka",
  German = "de",
  Greek = "el",
  Kalaallisut = "kl",
  Guarani = "gn",
  Gujarati = "gu",
  Haitian = "ht",
  Hausa = "ha",
  Hebrew = "he",
  Herero = "hz",
  Hindi = "hi",
  HiriMotu = "ho",
  Hungarian = "hu",
  Icelandic = "is",
  Ido = "io",
  Igbo = "ig",
  Indonesian = "id",
  Interlingua = "ia",
  Interlingue = "ie",
  Inuktitut = "iu",
  Inupiaq = "ik",
  Irish = "ga",
  Italian = "it",
  Japanese = "ja",
  Javanese = "jv",
  Kannada = "kn",
  Kanuri = "kr",
  Kashmiri = "ks",
  Kazakh = "kk",
  CentralKhmer = "km",
  Kikuyu = "ki",
  Kinyarwanda = "rw",
  Kirghiz = "ky",
  Komi = "kv",
  Kongo = "kg",
  Korean = "ko",
  Kuanyama = "kj",
  Kurdish = "ku",
  Lao = "lo",
  Latin = "la",
  Latvian = "lv",
  Limburgan = "li",
  Lingala = "ln",
  Lithuanian = "lt",
  LubaKatanga = "lu",
  Luxembourgish = "lb",
  Macedonian = "mk",
  Malagasy = "mg",
  Malay = "ms",
  Malayalam = "ml",
  Maltese = "mt",
  Manx = "gv",
  Maori = "mi",
  Marathi = "mr",
  Marshallese = "mh",
  Mongolian = "mn",
  Nauru = "na",
  Navajo = "nv",
  NorthNdebele = "nd",
  SouthNdebele = "nr",
  Ndonga = "ng",
  Nepali = "ne",
  Norwegian = "no",
  NorwegianBokmal = "nb",
  NorwegianNynorsk = "nn",
  SichuanYi = "ii",
  Occitan = "oc",
  Ojibwa = "oj",
  Oriya = "or",
  Oromo = "om",
  Ossetian = "os",
  Pali = "pi",
  Pashto = "ps",
  Persian = "fa",
  Polish = "pl",
  Portuguese = "pt",
  Punjabi = "pa",
  Quechua = "qu",
  Romanian = "ro",
  Romansh = "rm",
  Rundi = "rn",
  Russian = "ru",
  NorthernSami = "se",
  Samoan = "sm",
  Sango = "sg",
  Sanskrit = "sa",
  Sardinian = "sc",
  Serbian = "sr",
  Shona = "sn",
  Sindhi = "sd",
  Sinhala = "si",
  Slovak = "sk",
  Slovenian = "sl",
  Somali = "so",
  SouthernSotho = "st",
  Spanish = "es",
  Sundanese = "su",
  Swahili = "sw",
  Swati = "ss",
  Swedish = "sv",
  Tagalog = "tl",
  Tahitian = "ty",
  Tajik = "tg",
  Tamil = "ta",
  Tatar = "tt",
  Telugu = "te",
  Thai = "th",
  Tibetan = "bo",
  Tigrinya = "ti",
  Tonga = "to",
  Tsonga = "ts",
  Tswana = "tn",
  Turkish = "tr",
  Turkmen = "tk",
  Twi = "tw",
  Uighur = "ug",
  Ukrainian = "uk",
  Urdu = "ur",
  Uzbek = "uz",
  Venda = "ve",
  Vietnamese = "vi",
  Volapuk = "vo",
  Walloon = "wa",
  Welsh = "cy",
  Wolof = "wo",
  Xhosa = "xh",
  Yiddish = "yi",
  Yoruba = "yo",
  Zhuang = "za",
  Zulu = "zu",
}

export enum EMonetizationStatus {
  Pending = "pending",
  Active = "active",
  Blocked = "blocked",
}

export enum EViewStatItem {
  Video = "video",
  Track = "track",
}

export enum EReportType {
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
  January = "M01",
  February = "M02",
  March = "M03",
  April = "M04",
  May = "M05",
  June = "M06",
  July = "M07",
  August = "M08",
  September = "M09",
  October = "M10",
  November = "M11",
  December = "M12",
}

export enum EUserStatus {
  InReview = "in-review",
  Active = "active",
  Rejected = "rejected",
  Suspended = "suspended",
  Blocked = "blocked",
}

export enum EReviewStatus {
  Draft = "draft",
  InReview = "in-review",
  Approved = "approved",
  Rejected = "rejected",
}

export enum EContractStatus {
  Blank = "blank",
  SignedOne = "signed-one",
  SignedTwo = "signed-two",
}

export enum ESortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface IPagedData<T> {
  page: number;
  limit: number;
  count: number | null;
  pages: number | null;
  data: T[];
}

export enum EFieldGroup {
  ChannelOwner = "channel:owner",
  ChannelDate = "channel:date",
  ReportFile = "report:file",
  DetailsData = "details:data",
  TrackList = "track:list",
  TrackItem = "track:item",
  TrackFile = "track:file",
  TrackAuthor = "track:author",
  TrackOwner = "track:owner",
  TrackLimit = "track:limit",
  TrackViews = "track:views",
  TrackIsrc = "track:isrc",
  TrackBuy = "track:buy",
  TrackPrice = "track:price",
  TrackSell = "track:sell",
  TrackBatch = "track:batch",
  TrackLease = "track:lease",
  UploadName = "upload:name",
  UploadFull = "upload:full",
  UploadMime = "upload:mime",
  UploadSize = "upload:size",
  PayoutUser = "payout:user",
  TransactionUser = "transaction:user",
  TransactionFull = "transaction:full",
  BatchAuthor = "batch:author",
  BatchContractAi = "batch:contract:ai",
  BatchContracts = "batch:contracts",
  BatchContractIl = "batch:contract:il",
  BatchContractLd = "batch:contract:ld",
  BatchTracks = "batch:tracks",
  ContractItem = "contract:item",
  VideoChannel = "video:channel",
  VideoTrack = "video:track",
  UserProfile = "user:profile",
  UserTracksCount = "user:tracks:count",
  UserLimit = "user:limit",
  UserItem = "user:item",
  UserTelegram = "user:telegram",
  UserList = "user:list",
  UserRef = "user:ref",
  UserSource = "user:source",
  UserUids = "user:uids",
  UserExtra = "user:extra",
  UserApikey = "user:apikey",
  DocParent = "doc:parent",
  DocContent = "doc:content",
  AuthorUser = "author:user",
  AuthorItem = "author:item",
  AuthorUrls = "author:urls",
}

class Batches {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** getBatchesList */
  getBatchesList = (
    request: IGetBatchesRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IBatch>> => this.api.get(`/batches`, request, fields);

  /** getBatch */
  getBatch = (batch: TIdentifier, fields?: EFieldGroup[]): Promise<IBatch> =>
    this.api.get(`/batches/${batch}`, {}, fields);

  /** startBatchZip */
  startBatchZip = (
    batch: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IBatchZipStatus> =>
    this.api.post(`/batches/${batch}/zip`, {}, fields);

  /** getBatchZipStatus */
  getBatchZipStatus = (
    token: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IBatchZipStatus> =>
    this.api.get(`/batches/${token}/zip`, {}, fields);

  /** setContentIds */
  setContentIds = (
    batch: TIdentifier,
    request: ISetContentIdRequest,
    fields?: EFieldGroup[]
  ): Promise<IBatch> =>
    this.api.patch(`/batches/${batch}/ids`, request, fields);

  /** getApprovedTracksForBatch */
  getApprovedTracksForBatch = (
    author: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<ITrack[]> =>
    this.api.get(`/author/${author}/approved-tracks`, {}, fields);

  /** createBatch */
  createBatch = (
    author: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IBatch> => this.api.post(`/author/${author}/batches`, {}, fields);
}

class Billing {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** getHistory */
  getHistory = (
    request: IGetBillingHistoryRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<ITransaction>> =>
    this.api.get(`/billing/history`, request, fields);

  /** confirmUsdtTransaction */
  confirmUsdtTransaction = (
    request: IConfirmUsdtTransactionRequest,
    fields?: EFieldGroup[]
  ): Promise<true> => this.api.post(`/billing/ustd/confirm`, request, fields);

  /** getConfig */
  getConfig = (fields?: EFieldGroup[]): Promise<IBillingConfig> =>
    this.api.get(`/billing/config`, {}, fields);

  /** createPayoutRequest */
  createPayoutRequest = (
    details: TIdentifier,
    request: ICreatePayoutRequest,
    fields?: EFieldGroup[]
  ): Promise<IPayoutRequest> =>
    this.api.post(`/billing/payout/details/${details}/payout`, request, fields);

  /** createPayoutDetails */
  createPayoutDetails = (
    request: ICreatePayoutDetailsRequest,
    fields?: EFieldGroup[]
  ): Promise<IPayoutDetails> =>
    this.api.post(`/billing/payout/details`, request, fields);

  /** getPayoutDetails */
  getPayoutDetails = (fields?: EFieldGroup[]): Promise<IPayoutDetails[]> =>
    this.api.get(`/billing/payout/details`, {}, fields);

  /** deletePayoutDetails */
  deletePayoutDetails = (
    details: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<true> =>
    this.api.delete(`/billing/payout/details/${details}`, {}, fields);
}

class Stats {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** getTracksStats */
  getTracksStats = <T>(
    request: IGetStatsRequest,
    fields?: EFieldGroup[]
  ): Promise<IGetStatsResponse<T>> => this.api.get(`/stats`, request, fields);
}

class Videos {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** bulkAddVideo */
  bulkAddVideo = (
    request: IBulkAddVideoRequest,
    fields?: EFieldGroup[]
  ): Promise<IBulkAddVideoReport> =>
    this.api.post(`/video/bulk`, request, fields);

  /** bulkAddReport */
  bulkAddReport = (
    request: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IBulkAddVideoReport> =>
    this.api.get(`/video/bulk/${request}`, {}, fields);

  /** addVideo */
  addVideo = (
    request: IAddVideoRequest,
    fields?: EFieldGroup[]
  ): Promise<IVideo> => this.api.post(`/video`, request, fields);

  /** check */
  check = (
    request: ICheckVideoRequest,
    fields?: EFieldGroup[]
  ): Promise<string[]> => this.api.post(`/video/check`, request, fields);

  /** cutoutTrack */
  cutoutTrack = (video: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.delete(`/video/${video}/track`, {}, fields);

  /** deleteVideo */
  deleteVideo = (video: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.delete(`/video/${video}`, {}, fields);
}

class Users {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** loginAs */
  loginAs = (
    user: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/${user}/auth`, {}, fields);

  /** charge */
  charge = (
    user: TIdentifier,
    request: IChargeUserRequest,
    fields?: EFieldGroup[]
  ): Promise<ITransaction> =>
    this.api.post(`/users/${user}/charge`, request, fields);

  /** getApiKey */
  getApiKey = (fields?: EFieldGroup[]): Promise<string | null> =>
    this.api.get(`/users/api-key`, {}, fields);

  /** setApiKey */
  setApiKey = (fields?: EFieldGroup[]): Promise<string> =>
    this.api.post(`/users/api-key`, {}, fields);

  /** deleteApiKey */
  deleteApiKey = (fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/users/api-key`, {}, fields);

  /** getList */
  getList = (
    request: IGetUsersListRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IUser>> => this.api.get(`/users`, request, fields);

  /** register */
  register = (
    request: IRegisterRequest,
    fields?: EFieldGroup[]
  ): Promise<{ token: string; id: number }> =>
    this.api.post(`/users`, request, fields);

  /** form */
  form = (
    request: ISubmitFormRequest,
    fields?: EFieldGroup[]
  ): Promise<number> => this.api.post(`/users/form`, request, fields);

  /** login */
  login = (
    request: ILoginRequest,
    fields?: EFieldGroup[]
  ): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/login`, request, fields);

  /** password */
  password = (
    request: IPasswordRequest,
    fields?: EFieldGroup[]
  ): Promise<true> => this.api.post(`/users/password`, request, fields);

  /** getMe */
  getMe = (fields?: EFieldGroup[]): Promise<{ token: string; user: IUser }> =>
    this.api.get(`/users/me`, {}, fields);

  /** updateProfile */
  updateProfile = (
    request: IUpdateProfileRequest,
    fields?: EFieldGroup[]
  ): Promise<IUser> => this.api.post(`/users/me`, request, fields);

  /** linkTelegram */
  linkTelegram = (
    token: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.post(`/users/telegram/${token}/link`, {}, fields);

  /** getUserById */
  getUserById = (user: TIdentifier, fields?: EFieldGroup[]): Promise<IUser> =>
    this.api.get(`/users/${user}`, {}, fields);

  /** updateUser */
  updateUser = (
    user: TIdentifier,
    request: IUpdateUserRequest,
    fields?: EFieldGroup[]
  ): Promise<IUser> => this.api.patch(`/users/${user}`, request, fields);
}

class Uploads {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** Загрузить в Base64 */
  uploadJson = (
    request: IUploadRequest,
    fields?: EFieldGroup[]
  ): Promise<IUpload> => this.api.post(`/upload/base64`, request, fields);

  /** Загрузить с FormData */
  uploadForm = (fields?: EFieldGroup[]): Promise<IUpload> =>
    this.api.post(`/upload/form`, {}, fields);

  /** Частичная загрузка (init) */
  beginPartial = (
    request: IInitPartialUploadRequest,
    fields?: EFieldGroup[]
  ): Promise<IPartialUploadStatus> =>
    this.api.post(`/upload/partial`, request, fields);

  /** Частичная загрузка (chunk) */
  chunkPartial = (
    request: IPartialChunkUploadRequest,
    fields?: EFieldGroup[]
  ): Promise<IPartialUploadStatus> =>
    this.api.patch(`/upload/partial`, request, fields);

  /** Информация о файле */
  getUpload = (file: TIdentifier, fields?: EFieldGroup[]): Promise<IUpload> =>
    this.api.get(`/upload/${file}`, {}, fields);
}

class Help {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** Index */
  index = (request: IGetDocsRequest, fields?: EFieldGroup[]): Promise<IDoc[]> =>
    this.api.get(`/help`, request, fields);

  /** Get path */
  getPath = (doc: TIdentifier, fields?: EFieldGroup[]): Promise<IDocItem[]> =>
    this.api.get(`/help/${doc}/path`, {}, fields);

  /** Get plain tree */
  getPlainTree = (
    request: IGetPlainTreeRequest,
    fields?: EFieldGroup[]
  ): Promise<IDocPlainNode[]> =>
    this.api.get(`/help/plain-tree`, request, fields);

  /** Create */
  create = (
    request: ICreateDocRequest,
    fields?: EFieldGroup[]
  ): Promise<IDoc> => this.api.post(`/help`, request, fields);

  /** Sort */
  sort = (request: ISortDocsRequest, fields?: EFieldGroup[]): Promise<IDoc[]> =>
    this.api.patch(`/help/sort`, request, fields);

  /** Update */
  update = (
    doc: TIdentifier,
    request: IUpdateDocRequest,
    fields?: EFieldGroup[]
  ): Promise<IDoc> => this.api.patch(`/help/${doc}`, request, fields);

  /** Get by Tag */
  getByTag = (tag: TIdentifier, fields?: EFieldGroup[]): Promise<IDoc> =>
    this.api.get(`/help/tag/${tag}`, {}, fields);

  /** Get by Id */
  getById = (doc: TIdentifier, fields?: EFieldGroup[]): Promise<IDoc> =>
    this.api.get(`/help/${doc}`, {}, fields);

  /** Delete doc */
  delete = (doc: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/help/${doc}`, {}, fields);
}

class Referrals {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** getList */
  getList = (fields?: EFieldGroup[]): Promise<IReferralRecord[]> =>
    this.api.get(`/referrals`, {}, fields);
}

class Payouts {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** getPayoutRequests */
  getPayoutRequests = (
    request: IGetPayoutsRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IPayoutRequest>> =>
    this.api.get(`/payouts`, request, fields);

  /** getPayoutRequest */
  getPayoutRequest = (
    payout: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IPayoutRequest> => this.api.get(`/payouts/${payout}`, {}, fields);

  /** cancelPayoutRequests */
  cancelPayoutRequests = (
    payout: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.patch(`/payouts/${payout}/cancel`, {}, fields);

  /** retryPayoutRequests */
  retryPayoutRequests = (
    payout: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.patch(`/payouts/${payout}/retry`, {}, fields);

  /** processPayoutRequests */
  processPayoutRequests = (
    payout: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.patch(`/payouts/${payout}/process`, {}, fields);

  /** successPayoutRequests */
  successPayoutRequests = (
    payout: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.patch(`/payouts/${payout}/success`, {}, fields);

  /** failPayoutRequests */
  failPayoutRequests = (
    payout: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.patch(`/payouts/${payout}/fail`, request, fields);

  /** pendingPayoutRequests */
  pendingPayoutRequests = (
    payout: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.patch(`/payouts/${payout}/pending`, {}, fields);
}

class Contracts {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** signContract */
  signContract = (
    contract: TIdentifier,
    request: ISignContractRequest,
    fields?: EFieldGroup[]
  ): Promise<IContract> =>
    this.api.post(`/contracts/${contract}/sign`, request, fields);

  /** updateContract */
  updateContract = (
    contract: TIdentifier,
    request: IUpdateContractRequest,
    fields?: EFieldGroup[]
  ): Promise<IContract> =>
    this.api.patch(`/contracts/${contract}`, request, fields);
}

class Main {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** version */
  version = (
    version: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<{ frontend: string; backend: string; upgrade: boolean }> =>
    this.api.get(`/version/${version}`, {}, fields);

  /** checkVersion */
  checkVersion = (
    item: TIdentifier,
    version: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<{ version: string; upgrade: boolean }> =>
    this.api.get(`/version/${item}/${version}`, {}, fields);
}

class Channels {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** addChannelExt */
  addChannelExt = (
    request: IAddChannelExtRequest,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.post(`/channels/ext`, request, fields);

  /** getChannelsList */
  getChannelsList = (fields?: EFieldGroup[]): Promise<IChannel[]> =>
    this.api.get(`/channels`, {}, fields);

  /** getChannel */
  getChannel = (
    channel: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IChannel> => this.api.get(`/channels/${channel}`, {}, fields);

  /** lookupChannel */
  lookupChannel = (
    query: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.get(`/channels/lookup/${query}`, {}, fields);
}

class Reports {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** upload */
  upload = (
    request: IImportReportRequest,
    fields?: EFieldGroup[]
  ): Promise<IImportStatus> => this.api.post(`/reports`, request, fields);

  /** getStatus */
  getStatus = (
    token: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IImportStatus> =>
    this.api.get(`/reports/status/${token}`, {}, fields);
}

class Admin {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** getOwnersInReview */
  getOwnersInReview = (fields?: EFieldGroup[]): Promise<IUser[]> =>
    this.api.get(`/admin/review/owners`, {}, fields);

  /** approveOwner */
  approveOwner = (user: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.post(`/admin/review/owners/${user}/approve`, {}, fields);

  /** rejectOwner */
  rejectOwner = (
    user: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<true> =>
    this.api.post(`/admin/review/owners/${user}/reject`, request, fields);

  /** suspendUser */
  suspendUser = (
    user: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.post(`/admin/users/${user}/suspend`, request, fields);

  /** getTracksInReview */
  getTracksInReview = (fields?: EFieldGroup[]): Promise<ITrack[]> =>
    this.api.get(`/admin/review/tracks`, {}, fields);

  /** approveTrack */
  approveTrack = (
    track: TIdentifier,
    request: IApproveTrackRequest,
    fields?: EFieldGroup[]
  ): Promise<true> =>
    this.api.post(`/admin/review/tracks/${track}/approve`, request, fields);

  /** rejectTrack */
  rejectTrack = (
    track: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<true> =>
    this.api.post(`/admin/review/tracks/${track}/reject`, request, fields);

  /** getAuthorsInReview */
  getAuthorsInReview = (fields?: EFieldGroup[]): Promise<IAuthor[]> =>
    this.api.get(`/admin/review/authors`, {}, fields);

  /** approveAuthor */
  approveAuthor = (
    author: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<true> =>
    this.api.post(`/admin/review/authors/${author}/approve`, {}, fields);

  /** rejectAuthor */
  rejectAuthor = (
    author: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<true> =>
    this.api.post(`/admin/review/authors/${author}/reject`, request, fields);

  /** exportVideo */
  exportVideo = (fields?: EFieldGroup[]): Promise<string> =>
    this.api.post(`/admin/export-video`, {}, fields);

  /** exportVideoStatus */
  exportVideoStatus = (
    token: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<{
    status: "queued" | "working" | "error" | "done";
    url?: string;
    error?: string;
  }> => this.api.get(`/admin/export-video/${token}`, {}, fields);
}

class AdminStats {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** getTracksViewsHistory */
  getTracksViewsHistory = (
    fields?: EFieldGroup[]
  ): Promise<{ date: string; views: number; views_inc: number }[]> =>
    this.api.get(`/admin/stats/tracks`, {}, fields);
}

class Tracks {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** getOptions */
  getOptions = (
    fields?: EFieldGroup[]
  ): Promise<{ languages: ELanguage[]; genres: EMusicGenre[] }> =>
    this.api.get(`/tracks/options`, {}, fields);

  /** getLimits */
  getLimits = (fields?: EFieldGroup[]): Promise<ITracksLimits> =>
    this.api.get(`/tracks/limits`, {}, fields);

  /** getMyTracks */
  getMyTracks = (
    request: IGetMyTracksRequest,
    fields?: EFieldGroup[]
  ): Promise<ITrack[]> => this.api.get(`/tracks/my`, request, fields);

  /** getFreeTracks */
  getFreeTracks = (fields?: EFieldGroup[]): Promise<ITrack[]> =>
    this.api.get(`/tracks/free`, {}, fields);

  /** getFreeTracksIds */
  getFreeTracksIds = (fields?: EFieldGroup[]): Promise<number[]> =>
    this.api.get(`/tracks/free/ids`, {}, fields);

  /** getVideosCount */
  getVideosCount = (
    request: ICountVideosRequest,
    fields?: EFieldGroup[]
  ): Promise<Record<TIdentifier, number>> =>
    this.api.get(`/tracks/count-videos`, request, fields);

  /** getTracksList */
  getTracksList = (
    request: IGetTracksRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<ITrack>> => this.api.get(`/tracks`, request, fields);

  /** addTrack */
  addTrack = (
    request: IAddTrackRequest,
    fields?: EFieldGroup[]
  ): Promise<ITrack> => this.api.post(`/tracks`, request, fields);

  /** adminUpdateTrack */
  adminUpdateTrack = (
    track: TIdentifier,
    request: IAdminUpdateTrackRequest,
    fields?: EFieldGroup[]
  ): Promise<ITrack> =>
    this.api.patch(`/tracks/admin/${track}`, request, fields);

  /** updateTrack */
  updateTrack = (
    track: TIdentifier,
    request: IUpdateTrackRequest,
    fields?: EFieldGroup[]
  ): Promise<ITrack> => this.api.patch(`/tracks/${track}`, request, fields);

  /** getLastAuthorTrack */
  getLastAuthorTrack = (
    author: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<ITrack | null> =>
    this.api.get(`/tracks/${author}/last`, {}, fields);

  /** getTrack */
  getTrack = (track: TIdentifier, fields?: EFieldGroup[]): Promise<ITrack> =>
    this.api.get(`/tracks/${track}`, {}, fields);

  /** activate */
  activate = (track: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.post(`/tracks/${track}/activate`, {}, fields);

  /** getTrackHistory */
  getTrackHistory = (
    track: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<number[]> => this.api.get(`/tracks/${track}/views`, {}, fields);

  /** deleteTrack */
  deleteTrack = (track: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.delete(`/tracks/${track}`, {}, fields);

  /** draftTrack */
  draftTrack = (track: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.patch(`/tracks/${track}/draft`, {}, fields);

  /** reviewTrack */
  reviewTrack = (track: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.patch(`/tracks/${track}/review`, {}, fields);

  /** getTrackVideos */
  getTrackVideos = (
    track: TIdentifier,
    request: IGetTrackVideoRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IVideo>> =>
    this.api.get(`/tracks/${track}/video`, request, fields);

  /** buyTrack */
  buyTrack = (track: TIdentifier, fields?: EFieldGroup[]): Promise<ITrack> =>
    this.api.post(`/tracks/${track}/buy`, {}, fields);

  /** leaseTrack */
  leaseTrack = (track: TIdentifier, fields?: EFieldGroup[]): Promise<ITrack> =>
    this.api.post(`/tracks/${track}/lease`, {}, fields);
}

class Notifications {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** Получить список */
  getNotifications = (fields?: EFieldGroup[]): Promise<INotification[]> =>
    this.api.get(`/notifications`, {}, fields);

  /** Прочитать */
  readNotification = (
    notification: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<INotification> =>
    this.api.patch(`/notifications/${notification}`, {}, fields);

  /** Удалить */
  deleteNotification = (
    notification: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.delete(`/notifications/${notification}`, {}, fields);

  /** Прочитать все */
  readAllNotifications = (fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.patch(`/notifications`, {}, fields);

  /** Удалить все */
  deleteAllNotifications = (fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/notifications`, {}, fields);
}

class Authors {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** getAuthors */
  getAuthors = (fields?: EFieldGroup[]): Promise<IAuthor[]> =>
    this.api.get(`/authors`, {}, fields);

  /** addAuthor */
  addAuthor = (
    request: IAddAuthorRequest,
    fields?: EFieldGroup[]
  ): Promise<IAuthor> => this.api.post(`/authors`, request, fields);

  /** getAuthor */
  getAuthor = (author: TIdentifier, fields?: EFieldGroup[]): Promise<IAuthor> =>
    this.api.get(`/authors/${author}`, {}, fields);

  /** updateAuthor */
  updateAuthor = (
    author: TIdentifier,
    request: IUpdateAuthorRequest,
    fields?: EFieldGroup[]
  ): Promise<IAuthor> => this.api.patch(`/authors/${author}`, request, fields);
}

class Storage {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  /** getItem */
  getItem = <T>(
    request: IGetStorageItemRequest,
    fields?: EFieldGroup[]
  ): Promise<T | null> => this.api.get(`/storage`, request, fields);

  /** setItem */
  setItem = (
    request: ISetStorageItemRequest,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.put(`/storage`, request, fields);

  /** deleteItem */
  deleteItem = (
    request: IDeleteStorageItemRequest,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.delete(`/storage`, request, fields);
}
