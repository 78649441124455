import session from '../../../modules/session';
import Button from '../../../components/Button';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import { Link, useNavigate } from 'react-router-dom';
import ym from '../../../assets/images/payment-ym.png';
import { IBillingConfig } from '../../../modules/rest';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import {useTranslation} from "react-i18next";

const Ym = () => {
  const {t} = useTranslation();
  const [amount, setAmount] = useState(1000);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState<IBillingConfig>();
  const navigate = useNavigate();

  useEffect(() => {
    API.Billing.getConfig().then(setConfig).catch(toast.error);
  }, [setConfig]);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      const form = document.getElementById('charge-form') as HTMLFormElement;
      form.submit();
    },
    [setLoading]
  );

  return (
    <div>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/billing/">{t('BILLING')}</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/billing/charge/">{t('REFILL')}</Link>
        </li>
        <li className="breadcrumb-item active">{t('UMONEY')}</li>
      </ul>

      <div className="mb-3">
        <img src={ym} height={50} alt="YM" />
      </div>

      {config ? (
        <>
          <form onSubmit={submit} style={{ width: 300 }} className="my-3">
            <div className="mb-3">
              <label className="req">{t('SUM')}</label>
              <div className="input-group">
                <input
                  required
                  type="number"
                  className="form-control"
                  autoFocus
                  step={0.01}
                  min={100}
                  max={15000}
                  value={amount || ''}
                  disabled={loading}
                  onChange={(e) => setAmount(Number(e.target.value))}
                />
                <div className="input-group-text">RUB</div>
              </div>
              <div className="form-text">{t('UMONEY_COMMISSION')} {(amount * 1.011).toFixed(2)} ₽</div>
            </div>

            <div className="d-flex mt-4">
              <Button
                outline
                type="submit"
                text="GO_TO_PAYMENT"
                disabled={amount < 100 || amount > 15000 || loading}
              />
              <Button
                outline
                onClick={() => navigate('/billing/charge/', { replace: true })}
                text="CANCEL"
                color="secondary"
                disabled={loading}
                className="ms-2"
              />
            </div>
          </form>
          {loading && <Loader text="PAYMENT_REDIRECT" />}
          <form id="charge-form" method="POST" action="https://yoomoney.ru/quickpay/confirm.xml">
            <input type="hidden" name="receiver" value={config.ymAddress} />
            <input type="hidden" name="paymentType" value="PC" />
            <input type="hidden" name="label" value={session.user!.id} />
            <input type="hidden" name="quickpay-form" value="button" />
            <input type="hidden" name="sum" value={(amount * 1.011).toFixed(2)} />
            <input type="hidden" name="successURL" value={`${window.location.href.replace('/ym/', '/success/')}`} />
          </form>
        </>
      ) : (
        <Loader text="GET_SERVER_SETTINGS" />
      )}
    </div>
  );
};

export default Ym;
