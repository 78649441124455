import React, {FC} from 'react';
import {Icon} from "../../../components/fontawesome";
import Helper from "../../../components/Helper";
import {formatNumber} from "../../../modules/utils";
import {useTranslation} from "react-i18next";

interface Props {
  title: string;
  amount?: number | null;
  amountUsd?: number | null;
  icon: string;
  color: string;
  help: string;
  rub?: boolean;
}

const StatsHeadItem: FC<Props> = ({color, icon, title, help, amount, amountUsd, rub}) => {
  const {t} = useTranslation();
  if(amount === null) return null;
  return (
    <div className='stats__head__item'>
      <div className="stats__head__item__circle" style={{backgroundColor: color}}>
        <div className={`stats__head__item__icon${icon === 'dollars' ? ' dollars' : ''}`}>
          {icon === 'eye' || icon === 'eye_rub' ? <Icon icon={['fas', 'eye']} fixedWidth color='#fff' size='xl'/> : null}
          {icon === 'eye_rub' ? <div className='stats__head__item__icon__rub'>₽</div> : null}
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        <div className='text-muted'>{t(title)}</div>
        <Helper tag={help} />
      </div>
        {amountUsd && <span className="small text-muted" style={{marginBottom: -15}}>≈ $ {amountUsd.toFixed(2)}</span>}
        <div className='bold-24'>{formatNumber(amount || 0, 2)}{rub ? ' ₽' : ''}</div>
    </div>
  );
}

export default StatsHeadItem;
