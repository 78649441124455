import moment from 'moment';
import { useEffect, useState } from 'react';
import './Timer.scss';

const Timer = ({ date }: { date: string }) => {
  const [time, setTime] = useState<number>(0);

  let s = time >= 0 ? time : 0;
  let d = Math.floor(s / (3600 * 24));
  s -= d * (3600 * 24);
  let h = Math.floor(s / 3600);
  s -= h * 3600;
  let m = Math.floor(s / 60);
  s -= m * 60;

  useEffect(() => {
    const callback = () => setTime(Math.round(moment(date).diff(moment()) / 1000));
    const interval = setInterval(callback, 1000);
    callback();
    return () => {
      clearInterval(interval);
    };
  });
  return (
    <div className={`timer bold-14${time < 3600 * 24 ? ' timer-danger' : ''}`}>
      <span className={time < 3600 * 24 ? 'muted' : ''}>{d < 10 ? `0${d}` : d}</span>
      <span className={time < 3600 ? 'muted' : ''}>{h < 10 ? `0${h}` : h}</span>
      <span className={time < 60 ? 'muted' : ''}>{m < 10 ? `0${m}` : m}</span>
      <span className={time <= 0 ? 'muted' : ''}>{s < 10 ? `0${s}` : s}</span>
    </div>
  );
};

export default Timer;
