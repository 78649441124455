import { EUserStatus, IUser } from '../modules/rest';
import { UserRoles, UserStates } from '../modules/directory';
import {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  user: IUser;
  showRole?: boolean;
  showStatus?: boolean;
  hideStatus?: boolean;

  onClick?(): void;
}

const User: FC<Props> = ({ user, showRole, showStatus, hideStatus, onClick }) => {
  const {t} = useTranslation();
  return (
    <div
      onClick={onClick}
      className={`user d-flex align-items-center ${onClick ? 'cursor-pointer' : ''}`}
      style={{ overflow: 'hidden' }}
    >
      <div className={`me-2 user-id user-id-${user.role}`}>{user.id}</div>
      <div>
        <div>{user.email}</div>
        {showRole && <div className="x-small text-muted">{t(UserRoles[user.role])}</div>}
        {!hideStatus && (showStatus || user.status !== EUserStatus.Active) && (
          <div className={`x-small user-status-${user.status} lh-1`}>{t(UserStates[user.status])}</div>
        )}
      </div>
    </div>
  );
};

export default User;
