import React, {FC, useCallback, useEffect, useState} from 'react';
import {ECountry, IGetStatsRequest, ITrack} from '../../../modules/rest';
import {Icon} from '../../../components/fontawesome';
import SortItem from '../../../components/SortItem';
import SelectLanguages from "../../../components/SelectLanguages";
import SelectTracks from "../../../components/SelectTracks";
import {useTranslation} from "react-i18next";

interface Props extends IGetStatsRequest {
  onSortCountries: (value: ECountry, clear?: boolean) => void;
  onSortTracks: (value?: ITrack, clear?: boolean) => void;
  onSortDate: (date: { periodBegin?: string, periodEnd?: string }) => void;
  onClear: () => void;
  trackId?: number[];
  sortCount: number;
  tracksFilter: ITrack[];
  group: IGetStatsRequest['group']
}

const StatsFilters: FC<Props> = ({
                                   trackId,
                                   countryCode,
                                   periodBegin,
                                   periodEnd,
                                   onSortTracks,
                                   onSortCountries,
                                   onSortDate,
                                   onClear,
                                   tracksFilter,
                                   sortCount,
                                   group,
                                 }) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState('');
  const [date, setDate] = useState<{ periodBegin?: string, periodEnd?: string }>({});

  useEffect(() => {
    setDate({periodBegin: periodBegin || undefined, periodEnd: periodEnd || undefined});
  }, [periodBegin, periodEnd]);

  const toggleVisible = useCallback((item: string) => () => {
    setVisible(prevState => {
      if (prevState === item) return '';
      return item;
    });
  }, []);

  const handleChangeDate = useCallback((key: 'periodBegin'|'periodEnd') => (e: any) => {
    setDate(date => {
      return {...date, [key]: e.target.value || undefined};
    });
  }, []);

  return (
    <div className='right_sider__filters'>
      <div className='d-flex align-items-center pb-3'>
        <div className='circle-light-40'>
          <Icon icon={['fas', 'sliders']} fixedWidth color='#34353C'/>
        </div>
        <div className='px-2 bold'>{t('FILTER')}</div>
        {sortCount ? <SortItem text={sortCount} yellow className='text-14' onClick={onClear}/> : null}
      </div>
      <div className={`page__filters__item${visible === 'track' ? ' visible' : ''}`}>
        <div className='page__filters__item__head' onClick={toggleVisible('track')}>
          <div className='d-flex align-items-center'>
            <div className='bold me-2'>{t('TRACKS')}</div>
            {trackId?.length ?
              <SortItem text={trackId?.length} onClick={() => onSortTracks(undefined, true)}/> : null}
          </div>
          <div className='chevron'><Icon icon={['fas', 'chevron-down']} fixedWidth color='#AFB2BB'/></div>
        </div>
        <div className='page__filters__item__hidden'>
          <SelectTracks
            onChange={onSortTracks}
            selectedTracks={tracksFilter}
          />
        </div>
      </div>

        {group === 'country' ?

      <div className={`page__filters__item${visible === 'language' ? ' visible' : ''}`}>
        <div className='page__filters__item__head' onClick={toggleVisible('language')}>
          <div className='d-flex align-items-center'>
            <div className='bold me-2'>{t('COUNTRIES')}</div>
            {countryCode?.length ?
              <SortItem text={countryCode.length} onClick={() => onSortCountries(ECountry.AD, true)}/> : null}
          </div>
          <div className='chevron'><Icon icon={['fas', 'chevron-down']} fixedWidth color='#AFB2BB'/></div>
        </div>
        <div className='page__filters__item__hidden'>
          <SelectLanguages
            country
            onChange={ln => onSortCountries(ln as ECountry)}
            selectedLanguages={countryCode as ECountry[]}
          />
        </div>
      </div>

            : null }

      <div className={`page__filters__item${visible === 'date' ? ' visible' : ''}`}>
        <div className='page__filters__item__head' onClick={toggleVisible('date')}>
          <div className='d-flex align-items-center'>
            <div className='bold me-2'>{t('PERIOD')}</div>
            {periodEnd || periodBegin
              ?
              <SortItem
                className='ps-0 pe-2'
                text={''}
                onClick={() => onSortDate({periodEnd: undefined, periodBegin: undefined})}
              />
              : null
            }
          </div>
          <div className='chevron'><Icon icon={['fas', 'chevron-down']} fixedWidth color='#AFB2BB'/></div>
        </div>
        <div className='page__filters__item__hidden'>
          <div className='page__filters__price'>
            <div className='position-relative'>
              <div className='page__filters__input__label text-muted'>{t('FROM')}</div>
              <input
                type='month'
                className='form-control'
                value={date.periodBegin || ''}
                onChange={handleChangeDate('periodBegin')}
              />
            </div>
            <div className='position-relative' style={{borderLeft: '1px solid #E3E7ED'}}>
              <div className='page__filters__input__label text-muted'>{t('TILL')}</div>
              <input
                type='month'
                className='form-control'
                value={date.periodEnd || ''}
                onChange={handleChangeDate('periodEnd')}
              />
            </div>
          </div>
          <button
            className='btn btn-secondary btn-sm mt-2 w-100'
            onClick={() => onSortDate(date)}
            type={'button'}
          >
            {t('APPLY')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatsFilters;
