import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import BillingCharge from './choose';
import Ym from './ym';
import ChargeCard from './card';
import ChargeUsdt from './usdt';
import PaymentSuccess from './success';

const ChangeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BillingCharge />} />
      <Route path="/ym/" element={<Ym />} />
      <Route path="/card/" element={<ChargeCard />} />
      <Route path="/usdt/" element={<ChargeUsdt />} />
      <Route path="/success/" element={<PaymentSuccess />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default ChangeRoutes;
