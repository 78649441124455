import React, {FC} from 'react';
import AuthorStatusIcon from "../tracks/AuthorStatusIcon";
import {useNavigate, useParams} from "react-router-dom";
import {IAuthor} from "../../../modules/rest";
import {useTranslation} from "react-i18next";

interface Props {
  authors?: IAuthor[]
}

const ProfileAuthorsList: FC<Props> = ({authors}) => {
  const navigate = useNavigate();
  const params = useParams<{ author: string }>();
  const {t} = useTranslation();

  if (!authors?.length) return null;
  return (
    <>
      <div className='d-flex pb-1 text-muted'>
        <div
          onClick={() => navigate(`/author`)}
          className={`page__group__item${!params.author ? ' active' : ''}`}
        >
          {t('ALL_PERFORMERS')}
        </div>
        {authors.map((a) => (
          <div
            key={a.id}
            className={`page__group__item${a.id === Number(params?.author) ? ' active' : ''} ${a.status}`}
            onClick={() => navigate(`/author/${a.id}/tracks/`)}
          >
            {a.alias}
            <AuthorStatusIcon status={a.status} className='page__group__status__item-icon'/>
          </div>
        ))}
      </div>
      <div className='separator mb-4'/>
    </>
  );
}

export default ProfileAuthorsList;