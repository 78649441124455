import { EPayoutMethod, IPayoutDetails } from '../../modules/rest';
import asyncModal from 'react-async-modal';
import ModalActions from '../ModalActions';
import { FC, FormEvent, useCallback, useState } from 'react';
import Loadable from '../../components/Loadable';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import { monthRange, yearRange } from '../../modules/utils';
import {useTranslation} from "react-i18next";

type ReturnType = IPayoutDetails | false;

interface Props {
  resolve(details: ReturnType): void;
}

const CreatePayoutDetailsModal: FC<Props> = ({ resolve }) => {
  const {t} = useTranslation();
  const [method, setMethod] = useState<EPayoutMethod>(EPayoutMethod.RuCard);
  const [data, setData] = useState<Record<string, number | string | null>>({});
  const [loading, setLoading] = useState(false);

  const setItem = useCallback(
    (key: string, value: string | number | null) => {
      setData({ ...data, [key]: value });
    },
    [data, setData]
  );

  const submit = useCallback(
    (e: FormEvent) => {
      setLoading(true);
      e.preventDefault();
      API.Billing.createPayoutDetails({ method, data })
        .then((details) => {
          resolve(details);
          toast.success(t('PAYMENT_DETAILS_ADDED'));
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [method, data, setLoading, resolve, t]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <h4 className="mb-4">{t('ADD_PAYMENT_DETAILS')}</h4>

        <div className="mb-3">
          <label className="req">{t('PAYMENT_METHOD')}</label>
          <select
            required
            className="form-select"
            value={method}
            onChange={(e) => setMethod(e.target.value as EPayoutMethod)}
          >
            <option value={EPayoutMethod.RuCard}>{t('RF_CARD')}</option>
            <option value={EPayoutMethod.OtherCard}>{t('NO_RF_CARD')}</option>
            <option value={EPayoutMethod.USDT}>USDT (TRC-20)</option>
          </select>
        </div>

        {method === EPayoutMethod.USDT && (
          <div>
            <div className="mb-3">
              <label className="req">{t('WALLET')}</label>
              <input
                type="text"
                className="form-control"
                required
                placeholder="TT3emG6tnHQSBpQKJxbTvr8uj3jsc8xhng"
                minLength={34}
                maxLength={34}
                value={data.address || ''}
                onChange={(e) => setItem('address', e.target.value)}
              />
            </div>
          </div>
        )}

        {method === EPayoutMethod.RuCard && (
          <div>
            <div className="mb-3">
              <label className="req">{t('CARD_NUMBER')}</label>
              <input
                type="text"
                className="form-control"
                required
                placeholder="0000 1111 2222 3333"
                minLength={16}
                maxLength={20}
                value={data.number || ''}
                onChange={(e) => setItem('number', e.target.value.replace(/[^[0-9]/g, ''))}
              />
            </div>
            <div className="mb-3">
              <label className="req">{t('CARDHOLDER_NAME')}</label>
              <input
                type="text"
                className="form-control"
                required
                placeholder={t('CARDHOLDER_PLACEHOLDER') || ''}
                value={data.name || ''}
                onChange={(e) => setItem('name', e.target.value)}
              />
            </div>
          </div>
        )}

        {method === EPayoutMethod.OtherCard && (
          <div>
            <div className="row">
              <div className="col-6">
                <div className="mb-3">
                  <label className="req">{t('CARD_NUMBER')}</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    placeholder="5000 1111 2222 3333"
                    minLength={16}
                    maxLength={16}
                    value={data.number || ''}
                    onChange={(e) => setItem('number', e.target.value.replace(/[^[0-9]/g, ''))}
                  />
                </div>
              </div>

              <div className="col">
                <div className="mb-3">
                  <div className="row">
                    <label className="req">{t('VALIDITY')}</label>
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={data.exp_month || ''}
                        onChange={(e) => setItem('exp_month', e.target.value)}
                      >
                        <option value="">{t('MONTH')}</option>
                        {monthRange().map((m) => (
                          <option value={m} key={m}>
                            {m}
                          </option>
                        ))}
                      </select>
                      <span className="input-group-text">/</span>
                      <select
                        className="form-select"
                        value={data.exp_year || ''}
                        onChange={(e) => setItem('exp_year', e.target.value)}
                      >
                        <option value="">{t('YEAR')}</option>
                        {yearRange().map((m) => (
                          <option value={m} key={m}>
                            {m}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label className="req">{t('SURNAME')}</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={data.last_name || ''}
                  onChange={(e) => setItem('last_name', e.target.value || null)}
                  placeholder={t('SURNAME_PLACEHOLDER') || ''}
                />
              </div>
              <div className="col">
                <label className="req">{t('FIRST_NAME')}</label>
                <input
                  placeholder={t('FIRST_NAME_PLACEHOLDER') || ''}
                  type="text"
                  className="form-control"
                  required
                  value={data.first_name || ''}
                  onChange={(e) => setItem('first_name', e.target.value || null)}
                />
              </div>
              <div className="col">
                <label>{t('PATRONYMIC')}</label>
                <input
                  placeholder={t('PATRONYMIC_PLACEHOLDER') || ''}
                  type="text"
                  className="form-control"
                  value={data.middle_name || ''}
                  onChange={(e) => setItem('middle_name', e.target.value || null)}
                />
              </div>
            </div>

            <div className="mb-3 w-50">
              <label className="req">{t('BIRTHDAY')}</label>
              <input
                type="date"
                className="form-control"
                required
                value={data.birth_date || ''}
                onChange={(e) => setItem('birth_date', e.target.value)}
              />
            </div>

            <div className="row mb-3">
              <div className="col-4">
                <label className="req">{t('RESIDENCE_CITY')}</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={data.city || ''}
                  onChange={(e) => setItem('city', e.target.value)}
                />
              </div>

              <div className="col">
                <label className="req">{t('RESIDENCE_ADDRESS')}</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={data.address || ''}
                  onChange={(e) => setItem('address', e.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        <ModalActions onCancelClick={() => resolve(false)} />
      </form>
    </Loadable>
  );
};

const openCreatePayoutDetailsModal = (): Promise<ReturnType> => {
  return asyncModal(CreatePayoutDetailsModal, {}, { styles: { modal: { width: 600 } } });
};

export { openCreatePayoutDetailsModal };
