import Loader from '../../components/Loader';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API } from '../../modules/api';
import session from '../../modules/session';
import {useTranslation} from "react-i18next";

const LinkTelegram = () => {
  const {t} = useTranslation();
  const params = useParams<{ token: string }>();
  const navigate = useNavigate();

  const link = useCallback(() => {
    API.Users.linkTelegram(params.token!)
      .then(() => {
        toast.success(t('BOT_SUCCESS_SET'));
        return session.fetch();
      })
      .catch(toast.error)
      .finally(() => navigate('/'));
  }, [params, navigate, t]);

  useEffect(() => {
    link();
  }, [link]);

  return (
    <div>
      <Loader text="SETTING_NOTIF_BOT" />
    </div>
  );
};

export default LinkTelegram;
