import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import Button from '../../../components/Button';
import { Icon } from '../../../components/fontawesome';
import session from '../../../modules/session';
import {useTranslation} from "react-i18next";

const PaymentSuccess = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    session.fetch().then();
  }, []);
  return (
    <div>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/billing/">{t('BILLING')}</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/billing/charge/">{t('REFILL')}</Link>
        </li>
        <li className="breadcrumb-item active">{t('PAYMENT_RESULT')}</li>
      </ul>
      <h3 className="mb-3">{t('HOORAY')}</h3>
      <div className="mb-4 alert alert-success">
        <Icon icon={['fas', 'check']} fixedWidth className="me-1" />
        {t('SUCCESS_PAYMENT')}
      </div>
      <Button onClick={() => navigate('/billing', { replace: true })} text="PAYMENTS_HISTORY" outline />
    </div>
  );
};

export default PaymentSuccess;
