import {FC, useCallback, useEffect, useState} from 'react';
import {Icon} from '../../../components/fontawesome';
import {Link} from 'react-router-dom';
import './tracks-recommended.scss';
import {ESortOrder, ITrack} from '../../../modules/rest';
import {API} from '../../../modules/api';
import TrackBlock from '../../../components/TrackBlock';
import cache from '../../../modules/cache';
import {useTranslation} from "react-i18next";

interface Props {
  opened: boolean;
  onChange: () => void;
  toggleOpened: () => void;
  changePlayedTrackId: (id?: number) => void;
  activeTrackId?: number;
}

const TracksRecommendedSlider: FC<Props> = ({opened, onChange, toggleOpened, changePlayedTrackId, activeTrackId}) => {
  const {t} = useTranslation();
  const [tracks, setTracks] = useState<ITrack[]>(cache.get('tracks.recommended') ?? []);
  const [freeIds, setFreeIds] = useState<number[]>([]);


  const fetch = useCallback(() => {
    return Promise.all([
      API.Tracks.getTracksList({sort: 'price', order: ESortOrder.ASC, limit: 10}).then((res) => {
        setTracks(res.data);
        cache.set('tracks.recommended', res.data);
      }),
      API.Tracks.getFreeTracksIds().then((free) => {
        setFreeIds(free);
        cache.set('tracks.free', free);
      }),
    ]);
  }, [setTracks]);


  useEffect(() => {
    fetch().then(() => {
      if (window.localStorage.getItem('hide-recommended') !== '1') {
        toggleOpened();
      }
    });
  }, []);

  if (!tracks || tracks.length === 0) return null;

  return (
    <div className={`recommended ${opened ? 'opened' : 'closed'}`}>
      <div className="recommended__widget">
        <button onClick={toggleOpened}>
          <Icon icon={['fas', 'chevron-left']}/>
        </button>
        <div className="caption">
          <span>{t('RECOMMENDED_TRACKS')}</span>
          <Link to="/owner/tracks/" className='link-secondary'>{t('GO_TO_CATALOG')}</Link>
        </div>
      </div>
      <div className="recommended__tracks">
        <div className="recommended__tracks__content">
          {tracks.map(tr => (
            <TrackBlock
              activeTrackId={activeTrackId}
              changePlayedTrackId={changePlayedTrackId}
              key={tr.id}
              track={tr}
              free={freeIds?.includes(tr.id)}
              onChange={() => {
                fetch().then();
                onChange();
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TracksRecommendedSlider;
