import {observer, useLocalObservable} from 'mobx-react';
import {EContractStatus, EReviewStatus, IAuthor, IGetTracksRequest, IPagedData, ITrack} from '../../../modules/rest';
import Loadable from '../../../components/Loadable';
import React, {FC, useCallback, useEffect} from 'react';
import {runInAction} from 'mobx';
import {API} from '../../../modules/api';
import {toast} from 'react-toastify';
import Empty from '../../../components/Empty';
import cache from '../../../modules/cache';
import Track from '../../../components/Track';
import Button from '../../../components/Button';
import {useNavigate} from 'react-router-dom';
import {ReviewStates} from '../../../modules/directory';
import {confirmDialog} from '../../../modules/confirm';
import {openCreateBatchModal} from '../../../modals/author/CreateBatchModal';
import {signContractModal, SignSide} from '../../../modals/common/ContractModal';
import Pagination from '../../../components/Pagination';
import {Icon} from '../../../components/fontawesome';
import {download} from '../../../modules/utils';
import Dash from '../../../components/Dash';
import {useTranslation} from "react-i18next";
import ProfileAuthorsList from "../profiles/ProfileAuthorsList";
import Author from "./Author";
import AuthorStatusIcon from "./AuthorStatusIcon";

interface State {
  pager?: IPagedData<ITrack>;
  request: IGetTracksRequest;
  loading: boolean;
}

interface Props {
  author: IAuthor;
  authors?: IAuthor[];
}

const ManageTracks: FC<Props> = observer(({author, authors}) => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    pager: cache.get(`tracks:${author.id}`),
    loading: true,
    request: {
      authorId: author.id,
      page: 1,
      limit: 30,
    },
    author: null,
  }));

  const fetch = useCallback(() => {
    runInAction(() => (st.loading = true));
    API.Tracks.getTracksList(st.request)
      .then((pager) => {
        runInAction(() => {
          st.pager = pager;
          st.request.page = pager.page;
          st.request.limit = pager.limit;
          cache.set(`tracks:${author.id}`, pager);
        });
      })
      .catch(toast.error)
      .finally(() => runInAction(() => (st.loading = false)));
  }, [st, author.id]);

  const navigate = useNavigate();

  const editTrack = useCallback(
    (track: ITrack) => {
      navigate(`${track.id}/edit/`);
    },
    [navigate]
  );

  const deleteTack = useCallback(
    (track: ITrack) => {
      confirmDialog(t('REMOVE_TRACK_CONFIRM', {title: track.title}), {danger: true}).then((agree) => {
        if (!agree) return;
        runInAction(() => (st.loading = true));
        API.Tracks.deleteTrack(track.id)
          .then(() => {
            toast.success(t('TRACK_DELETED'));
            fetch();
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (st.loading = false)));
      });
    },
    [st, fetch, t]
  );

  const reviewTrack = useCallback(
    (track: ITrack) => {
      confirmDialog(t('SEND_TRACK_REVIEW', {title: track.title}), {confirmText: t('SUBMIT_FOR_REVIEW') || ''}).then(
        (agree) => {
          if (!agree) return;
          runInAction(() => (st.loading = true));
          API.Tracks.reviewTrack(track.id)
            .then(() => {
              toast.success(t('TRACK_SENT_REVIEW'));
              fetch();
            })
            .catch(toast.error)
            .finally(() => runInAction(() => (st.loading = false)));
        }
      );
    },
    [st, fetch, t]
  );

  const draftTack = useCallback(
    (track: ITrack) => {
      confirmDialog(
        t('DRAFT_TRACK_CONFIRM', {title: track.title}),
        {confirmText: t('CONVERT_TO_DRAFT') || ''}
      ).then((agree) => {
        if (!agree) return;
        runInAction(() => (st.loading = true));
        API.Tracks.draftTrack(track.id)
          .then(() => {
            toast.success(t('TRACK_TO_DRAFT'));
            fetch();
            editTrack(track);
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (st.loading = false)));
      });
    },
    [st, fetch, editTrack, t]
  );

  useEffect(() => {
    runInAction(() => {
      st.request.authorId = author.id;
      st.request.page = 1;
    });
    fetch();
  }, [fetch, author?.id]);

  return (
    <Loadable loading={st.loading} className='pb-3'>
      <ProfileAuthorsList authors={authors}/>
      <Author author={author}/>
      <div className="mb-3 d-flex mt-4 pt-2 justify-content-between align-items-center">
        <h4 className="bold-24">{t('TRACKS')} <span className='text-muted'>{author.alias}</span></h4>
        <Button
          text="ADD_TRACK"
          color={"secondary"}
          icon={['fas', 'plus']}
          onClick={() => navigate('add/')}
          className='pe-4'
        />
        {/*<Button*/}
        {/*  text="TREATY"*/}
        {/*  icon={['fas', 'plus']}*/}
        {/*  outline*/}
        {/*  onClick={() => openCreateBatchModal(author).then(fetch)}*/}
        {/*  className="ms-2"*/}
        {/*/>*/}
      </div>
      <div className='d-flex align-items-center text-muted-14 pb-2'>
        <div style={{width: 50}}>#</div>
        <div className='d-flex w-100'>
          <div className='w-30'>{t('TRACK_NAME')}</div>
          <div className='flex-grow-1'>{t('TREATY')}</div>
          <div className='text-right w-20'>{t('STATUS')}</div>
        </div>
      </div>
      <div className="separator"></div>
      {st.pager && (
        <div className="tracks mt-3">
          {st.pager.data.map((track) => (
            <div key={track.id} className='d-flex align-items-center mb-3'>
              <div style={{width: 50}} className='text-muted-14'>#{track.id}</div>
              <div className="card dark w-100">
                <div className="card-body d-flex align-items-center">
                  <div className='w-30'>
                    <Track track={track}/>
                  </div>

                  <div className="d-flex flex-column flex-grow-1 align-items-start">
                    {track.batch ? (
                      <>
                        <span className="bold">№{track.batch?.aiContract?.tag}</span>
                        {track.batch?.aiContract?.status === EContractStatus.Blank && (
                          <Button
                            text="SIGN"
                            size="xs"
                            color="warning"
                            icon={['fas', 'check']}
                            onClick={() => signContractModal(track.batch!.aiContract!, SignSide.One).then(fetch)}
                          />
                        )}
                        {track.batch?.aiContract?.status === EContractStatus.SignedTwo && (
                          <div onClick={() => download(track.batch!.aiContract!.signedTwo!)} className='d-flex bold-12 text-muted align-items-center cursor-pointer'>
                            <span className='pe-1'>{t('DOWNLOAD')}</span>
                            <Icon icon={['fas', 'download']}/>
                          </div>
                        )}
                      </>
                    ) : track.status === EReviewStatus.Approved && author.status === EReviewStatus.Approved ? (
                      <Button
                        text="CREATE"
                        size="xs"
                        color="success"
                        icon={['fas', 'plus']}
                        onClick={() => openCreateBatchModal(author).then(fetch)}
                      />
                    ) : track.status === EReviewStatus.Approved && author.status !== EReviewStatus.Approved ? (
                      <span className="text-danger small">
                        <Icon icon={['fas', 'exclamation-circle']}/> {t('CHECK_PROFILE')}
                      </span>
                    ) : (
                      <Dash/>
                    )}

                  </div>
                  <div className="w-20 text-right align-self-center">
                    <div className='d-flex align-items-center justify-content-end mt-2'>
                      <span className={`small me-2`}>{t(ReviewStates[track.status!])}</span>
                      <AuthorStatusIcon status={track.status!} className='card__item__status-icon'/>
                    </div>
                    {track.rejectReason && <span className="text-danger small">{track.rejectReason}</span>}
                    <div className="d-flex justify-content-end mt-2">
                      <div className="btn-group btn-group-sm">
                        {track.status === EReviewStatus.Draft && (
                          <Button title="CHANGE" outline icon={['fas', 'edit']} onClick={() => editTrack(track)}/>
                        )}

                        {[EReviewStatus.InReview, EReviewStatus.Rejected].includes(track.status!) && (
                          <Button title="CHANGE" outline icon={['fas', 'edit']} onClick={() => draftTack(track)}/>
                        )}

                        {track.status === EReviewStatus.Draft && (
                          <Button
                            title="SUBMIT_FOR_REVIEW"
                            color="success"
                            outline
                            icon={['fas', 'check']}
                            onClick={() => reviewTrack(track)}
                          />
                        )}
                        {[EReviewStatus.Draft, EReviewStatus.Rejected].includes(track.status!) && (
                          <Button
                            title="REMOVE"
                            color="danger"
                            outline
                            icon={['fas', 'times']}
                            onClick={() => deleteTack(track)}
                          />
                        )}
                      </div>
                    </div>
                  </div>


                  {/*<span className="w-15">{track.author?.firstName}</span>*/}
                  {/*<span className="w-10">{track.sellPrice} ₽</span>*/}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <Empty show={!st.loading && !st.pager?.count} text="NO_TRACK"/>
      <Pagination
        pager={st.pager}
        onPageChange={(page) =>
          runInAction(() => {
            st.request.page = page;
            fetch();
          })
        }
        onLimitChange={(limit) => {
          runInAction(() => {
            st.request.page = 1;
            st.request.limit = limit;
            fetch();
          });
        }}
      />
    </Loadable>
  );
});

export default ManageTracks;
