import ReactDOM from 'react-dom/client';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import './SideModal.scss';

interface Props<T> {
  builder: (resolve: (result: T) => void) => ReactNode;
  close(result: T | null): void;
}

const SideModal = <T,>({ builder, close }: Props<T>) => {
  const [mounted, setMounted] = useState<number>(0);

  const startClose = useCallback(
    (result: T | null) => {
      setMounted(0);
      setTimeout(() => close(result), 200);
    },
    [setMounted, close]
  );

  useEffect(() => {
    setMounted(1);
  }, [setMounted]);
  return (
    <div className={`side-modal mounted-${mounted}`} onClick={() => startClose(null)}>
      <div className={`side-modal__body mounted-${mounted}`} onClick={(e) => e.stopPropagation()}>
        {builder(startClose)}
      </div>
    </div>
  );
};

function openSideModal<T>(builder: (resolve: (result: T) => void) => ReactNode): Promise<T | null> {
  return new Promise((resolve) => {
    const el = document.createElement('div');
    if(!el) return;
    document.body.appendChild(el);
    const root = ReactDOM.createRoot(el);
    const close = (result: T | null) => {
      resolve(result as T);
      document.body.removeChild(el);
    };
    root.render(<SideModal close={() => close(null)} builder={builder} />);
  });
}

export { openSideModal };
