import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { IAuthor, ITrack } from '../../../modules/rest';
import TrackCompose from './compose';
import { FC, useEffect, useState } from 'react';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import {useTranslation} from "react-i18next";

interface Props {
  author: IAuthor;
}

const EditTrack: FC<Props> = ({ author }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ track: string }>();

  const [track, setTrack] = useState<ITrack>();

  useEffect(() => {
    API.Tracks.getTrack(params.track!)
      .then((track) => {
        if (track.status === 'draft') {
          setTrack(track);
        } else {
          throw new Error(t('EDIT_ONLY_DRAFT') || '');
        }
      })
      .catch((e) => {
        toast.error(e);
        navigate('../');
      });
  }, [params.track, navigate, t]);

  if (!track) return <Loader />;

  return (
    <div>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <NavLink to="/author/">{t('PERFORMERS')}</NavLink>
        </li>
        <li className="breadcrumb-item">
          <NavLink to={`/author/${author.id}/tracks/`}>{author.alias}</NavLink>
        </li>
        <li className="breadcrumb-item active">{t('EDIT_TRACK')}</li>
      </ul>
      <div className="mb-3">
        <TrackCompose
          mode="edit"
          track={track}
          author={author}
          onUpdated={() => navigate(`/author/${author.id}/tracks/`)}
        />
      </div>
    </div>
  );
};

export default EditTrack;
