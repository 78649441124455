import React, {FC, useCallback, useEffect, useState} from 'react';
import BarChart from "../BarChart";
import {ITrack} from "../../modules/rest";
import {API} from "../../modules/api";

interface Props {
  track: ITrack;
}

const TrackHistoryBar: FC<Props> = ({track}) => {
  const [history, setHistory] = useState<number[]>();
  useEffect(() => {
    fetch();
  }, []);

  const fetch = useCallback(async () => {
    try {
      const res = await API.Tracks.getTrackHistory(track.id);
      setHistory(res);
    } catch (e) {
      setHistory([]);
    }
  }, [track.id]);

  if (!history?.length) return null;
  return <BarChart data={history}/>;

}

export default TrackHistoryBar;