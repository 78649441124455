import React, {FC, useState} from 'react';
import './one_min_track.scss'
import {useTranslation} from "react-i18next";
import {ArrowContainer, Popover} from 'react-tiny-popover';

interface Props {
  visible: boolean;
  small?: boolean;
  className?: string;
}

const OneMinTrack: FC<Props> = ({visible, small, className}) => {
  const {t} = useTranslation();
  const [opened, setOpened] = useState(false);
  if (!visible) return null;
  return (
    <div onClick={e => e.stopPropagation()} className={className || ''}>
      <Popover
        isOpen={opened}
        positions={['bottom', 'top', 'left', 'right']}
        onClickOutside={() => setOpened(false)}
        content={({position, childRect, popoverRect}) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'white'}
            arrowSize={10}
          >
            <div className='min-music-popover'>
              <div className='min-music-popover-icon bold-14'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                    stroke="#34353C" strokeWidth="1.5" strokeMiterlimit="10"/>
                  <path d="M8.25 15H15.75" stroke="#34353C" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round"/>
                  <path
                    d="M8.625 11.25C9.24632 11.25 9.75 10.7463 9.75 10.125C9.75 9.50368 9.24632 9 8.625 9C8.00368 9 7.5 9.50368 7.5 10.125C7.5 10.7463 8.00368 11.25 8.625 11.25Z"
                    fill="#34353C"/>
                  <path
                    d="M15.375 11.25C15.9963 11.25 16.5 10.7463 16.5 10.125C16.5 9.50368 15.9963 9 15.375 9C14.7537 9 14.25 9.50368 14.25 10.125C14.25 10.7463 14.7537 11.25 15.375 11.25Z"
                    fill="#34353C"/>
                  <rect x="8.25" y="13.5" width="1.5" height="3" rx="0.75" fill="#34353C"/>
                  <rect x="14.25" y="13.5" width="1.5" height="3" rx="0.75" fill="#34353C"/>
                  <rect x="11.25" y="13.5" width="1.5" height="3" rx="0.75" fill="#34353C"/>
                </svg>
                <span className='ps-2'>{t('ONE_MIN')}</span>
              </div>
              <p className='text-muted-14 mb-0' dangerouslySetInnerHTML={{__html: t('ONE_MIN_TRACK_INFO')}}/>
            </div>
          </ArrowContainer>
        )}
      >
        <div
          className={`min-music${small ? ' small' : ''}`}
          title={t('ONE_MIN') || ''}
          onClick={(e) => {
            e.stopPropagation();
            setOpened(true)
          }}
        />

      </Popover>
    </div>
  );
}

export default OneMinTrack;