import React, {useCallback, useEffect, useState} from 'react';
import {API} from '../../../modules/api';
import {EVideoType, IGetMyTracksRequest, ITrack} from '../../../modules/rest';
import {toast} from 'react-toastify';
import cache from '../../../modules/cache';
import Loadable from '../../../components/Loadable';

import './tracks.scss';
import {useNavigate} from 'react-router-dom';
import {Icon} from '../../../components/fontawesome';
import TracksRecommendedSlider from './tracks-recommended';
import Loader from '../../../components/Loader';
import {useTranslation} from "react-i18next";
import ExtensionPanel from "../../../components/ExtencionPanel";
import MyTracksListItem from "./MyTracksListItem";
import Helper from "../../../components/Helper";
import SearchInput from "../../../components/SearchInput";

const MyTracks = () => {
  const {t} = useTranslation();
  const [siderOpened, setSiderOpened] = useState(cache.get('tracks.free') ?? false);
  const [tracks, setTracks] = useState<ITrack[]>(cache.get(`owner.my2.tracks`) ?? []);
  const [loading, setLoading] = useState<boolean>(true);
  const [request, setRequest] = useState<IGetMyTracksRequest>({});
  const [activeTrackId, setActiveTrack] = useState<number>();
  const [limits, setLimits] = useState<{
    tracks: Record<EVideoType, number>;
    free: Record<EVideoType, number>;
  }>();

  const fetchTracks = useCallback((req?: IGetMyTracksRequest) => {
    API.Tracks.getMyTracks(req || request)
      .then((tracks) => {
        setTracks(tracks);
        cache.set(`owner.my2.tracks`, tracks);
        setLoading(false);
      })
      .catch(toast.error);
  }, [request]);

  const navigate = useNavigate();

  useEffect(() => {
    API.Tracks.getLimits()
      .then((limits) => {
        setLimits(limits);
      })
      .catch(toast.error);
  }, [])

  useEffect(() => {
    fetchTracks();
    const content = document.getElementById('content') as HTMLElement;
    content.classList.remove('container-fluid');
    return () => {
      content.classList.add('container-fluid');
    };
  }, []);

  const handleChangeRequest = (data: IGetMyTracksRequest, reset?: boolean) => {
    setRequest(prevState => {
      let newState = {...prevState, ...data, ...(reset ? {page: 1} : {})}
      fetchTracks(newState)
      return newState;
    })
  }

  const toggleSider = useCallback(() => {
    setSiderOpened(prevState => {
      window.localStorage.setItem('hide-recommended', !prevState ? '0' : '1');
      return !prevState;
    });
  }, []);

  return (
    <div className={`my__tracks${siderOpened ? ' sider-opened' : ''}`}>
      <ExtensionPanel type='my_tracks' className='pb-2'/>
      <div className='page__title__line my__tracks__header'>
        <h1 className="page-title">
          {t('MY_TRACKS')} {tracks.length > 0 && <span className="second ms-2">{tracks.length}</span>}
        </h1>
        <div className="d-flex align-items-center">
          {/*<div className='form-control w-auto p-0'>*/}
          {/*  <select*/}
          {/*    className="form-select"*/}
          {/*    value={request.status || ''}*/}
          {/*    onChange={(e) => handleChangeRequest({status: e.target.value}, true)}*/}
          {/*  >*/}
          {/*    <option  value={''}>*/}
          {/*      {t('NOT_SELECT')}*/}
          {/*    </option>*/}
          {/*    {Object.entries(EReviewStatus).map(([key, status]) => (*/}
          {/*      <option key={key} value={status}>*/}
          {/*        {t(ReviewStates[status])}*/}
          {/*      </option>*/}
          {/*    ))}*/}
          {/*  </select>*/}
          {/*</div>*/}
          <SearchInput
            className='mx-3'
            onStartTyping={() => setLoading(true)}
            value={request.query}
            onSearch={(value) => handleChangeRequest({query: value}, true)}
          />
          <button onClick={() => navigate(`/owner/tracks/`)} className="btn btn-primary me-2 pe-3">
            <Icon icon={['fas', 'plus']} fixedWidth className="me-1"/>
            {t('ADD_TRACK')}
          </button>
        </div>
      </div>
      <div className='my__tracks__wrap'>
        <Loadable loading={loading} className="left__content__with__filter my__tracks__list">
          {tracks.length > 0 ? (
            <>
              <div className='d-flex align-items-center'>
                <div className='text-muted-14' style={{minWidth: 70, width: 70}}>#</div>
                <div className='d-flex ps-3 align-items-center'>
                  <div className='text-muted-14' style={{minWidth: 300, width: 300}}>{t('NAME')}/{t('STATUS')}</div>
                  <div className='text-muted-14 d-flex align-items-center' style={{minWidth: 280, width: 280}}>
                    {t('MONETIZE')} <Helper tag="tracks.limits" className='ms-2'/>
                  </div>
                  <div className='text-muted-14' style={{minWidth: 200, width: 200}}>{t('VIDEO')}</div>
                  <div className='text-muted-14 flex-grow-1'>{t('VIEWS_PER_MONTH')}</div>
                </div>
              </div>
              <div className='separator mt-2'/>
              {tracks.map((track) => (
                <MyTracksListItem
                  activeTrackId={activeTrackId}
                  changePlayedTrackId={setActiveTrack}
                  key={track.id}
                  onChange={fetchTracks}
                  track={track}
                />
              ))}
            </>
          ) : loading ? (
            <div className="ms-5">
              <Loader text="LOADING_TRACKS_LIST"/>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center" style={{marginTop: 100}}>
              <div className="bold">{t('NO_TRACKS')}</div>
              <div className="text-center text-muted small mt-1 mb-2"
                   dangerouslySetInnerHTML={{__html: t('TRACKS_OPPORTUNITY')}}/>
              <button onClick={() => navigate(`/owner/tracks/`)} className="btn btn-primary me-2 pe-3">
                <Icon icon={['fas', 'plus']} fixedWidth className="me-1"/>
                {t('ADD_TRACK')}
              </button>
            </div>
          )}
        </Loadable>
        <TracksRecommendedSlider
          onChange={fetchTracks}
          opened={siderOpened}
          toggleOpened={toggleSider}
          activeTrackId={activeTrackId}
          changePlayedTrackId={setActiveTrack}
        />
      </div>
    </div>
  );
};

export default MyTracks;
