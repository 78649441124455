import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { API } from '../modules/api';
import store from '../modules/session';
import session from '../modules/session';
import { runInAction } from 'mobx';
import Loadable from '../components/Loadable';
import { toast } from 'react-toastify';
import logo from '../assets/images/logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { SwitchAccountItems } from '../components/AccountSwitch';
import { observer } from 'mobx-react';
import {useTranslation} from "react-i18next";
import Checkbox from "../components/Checkbox";

const LoginRoute = observer(() => {
  const {t} = useTranslation();
  const [email, setEmail] = useState(localStorage.getItem('email') ?? '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState<boolean>(false);
  const [agree, setAgree] = useState<boolean>(false);
  const navigate = useNavigate();

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Users.login({ email, password, remember })
        .then((res) => {
          window.localStorage.setItem('token', res.token);
          window.localStorage.setItem('email', remember ? email : '');
          API.setToken(res.token);
          runInAction(() => (store.user = res.user));
          session.removeAccountByUser(res.user);
          session.addAccount(res.token, res.user);
          const returnPath = window.localStorage.getItem('return') ?? '/';
          window.localStorage.removeItem('return');
          navigate(returnPath, { replace: true });
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [email, password, remember, setLoading, navigate]
  );

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-light');
    return () => {
      body.classList.remove('bg-light');
    };
  });

  return (
    <Loadable loading={loading}>
      <div className="d-flex justify-content-center" style={{ marginTop: 100 }}>
        <form onSubmit={submit} className="card bg-white border-0 shadow" style={{ width: 450 }}>
          <div className="card-body p-4">
            <div className="d-flex justify-content-center mt-3 mb-5">
              <img src={logo} height={65} alt="" />
            </div>
            <div className="mb-3">
              <label className="req">E-mail</label>
              <input
                autoFocus
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <label className="req">{t('PASSWORD')}</label>
                <Link to="/password" className="text-decoration-none small link-secondary">
                  {t('PASSWORD_FORGOT')}
                </Link>
              </div>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Checkbox
              className='mb-3'
              checked={remember}
              onChange={setRemember}
              label={'REMEMBER_ME'}
            />
            <div className="text-muted mb-3 d-flex align-items-start">
              <Checkbox
                checked={agree}
                onChange={setAgree}
                id='agree'
              />
              <label className="form-check-label cursor-pointer" htmlFor="agree">
                {t('TERMS_TEXT_1')}{' '}
                <a
                  href="https://docs.google.com/document/d/1p0H79kYZNFwDA3bPT5K-9IS8MsVi__QfRkuMqs676Cg/edit"
                  target="_blank"
                  rel="noreferrer"
                  className="text-muted"
                >
                  {t('TERMS_TEXT_2')}
                </a>{' '}
                {t('TERMS_TEXT_3')}{' '}
                <a
                  href="https://docs.google.com/document/d/1OcaEyy8iude04mD87ns6wGEpUZU8WZXSlNjW8b1HzJs/edit"
                  target="_blank"
                  rel="noreferrer"
                  className="text-muted"
                >
                  {t('TERMS_TEXT_4')}
                </a>
              </label>
            </div>

            <div className="d-flex">
              <button disabled={!agree} type="submit" className="btn btn-primary w-100">
                {t('LOGIN')}
              </button>
            </div>

            {session.accounts.length > 0 && (
              <div className="mt-3 border-top" style={{ margin: '-1.5rem' }}>
                <div className="mt-2 px-2 text-muted small">{t('OPEN_SESSIONS')}</div>
                <div>
                  <SwitchAccountItems />
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </Loadable>
  );
});

export default LoginRoute;
