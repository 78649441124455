import React from 'react';
import { IPayoutRequest } from '../modules/rest';
import { confirmDialog } from '../modules/confirm';
import { API } from '../modules/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import i18n from 'i18next';

const cancelPayment = (request: IPayoutRequest): Promise<boolean> => {
  return confirmDialog(i18n.t('CANCEL_APP_CONFIRM'), {
    title: i18n.t('APPLICATION_FROM',{id: request.id, date: moment(request.createdAt).format('DD.MM.YYYY')}) || '',
    confirmText: i18n.t('CANCEL_APPLICATION') || '',
    danger: true,
  }).then((agree) => {
    if (!agree) return Promise.resolve(false);
    return API.Payouts.cancelPayoutRequests(request.id).catch((e) => {
      toast.error(e);
      return false;
    });
  });
};

const successPayment = (request: IPayoutRequest): Promise<boolean> => {
    return confirmDialog(i18n.t('MARK_APPLICATION_SUCCESS'), {
    title: i18n.t('APPLICATION_FROM',{id: request.id, date: moment(request.createdAt).format('DD.MM.YYYY')}) || '',
    confirmText: i18n.t('COMPLETED_APPLICATION') || '',
  }).then((agree) => {
    if (!agree) return Promise.resolve(false);
    return API.Payouts.successPayoutRequests(request.id).catch((e) => {
      toast.error(e);
      return false;
    });
  });
};

const processPayment = (request: IPayoutRequest): Promise<boolean> => {
    return confirmDialog(i18n.t('APPLY_FOR_JOB'), {
    title: i18n.t('APPLICATION_FROM',{id: request.id, date: moment(request.createdAt).format('DD.MM.YYYY')}) || '',
    confirmText: i18n.t('IN_WORK') || '',
  }).then((agree) => {
    if (!agree) return Promise.resolve(false);
    return API.Payouts.processPayoutRequests(request.id).catch((e) => {
      toast.error(e);
      return false;
    });
  });
};

const retryPayment = (request: IPayoutRequest): Promise<boolean> => {
    return confirmDialog(i18n.t('RESEND_REQUEST_PROCESSING'), {
    title: i18n.t('APPLICATION_FROM',{id: request.id, date: moment(request.createdAt).format('DD.MM.YYYY')}) || '',
  }).then((agree) => {
    if (!agree) return Promise.resolve(false);
    return API.Payouts.retryPayoutRequests(request.id).catch((e) => {
      toast.error(e);
      return false;
    });
  });
};

const pendingPayment = (request: IPayoutRequest): Promise<boolean> => {
    return confirmDialog(i18n.t('RETURN_APPLICATION_IN_QUEUE'), {
    title: i18n.t('APPLICATION_FROM',{id: request.id, date: moment(request.createdAt).format('DD.MM.YYYY')}) || '',
    confirmText: i18n.t('IN_QUEUE') || '',
  }).then((agree) => {
    if (!agree) return Promise.resolve(false);
    return API.Payouts.pendingPayoutRequests(request.id).catch((e) => {
      toast.error(e);
      return false;
    });
  });
};

const failPayment = (request: IPayoutRequest): Promise<boolean> => {
    return confirmDialog(i18n.t('CLOSE_APPLICATION_ERROR'), {
    title: i18n.t('APPLICATION_FROM',{id: request.id, date: moment(request.createdAt).format('DD.MM.YYYY')}) || '',
    danger: true,
    confirmText: i18n.t('CLOSE_APPLICATION') || '',
    input: {
      label: i18n.t('ERROR_TEXT') || '',
      required: true,
    },
  }).then((res) => {
    if (!res) return Promise.resolve(false);
    return API.Payouts.failPayoutRequests(request.id, { reason: res as string }).catch((e) => {
      toast.error(e);
      return false;
    });
  });
};

export { cancelPayment, processPayment, successPayment, failPayment, pendingPayment, retryPayment };
