import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import React from 'react';
import TracksRoutes from './tracks';
import AddProfile from './profiles/add';
import ManageAuthors from './profiles/list';
import EditAuthor from './profiles/edit';
import Button from "../../components/Button";
import {useTranslation} from "react-i18next";
import './author.scss';

const AuthorRoutes = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <>

      <div className='content-padding'>
        <div className='d-flex justify-content-between mb-3'>
          <h1 className="page-title">
            {t('PERFORMERS')}
          </h1>
          <Button text="ADD_AUTHOR" icon={['fas', 'plus']} onClick={() => navigate('add/')}/>
        </div>
        <Routes>
          <Route path="/" element={<ManageAuthors/>}/>
          <Route path="/add/" element={<AddProfile/>}/>
          <Route path="/:author/tracks/*" element={<TracksRoutes/>}/>
          <Route path="/:author/edit/" element={<EditAuthor/>}/>
          <Route path="*" element={<Navigate to="/author/" replace/>}/>
        </Routes>
      </div>
    </>
  );
};

export default AuthorRoutes;
