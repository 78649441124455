import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer, useLocalObservable } from 'mobx-react';
import { IGetBillingHistoryRequest, IPagedData, ITransaction } from '../../modules/rest';
import cache from '../../modules/cache';
import Loadable from '../../components/Loadable';
import Empty from '../../components/Empty';
import { runInAction } from 'mobx';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import {useTranslation} from "react-i18next";
import Dash from "../../components/Dash";
import {Icon} from "../../components/fontawesome";

interface State {
  pager?: IPagedData<ITransaction>;
  request: IGetBillingHistoryRequest;
  loading: boolean;
}

const BillingHistory = observer(() => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    pager: cache.get('billing.history'),
    request: {
      page: 1,
    },
    loading: true,
  }));

  const navigate = useNavigate();

  const fetch = useCallback(() => {
    runInAction(() => (st.loading = true));
    API.Billing.getHistory(st.request)
      .then((res) =>
        runInAction(() => {
          st.pager = res;
          st.request.page = res.page;
          st.request.limit = res.limit;
          cache.set('billing.history', st.pager);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (st.loading = false)));
  }, [st]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <div>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/billing/">{t('BILLING')}</Link>
        </li>
        <li className="breadcrumb-item active">{t('OPERATIONS_HISTORY')}</li>
      </ul>
      <div className="mb-3 d-flex justify-content-between">
        <h4>{t('OPERATIONS_HISTORY')}</h4>
        <div className="d-flex">
          <Button text="TOP_UP_ACCOUNT" outline onClick={() => navigate('charge/')} />
          <Button text="WITHDRAWALS" outline className="ms-2" onClick={() => navigate('payout/')} />
        </div>
      </div>

      <Loadable loading={st.loading}>
        <div className="card">
          <div className="card-body">
            {st.pager && (
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>№</th>
                    <th>{t('NOTE')}</th>
                    <th colSpan={2} className="text-center">{t('SUM')}</th>
                    <th>{t('CATEGORY')}</th>
                    <th>{t('OPERATION_DATE')}</th>
                  </tr>
                </thead>
                <tbody>
                  {st.pager?.data?.map((tr) => (
                    <tr key={tr.id} className={tr.amount > 0 ? 'text-success' : 'text-danger'}>
                      <td><Icon icon={['fas', tr.amount > 0 ? 'plus-circle' : 'minus-circle']} fixedWidth/></td>
                      <td>{tr.id}</td>
                      <td>{tr.comment}</td>
                      <td className="text-right">{Math.abs(tr.amount).toFixed(2)} <span className="small">RUB</span></td>
                      <td className="text-muted small">{tr.amountUsd ? <span>≈ {Math.abs(tr.amountUsd).toFixed(2)} <span className="small">USD</span></span> : <Dash/>}</td>
                      <td>{t(tr.type)}</td>
                      <td>{moment(tr.createdAt).format('DD.MM.YYYY, HH:mm')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Empty show={!st.loading && st.pager?.data?.length === 0} />
            <Pagination
              pager={st.pager}
              onPageChange={(page) =>
                runInAction(() => {
                  st.request.page = page;
                  fetch();
                })
              }
              onLimitChange={(limit) =>
                runInAction(() => {
                  st.request.page = 1;
                  st.request.limit = limit;
                  fetch();
                })
              }
            />
          </div>
        </div>
      </Loadable>
    </div>
  );
});

export default BillingHistory;
