import { FC } from 'react';
import { ITrack } from '../../modules/rest';
import { thumb } from '../../modules/utils';


interface Props {
  track: ITrack;
  className?: string;
  hideId?: boolean;
}

const Track: FC<Props> = ({ track, className, hideId }) => {
  return (
    <div className={`d-flex align-items-center position-relative overflow-hidden${className || ''}`}>
      {!hideId
        ?
        <div
          className={`position-absolute lh-1 text-white ${track.isrc ? 'bg-success' : 'bg-primary'}`}
          style={{ left: 3, top: 3, fontSize: 10, padding: 2, borderRadius: 8 }}
        >
          #{track.id}
        </div>
        : null
      }
      <img
        src={thumb(track.cover.id, 96)}
        className='me-2'
        alt={track.title}
        style={{ width: 48, height: 48, borderRadius: 8 }}
      />
      <div className='d-flex flex-column overflow-hidden'>
        <div className='bold-14 text-truncate'>
          {track.title}
          {track.version && ` (${track.version})`}
        </div>
        <div className='text-muted-14 text-truncate-2'>
          {track.alias || track.artist}
          {track.feat && ` feat ${track.feat}`}
        </div>
      </div>
    </div>
  );
};

export default Track;
