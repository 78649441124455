import {observer, useLocalObservable} from 'mobx-react';
import {
  ELanguage,
  EMusicGenre,
  ESortOrder,
  IGetTracksRequest,
  IPagedData,
  ITrack,
  TIdentifier
} from '../../../modules/rest';
import Loadable from '../../../components/Loadable';
import React, {useCallback, useEffect} from 'react';
import {runInAction} from 'mobx';
import {API} from '../../../modules/api';
import {toast} from 'react-toastify';
import Empty from '../../../components/Empty';
import './styles.scss';
import {useNavigate} from 'react-router-dom';
import cache from '../../../modules/cache';
import Pagination from '../../../components/Pagination';
import CatalogTracksFilters from './catalog-tracks-filters';
import SearchInput from '../../../components/SearchInput';
import SortItem from '../../../components/SortItem';
import TrackBlock from "../../../components/TrackBlock";
import {useTranslation} from "react-i18next";

interface State {
  pager?: IPagedData<ITrack>;
  request: IGetTracksRequest;
  loading: boolean;
  counters: Record<TIdentifier, number>;
  freeIds: number[];
  freeEnabled: boolean;
  activeTrackId?: number;
}

const TracksRoutes = observer(() => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    pager: cache.get<IPagedData<ITrack>>('owner.tracks'),
    loading: true,
    request: {
      sort: 'price', order: ESortOrder.ASC,
      page: 1,
      limit: 30,
      query: '',
      genre: [] as EMusicGenre[],
      language: [] as ELanguage[]
    },
    counters: {},
    freeIds: [],
    freeEnabled: false
  }));

  const fetchFree = useCallback(() => {
    API.Tracks.getFreeTracksIds().then((free) => runInAction(() => (st.freeIds = free)));
  }, [st]);

  const fetch = useCallback(() => {
    runInAction(() => (st.loading = true));
    API.Tracks.getTracksList(st.request)
      .then((pager) => {
        runInAction(() => {
          st.pager = pager;
          st.request.page = pager.page;
          st.request.limit = pager.limit;
          cache.set('owner.tracks', st.pager);
        });
      })
      .catch(toast.error)
      .finally(() => runInAction(() => (st.loading = false)));
  }, [st]);

  const setFreeEnabled = useCallback(
    (free: boolean) => {
      runInAction(() => {
        if (free) {
          st.request.page = 1;
          st.request.limit = 3;
          st.request.sort = 'price';
          st.request.order = ESortOrder.ASC;
        } else {
          st.request = {page: 1};
        }
        st.freeEnabled = free;
      });
      fetch();
    },
    [st, fetch]
  );

  const navigate = useNavigate();

  useEffect(() => {
    fetch();
    fetchFree();
    const content = document.getElementById('content') as HTMLElement;
    content.classList.remove('container-fluid');
    return () => {
      content.classList.add('container-fluid');
    };
  }, [fetch, fetchFree]);

  const buy = useCallback((track: ITrack) => {
      track && navigate(`/owner/tracks/my/${track.id}`);
    },
    [navigate]
  );

  const handleSortArray = useCallback((key: 'genre'|'language', value: any, clear?: boolean) => {
    const req: any = {
      genre: st.request.genre as EMusicGenre[],
      language: st.request.language as ELanguage[]
    };
    if (clear) {
      req[key] = [];
    } else if (req[key]?.includes(value)) {
      req[key] = req[key].filter((v: string) => v !== value);
    } else {
      req[key]?.push(value);
    }
    runInAction(() => {
      st.request.page = 1;
      st.request.genre = req.genre as EMusicGenre[];
      st.request.language = req.language as ELanguage[];
      fetch();
    });
  }, [st.request.genre, st.request.genre]);

  const handleSortPrice = useCallback((price: { priceFrom?: number, priceTill?: number }) => {
    runInAction(() => {
      st.request.priceFrom = price.priceFrom;
      st.request.priceTill = price.priceTill;
      st.request.page = 1;
      fetch();
    });
  }, [st.request.genre, st.request.genre]);

  const sortCount = (st.request.genre?.length || 0) + (st.request.language?.length || 0) + (st.request.priceTill || st.request.priceFrom ? 1 : 0);

  return (
    <>
      <div className='page__title__line flex-column align-items-baseline mt-2 pt-1'>
        <div className='d-flex justify-content-between w-100 mb-3'>
          <div className='d-flex align-items-center'>
            <h1 className='mb-0 page-title'>{t('TRACK_CATALOG')}</h1>
            {/*{st.freeIds && !st.freeEnabled && (*/}
            {/*  <Button*/}
            {/*    className='ms-3'*/}
            {/*    icon={['fas', 'gift']}*/}
            {/*    text={t('TAKE_FREE)}*/}
            {/*    color='warning'*/}
            {/*    onClick={() => setFreeEnabled(true)}*/}
            {/*  />*/}
            {/*)}*/}
            {/*{st.freeEnabled && (*/}
            {/*  <Button*/}
            {/*    className='ms-3'*/}
            {/*    icon={['fas', 'dollar']}*/}
            {/*    text={t('SHOW_ALL_TRACKS)}*/}
            {/*    color='primary'*/}
            {/*    onClick={() => setFreeEnabled(false)}*/}
            {/*  />*/}
            {/*)}*/}
          </div>
          <div className='d-flex align-items-center'>
            <SearchInput
              onStartTyping={() => {
                runInAction(() => {
                  st.loading = true;
                });
              }}
              value={st.request.query}
              onSearch={(value) => {
                runInAction(() => {
                  st.request.page = 1;
                  st.request.query = value;
                  fetch();
                });
              }}
            />
          </div>
        </div>
        {sortCount
          ?
          <div className='catalog__head__filters'>
            <div className='bold me-2 text-nowrap mb-2' style={{lineHeight: '32px'}}>
              {t('FILTER_RESULT')}
              <span className='text-muted'> {sortCount}</span>
            </div>
            {Array.isArray(st.request.genre) && st.request.genre?.map((genre, i) => (
              <SortItem
                text={genre}
                className='ms-2 mb-2'
                key={i}
                onClick={() => handleSortArray('genre', genre)}
              />
            ))}
            {Array.isArray(st.request.language) && st.request.language?.map(ln => (
              <SortItem
                text={t(`langs:${ln}`) || ''}
                className='ms-2 mb-2'
                key={ln}
                onClick={() => handleSortArray('language', ln)}
              />
            ))}
            {st.request.priceTill
              ?
              <SortItem
                text={`${t('TILL')} ${st.request.priceTill} ₽`}
                className='ms-2 mb-2'
                onClick={() => handleSortPrice({priceTill: undefined, priceFrom: st.request.priceFrom})}
              />
              :
              null
            }
            {st.request.priceFrom
              ?
              <SortItem
                text={`${t('TILL')} ${st.request.priceFrom} ₽`}
                className='ms-2 mb-2'
                onClick={() => handleSortPrice({priceTill: st.request.priceTill, priceFrom: undefined})}
              />
              :
              null
            }
          </div>
          :
          null
        }
      </div>
      <Loadable loading={st.loading} className='left__content__with__filter'>
        <div className='flex-grow-1 mb-3'>
          {st.pager && (
            <div className='tracks__wrap'>
              {st.pager.data.map((track, i) => (
                <div className='tracks__item__wrap' key={i}>
                  <div className='d-flex flex-column h-100'>
                    <div className='text-muted-14 pb-2'>#{track.id}</div>
                    <TrackBlock
                      activeTrackId={st.activeTrackId}
                      changePlayedTrackId={(id) => {
                        runInAction(() => {
                          st.activeTrackId = id;
                        });
                      }}
                      track={track}
                      onChange={buy}
                      free={st.freeIds?.includes(track.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          <Empty show={!st.loading && !st.pager?.count} text='NOTHING_FOUND_REQUEST'/>
        </div>
        {!st.freeEnabled && (
          <Pagination
            onPageChange={(page) =>
              runInAction(() => {
                st.request.page = page;
                fetch();
              })
            }
            onLimitChange={(limit) =>
              runInAction(() => {
                st.request.page = 1;
                st.request.limit = limit;
                fetch();
              })
            }
            pager={st.pager}
          />
        )}
      </Loadable>
      <CatalogTracksFilters
        sortCount={sortCount}
        onSortArray={handleSortArray}
        onSortPrice={handleSortPrice}
        onClear={() => {
          runInAction(() => {
            st.request.priceFrom = undefined;
            st.request.priceTill = undefined;
            st.request.genre = [];
            st.request.language = [];
            st.request.page = 1;
            fetch();
          });
        }}
        {...st.request}
      />
    </>
  );
});

export default TracksRoutes;
