import React, {FC} from 'react';
import {EReviewStatus} from "../../../modules/rest";
import {Icon} from "../../../components/fontawesome";

interface Props {
  status: EReviewStatus;
  className?: string;
}

const AuthorStatusIcon: FC<Props> = ({status, className}) => {
  if(status === 'draft') return null;
  return (
    <div className={`${status} ${className}`}>
      {status === 'approved' && <Icon icon={['fas', 'check']} size={'xs'}/>}
      {status === 'rejected' && <Icon icon={['fas', 'ban']} size={'xs'}/>}
      {status === 'in-review' && <Icon icon={['fas', 'eye']} size={'xs'}/>}
    </div>
  );
}

export default AuthorStatusIcon;