import React, {FC, memo, useEffect, useState} from 'react';
import {Icon} from './fontawesome';
import OutsideClick from './OutsideClick';
import {ITrack} from "../modules/rest";
import {API} from "../modules/api";
import track_svg from "../assets/images/track.svg";
import {useTranslation} from "react-i18next";
import Checkbox from "./Checkbox";

interface Props {
  selectedTracks: ITrack[];
  onChange: (track: ITrack) => void;
}

const SelectTracks: FC<Props> = memo(({selectedTracks, onChange }) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [allTracks, setAllTracks] = useState<ITrack[]>([]);
  const [filteredTracks, setFilteredTracks] = useState<ITrack[]>([]);

  useEffect(() => {
    getTracks();
  }, []);

  const getTracks = async () => {
    try {
      const tracks = await API.Tracks.getMyTracks({});
      setAllTracks(tracks);
    } catch (e) {

    }
  }

  const showList = () => setVisible(true);
  const hideList = () => {
    setInputValue('');
    setFilteredTracks(allTracks);
    setVisible(false);
  };

  const handleClickTrack = (track: ITrack) => (checked: boolean) => {
    onChange(track);
  };

  const handleFilter = (e: any) => {
    const value = e.target.value.toLowerCase();
    setInputValue(value);
    if (!value) return setFilteredTracks(allTracks)
    const filteredTracks = allTracks.filter(track => track.title.toLowerCase().includes(value));
    setFilteredTracks(filteredTracks);
  };

  return (
    <OutsideClick outsideClickEvent={hideList}>
      <div className={`custom-search-select${visible ? ' visible' : ''}`}>
        <div className='custom-search-select-control' style={{background: `url(${track_svg})`}}>
          <input
            value={inputValue}
            onChange={handleFilter}
            onFocus={showList}
            type='text'
            className='form-control'
            placeholder={t('SELECT_TRACK') || ''}
          />
          <Icon icon={['fas', 'chevron-down']} color='#AFB2BB'/>
        </div>
        <div className='custom-search-select-list'>
          {!filteredTracks.length
            ?
            <div className='text-center mb-2'>{t('NOTHING_FOUND')}</div>
            :
            filteredTracks.map((track, i) => (
              <Checkbox
                className='mb-2'
                key={i}
                checked={selectedTracks.findIndex(tr => tr.id === track.id) !== -1}
                onChange={handleClickTrack(track)}
                label={track.title}
              />
            ))}
        </div>
      </div>
    </OutsideClick>
  );
});

export default SelectTracks;