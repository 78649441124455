import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import React, {useCallback, useEffect, useState} from 'react';
import AddTrack from './add';
import ManageTracks from './manage';
import {EFieldGroup, IAuthor} from '../../../modules/rest';
import Loader from '../../../components/Loader';
import {API} from '../../../modules/api';
import {toast} from 'react-toastify';
import Edit from './edit';

const TracksRoutes = () => {
  const params = useParams<{ author: string }>();

  const [authors, setAuthors] = useState<IAuthor[]>();
  const [author, setAuthor] = useState<IAuthor>();

  useEffect(() => {
    init();
  }, [params.author]);

  const init = useCallback(async () => {
    try {
      const [author, authors] = await Promise.all([API.Authors.getAuthor(params.author!), API.Authors.getAuthors([EFieldGroup.AuthorUrls])]);
      setAuthor(author);
      setAuthors(authors);
    } catch (e: any) {
      toast.error(e)
    }
  }, [params.author]);

  if (!author) return <Loader/>;

  return (
    <Routes>
      <Route path="/" element={<ManageTracks author={author} authors={authors}/>}/>
      <Route path="/add/" element={<AddTrack author={author}/>}/>
      <Route path="/:track/edit/" element={<Edit author={author}/>}/>
      <Route path="*" element={<Navigate to="/author/tracks/" replace/>}/>
    </Routes>
  );
};

export default TracksRoutes;
