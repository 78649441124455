import {EVideoStatus, EVideoType, IVideo} from '../../../modules/rest';
import {thumb, views} from '../../../modules/utils';
import stub from '../../../assets/images/img-stub.png';
import {VideoStates} from '../../../modules/directory';
import React, {FC, useCallback, useState} from 'react';
import DropDown, {DropDownItem} from '../../../components/DropDown';
import {confirmDialog} from '../../../modules/confirm';
import {API} from '../../../modules/api';
import {toast} from 'react-toastify';
import {useTranslation} from "react-i18next";
import Checkbox from "../../../components/Checkbox";
import Loadable from "../../../components/Loadable";

interface Props {
  onChanged: () => void;
  video: IVideo;
  selectedVideosIds: number[];
  onSelectVideo: (id: number) => void;
}

const TrackItemVideo: FC<Props> = ({video, onChanged, selectedVideosIds, onSelectVideo}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const cutoutTrack = useCallback(() => {
    confirmDialog(t('CUT_TRACK_CONFIRM_TITLE', {title: video.title}), {
      title: t('CUTTING_TRACK') || '',
      danger: true,
      confirmText: t('TRACK_CUT') || '',
    }).then((confirmed) => {
      if (!confirmed) return;
      setLoading(true);
      API.Videos.cutoutTrack(video.id)
        .then(() => {
          toast.success(t('TRACK_CUT_SUCCESS'));
          onChanged();
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    });
  }, [setLoading, video, onChanged, t]);

  const deleteTrack = useCallback(() => {
    confirmDialog(t('DELETE_CLIP_CONFIRM_TITLE', {title: video.title}), {
      title: t('DELETING_CLIP') || '',
      danger: true,
      confirmText: t('REMOVE') || '',
    }).then((confirmed) => {
      if (!confirmed) return;
      setLoading(true);
      API.Videos.deleteVideo(video.id)
        .then(() => {
          toast.success(t('CLIP_REMOVED'));
          onChanged();
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    });
  }, [setLoading, video, onChanged]);

  const openVideo = useCallback(() => {
    window.open(
      video.type === EVideoType.Short
        ? `https://www.youtube.com/shorts/${video.extId}`
        : `https://www.youtube.com/watch?v=${video.extId}`
    );
  }, [video.type, video.extId]);

  const openStudio = useCallback(() => {
    window.open(`https://studio.youtube.com/video/${video.extId}/edit`);
  }, [video.extId]);

  const checked = selectedVideosIds.includes(video.id);

  return (
    <Loadable
      loading={loading}
      className={`my__tracks__modal__video__list__item${checked ? ' active' : ''}${checked || selectedVideosIds?.length ? ' checked' : ''}${!video.hasTrack || video.status !== EVideoStatus.Public ? ' disabled' : ''}`}>
      <div className='my__tracks__modal__video__list__item-id text-muted-14'>
        #{video.id}
      </div>
      <div className='my__tracks__modal__video__list__item-checkbox text-muted-14'>
        <Checkbox checked={checked} onChange={() => onSelectVideo(video.id)}/>
      </div>
      <div className='d-flex align-items-center flex-grow-1'>
        <img
          alt="video"
          className="my__tracks__modal__video__list__item-preview"
          src={video.cover ? thumb(video.cover!.id, 180) : stub}
          onClick={openVideo}
        />
        <div className="my__tracks__modal__video__list__item-title text-muted bold-14 text-truncate-2">
          {video.title || video.extId}
        </div>
      </div>
      <div style={{minWidth: 150, width: 150}} className='text-muted-14'>
        {t(video.hasTrack ? VideoStates[video.status] : 'TRACK_CUT')}
      </div>
      <div style={{minWidth: 208, width: 208}} className='d-flex justify-content-between align-items-center'>
        {video.status === EVideoStatus.Public && <span className="me-2 text-muted-14">{views(video.views)}</span>}
        <DropDown positions={['bottom', 'top', 'right', 'left']}>
          {video.hasTrack && video.type !== 'short'
            ?
            <DropDownItem icon={['fas', 'cut']} text="TRACK_CUT" danger onClick={cutoutTrack}/>
            :
            null
          }
          <DropDownItem icon={['fab', 'youtube']} text="GO_TO_STUDIO" onClick={openStudio}/>
          {/*<DropDownItem icon={['fas', 'trash-alt']} text="REMOVE_CLIP" danger onClick={deleteTrack} />*/}
        </DropDown>
      </div>
    </Loadable>
  );
};

export default TrackItemVideo;
