import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import TracksRoutes from './tracks';
import StatsPage from './stats/StatsPage';
import MyTracks from './tracks/my-tracks';

const OwnerRoutes = () => {
  return (
    <Routes>
      <Route path="/stats/*" element={<StatsPage />} />
      <Route path="/tracks/*" element={<TracksRoutes />} />
      <Route path="/tracks/my/*" element={<MyTracks />} />
      <Route path="*" element={<Navigate to="/owner/tracks/my/" replace />} />
    </Routes>
  );
};

export default OwnerRoutes;
