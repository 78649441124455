import React, {FC} from 'react';
import Icon from './fontawesome';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {useTranslation} from "react-i18next";

interface Props {
  text?: string;
  className?: string;
  size?: SizeProp;
}

const Loader: FC<Props> = ({text, size = '1x', className}) => {
  const {t} = useTranslation();
  return (
    <div className={`loader ${className || ''}`} style={{color: 'gray'}}>
      <Icon icon={['fas', 'spinner']} spin fixedWidth size={size}/>
      {!!text && <span className="ms-1">{t(text)}</span>}
    </div>
  );
};

export default Loader;
