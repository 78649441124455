import { FormEvent, useCallback, useEffect, useState } from 'react';
import { API } from '../modules/api';
import Loadable from '../components/Loadable';
import { toast } from 'react-toastify';
import logo from '../assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const PasswordRoute = () => {
  const {t} = useTranslation();
  const [email, setEmail] = useState(localStorage.getItem('email') ?? '');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Users.password({ email })
        .then(() => {
          toast.success(t('CHECK_EMAIL'));
          navigate('/login', { replace: true });
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [email, setLoading, navigate]
  );

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-light');
    return () => {
      body.classList.remove('bg-light');
    };
  });

  return (
    <Loadable loading={loading}>
      <div className="d-flex justify-content-center" style={{ marginTop: 100 }}>
        <form onSubmit={submit} className="card border-0 shadow" style={{ width: 450 }}>
          <div className="card-body p-4">
            <div className="d-flex justify-content-center mt-3 mb-5">
              <img src={logo} height={65} alt="" />
            </div>
            <div className="mb-4">
              <label className="req">{t('EMAIL')}</label>
              <input
                autoFocus
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="form-text">{t('EMAIL_TEXT')}</div>
            </div>

            <div className="d-flex justify-content-between">
              <button type="submit" className="btn btn-outline-primary flex-grow-1 me-2">
                {t('RESET_PASSWORD')}
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => navigate('/login', { replace: true })}
              >
                {t('BACK')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Loadable>
  );
};

export default PasswordRoute;
