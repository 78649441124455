import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import {NavLink} from 'react-router-dom';
import {API} from '../modules/api';
import {IDoc, IDocItem} from '../modules/rest';
import {useTranslation} from "react-i18next";

const Help = () => {
  const {t} = useTranslation();
  const params = useParams<{ id?: string }>();
  const [doc, setDoc] = useState<IDoc>();
  const [docs, setDocs] = useState<IDoc[]>([]);
  const [path, setPath] = useState<IDocItem[]>([]);

  useEffect(() => {
    const id = params.id ? Number(params.id) : null;
    if (id) {
      API.Help.getById(id).then(setDoc).catch((e) => toast.error(e, {toastId: 'support'}));
      API.Help.getPath(id).then(setPath).catch((e) => toast.error(e, {toastId: 'support'}));
    } else {
      setDoc(undefined);
      setPath([]);
    }
    API.Help.index({parentId: id}).then(setDocs).catch((e) => toast.error(e, {toastId: 'support'}));
  }, [params]);

  return (
    <div>
      <h4 className="mb-3">{doc?.title || t('HELP')}</h4>
      {path.length > 0 && (
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/help/">{t('HELP')}</NavLink>
          </li>
          {path.map((d) => (
            <li className="breadcrumb-item" key={d.id}>
              <NavLink to={`/help/${d.id}/`}>{d.title}</NavLink>
            </li>
          ))}
        </ul>
      )}

      <div className="card">
        <div className="card-body">
          <div dangerouslySetInnerHTML={{__html: doc?.content || ''}}/>
          {docs.length > 0 && (
            <ul className="mb-0 fs-5">
              {docs.map((d) => (
                <li>
                  <NavLink key={d.id} to={`/help/${d.id}/`} className="text-decoration-none">
                    {d.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Help;
