import React, {FC, useCallback, useEffect, useState} from 'react';
import './tracks-recommended.scss';
import {ELanguage, EMusicGenre, IGetTracksRequest} from '../../../modules/rest';
import {Icon} from '../../../components/fontawesome';
import SortItem from '../../../components/SortItem';
import {Genres} from '../../../modules/directory';
import SelectLanguages from '../../../components/SelectLanguages';
import {API} from "../../../modules/api";
import {useTranslation} from "react-i18next";

interface Props extends IGetTracksRequest {
  sortCount: number;
  onSortArray: (key: 'genre'|'language', value: any, clear?: boolean) => void;
  onSortPrice: (price: { priceFrom?: number, priceTill?: number }) => void;
  onClear: () => void;
}

const CatalogTracksFilters: FC<Props> = ({
                                           genre,
                                           language,
                                           priceFrom,
                                           priceTill,
                                           onSortArray,
                                           onSortPrice,
                                           onClear,
                                           sortCount
                                         }) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState('');
  const [price, setPrice] = useState<{ priceFrom?: number, priceTill?: number }>({priceFrom, priceTill});
  const [options, setOptions] = useState<{ languages: ELanguage[]; genres: EMusicGenre[] }>();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const res: any = await API.Tracks.getOptions();
      setOptions(res);
    } catch (e) {
    }
  }

  const toggleVisible = useCallback((item: string) => () => {
    setVisible(prevState => {
      if (prevState === item) return '';
      return item;
    });
  }, []);

  const handleChangePrice = useCallback((key: 'priceFrom'|'priceTill') => (e: any) => {
    setPrice(price => {
      return {...price, [key]: Number(e.target.value) || undefined};
    });
  }, []);

  return (
    <div className='right_sider__filters'>
      <div className='d-flex align-items-center pb-3'>
        <div className='circle-light-40'>
          <Icon icon={['fas', 'sliders']} fixedWidth color='#34353C'/>
        </div>
        <div className='px-2 bold'>{t('FILTER')}</div>
        {sortCount ? <SortItem text={sortCount} yellow className='text-14' onClick={onClear}/> : null}
      </div>
      <div className={`page__filters__item${visible === 'genre' ? ' visible' : ''}`}>
        <div className='page__filters__item__head' onClick={toggleVisible('genre')}>
          <div className='d-flex align-items-center'>
            <div className='bold me-2'>{t('GENRE')}</div>
            {genre?.length ? <SortItem text={genre?.length} onClick={() => onSortArray('genre', '', true)}/> : null}
          </div>
          <div className='chevron'><Icon icon={['fas', 'chevron-down']} fixedWidth color='#AFB2BB'/></div>
        </div>
        <div className='page__filters__item__hidden'>
          {options?.genres.map((value, i) => (
            <SortItem
              text={Genres[value]}
              key={i}
              className='mx-1 mb-2 text-14 px-3'
              white
              hideClose
              active={genre?.includes(value)}
              onClick={() => onSortArray('genre', value as EMusicGenre)}
            />
          ))}
        </div>
      </div>
      <div className={`page__filters__item${visible === 'language' ? ' visible' : ''}`}>
        <div className='page__filters__item__head' onClick={toggleVisible('language')}>
          <div className='d-flex align-items-center'>
            <div className='bold me-2'>{t('LANG')}</div>
            {language?.length ?
              <SortItem text={language.length} onClick={() => onSortArray('language', '', true)}/> : null}
          </div>
          <div className='chevron'><Icon icon={['fas', 'chevron-down']} fixedWidth color='#AFB2BB'/></div>
        </div>
        <div className='page__filters__item__hidden'>
          <SelectLanguages
            availableLanguages={options?.languages}
            onChange={(ln: string) => onSortArray('language', ln)}
            selectedLanguages={language as ELanguage[]}
          />
        </div>
      </div>
      <div className={`page__filters__item${visible === 'price' ? ' visible' : ''}`}>
        <div className='page__filters__item__head' onClick={toggleVisible('price')}>
          <div className='d-flex align-items-center'>
            <div className='bold me-2'>{t('PRICE')}</div>
            {priceTill || priceFrom
              ?
              <SortItem
                className='ps-0 pe-2'
                text={''}
                onClick={() => onSortPrice({priceTill: 0, priceFrom: 0})}
              />
              : null
            }
          </div>
          <div className='chevron'><Icon icon={['fas', 'chevron-down']} fixedWidth color='#AFB2BB'/></div>
        </div>
        <div className='page__filters__item__hidden'>
          <div className='page__filters__price'>
            <div className='input-icon'>₽</div>
            <div className='position-relative'>
              <div className='page__filters__input__label text-muted'>{t('MIN_PRICE')}</div>
              <input
                type='number'
                className='form-control'
                value={price.priceFrom}
                onChange={handleChangePrice('priceFrom')}
              />
            </div>
            <div className='position-relative' style={{borderLeft: '1px solid #E3E7ED'}}>
              <div className='page__filters__input__label text-muted'>{t('MAX_PRICE')}</div>
              <input
                type='number'
                className='form-control'
                value={price.priceTill}
                onChange={handleChangePrice('priceTill')}
              />
            </div>
          </div>
          <button
            className='btn btn-secondary btn-sm mt-2 w-100'
            onClick={() => onSortPrice(price)}
            type={'button'}
          >
            {t('APPLY')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CatalogTracksFilters;
