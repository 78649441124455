import React, {FC, useCallback, useEffect, useState, useDeferredValue} from 'react';
import {Icon} from './fontawesome';
import {useTranslation} from "react-i18next";

interface Props {
  className?: string;
  inputClassName?: string;
  value?: string;
  small?: boolean;
  onSearch: (value: string) => void;
  onStartTyping?: () => void;
}

const SearchInput: FC<Props> = ({small, className, inputClassName, value, onSearch, onStartTyping}) => {
  const {t} = useTranslation();
  const [inputVisible, setInputVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState(value || '');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchTerm);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChange = useCallback((e: any) => {
    if (onStartTyping) onStartTyping();
    setSearchTerm(e.target.value);
  }, []);

  return (
    <div className={`search-input${small ? ' small' : ''}${inputVisible || searchTerm ? ' visible' : ''} ${className || ''}`}>
      <span className='input-icon'>
        <Icon icon={['fas', 'search']} fixedWidth color='#AFB2BB' size={small ? 'sm' : 'lg'}/>
      </span>
      <input
        onFocus={() => setInputVisible(true)}
        onBlur={() => setInputVisible(false)}
        type='text'
        className={`form-control ${inputClassName || ''}`}
        placeholder={t('SEARCH') || ''}
        value={searchTerm || ''}
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchInput;