import React, {FC} from 'react';
import {ITrack} from "../../modules/rest";
import Marquee from "../Marquee";

interface Props {
  className?: string;
  track: ITrack;
  inline?: boolean;
  active?: boolean;
  width?: number;
}

const TrackTitle: FC<Props> = ({active, track, className, inline, width}) => {
  return (
    <Marquee active={active || false} width={width}>
      <div className={`track-title${inline ? ' track-title-inline' : ''} ${className || ''}`} style={{minWidth: width || 'auto'}}>
        <div className='d-grid overflow-hidden'>
          <span className="bold-14 text-nowrap text-truncate" title={track.title}>
            {track.title}
          </span>
        </div>
        {inline ? <span className='px-1 text-muted-14'>&#8226;</span> : null}
        <div className='d-grid overflow-hidden'>
          <span className="text-muted-14 text-truncate" title={track.alias || track.artist}>
           {track.alias || track.artist}
          </span>
        </div>
      </div>
    </Marquee>
  );
}

export default TrackTitle;