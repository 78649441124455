import React, { FC, useCallback, useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import session from '../modules/session';
import { EUserRole, IPayoutDetails, IUser } from '../modules/rest';
import { openCreatePayoutDetailsModal } from '../modals/common/CreatePayoutDetailsModal';
import { API } from '../modules/api';
import { Icon } from './fontawesome';
import { observer } from 'mobx-react';

interface Props {
  user: IUser | null
}

const NeedPaymentPanel: FC<Props> = ({user}) => {
  const {t} = useTranslation();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    init();
  },[])

  const init = async () => {
    try {
      const res = await API.Billing.getPayoutDetails();
      session.editPayoutDetails(res);
    } catch (e) {

    } finally {
      setReady(true)
    }
  }

  const addDetails = useCallback(() => {
    openCreatePayoutDetailsModal().then((details) => {
      if (details) {
        session.editPayoutDetails([details])
      }
    });
  }, []);

  if(session.payoutDetails?.length || session?.user?.role !== EUserRole.Owner || !user || !ready) return null;
  return (
    <div className={`alert alert-warning d-flex align-items-center ms-3`}>
      <Icon icon={['fas', 'info-circle']} fixedWidth />
      <div className='ps-2'>
        <span>{t('CANT_GET_MONEY')} </span>
        <span className='text-decoration-underline cursor-pointer' onClick={addDetails}>{t('ADD_NOW')}</span>
      </div>
    </div>

  );
}


export default observer(NeedPaymentPanel);