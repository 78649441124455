import React, {FC} from 'react';
import {IAuthor} from "../../../modules/rest";
import {ReviewStates} from "../../../modules/directory";
import AuthorStatusIcon from "./AuthorStatusIcon";
import {useTranslation} from "react-i18next";

interface Props {
  author: IAuthor
}

const Author: FC<Props> = ({author}) => {
  const {t} = useTranslation();
  return (
    <div className='bg-secondary p-3 d-flex bdrs-4'>
      <div className={`user d-flex align-items-center`} style={{overflow: 'hidden'}}>
        <div className={`me-3 user-id user-id-author`} style={{minWidth: 102, height: 102}} />
        <div>
          <div className='d-flex align-items-center'>
            <AuthorStatusIcon status={author.status} className={'card__item__status-icon me-2'}/>
            <div className={`x-small user-status-${author.status} lh-1`}>{t(ReviewStates[author.status])}</div>
          </div>
          <div className='bold-24 mt-1'>{author.alias}</div>
          <div className='text-muted'>{author.lastName} {author.firstName}</div>
        </div>
      </div>
      <div className='ps-5 ms-5'>
        <div className='text-muted-14 mb-2'>{t('LINKS_COMMUNITY')}</div>
        <div className='d-flex flex-wrap'>
        {author?.urls?.map((link, i) => (
          <a
            href={link}
            target={'_blank'}
            key={i}
            className={'text-muted text-decoration-underline me-2 text-break'}
          >{link}</a>
        ))}
        </div>
      </div>
    </div>
  );
}

export default Author;