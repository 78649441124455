import React, { useCallback, useEffect } from 'react';
import { API } from '../../../modules/api';
import { EPayoutStatus, IGetPayoutsRequest, IPagedData, IPayoutRequest } from '../../../modules/rest';
import Button from '../../../components/Button';
import { toast } from 'react-toastify';
import { observer, useLocalObservable } from 'mobx-react';
import Loadable from '../../../components/Loadable';
import { runInAction } from 'mobx';
import Empty from '../../../components/Empty';
import cache from '../../../modules/cache';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PayoutStates } from '../../../modules/directory';
import { openCreatePayoutRequestModal } from '../../../modals/common/CreatePayoutRequestModal';
import Pagination from '../../../components/Pagination';
import { cancelPayment, retryPayment } from '../../../modals/payments';
import session from '../../../modules/session';
import {useTranslation} from "react-i18next";

interface State {
  pager?: IPagedData<IPayoutRequest>;
  request: IGetPayoutsRequest;
  loading: boolean;
}

const PayoutRequests = observer(() => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    loading: false,
    pager: cache.get('payouts.pager'),
    request: cache.get('payouts.request') ?? { page: 1, limit: 20 },
  }));

  const fetch = useCallback(() => {
    runInAction(() => (st.loading = true));
    API.Payouts.getPayoutRequests(st.request)
      .then((res) =>
        runInAction(() => {
          st.pager = res;
          st.request.page = res.page;
          st.request.limit = res.limit;
          cache.set('payouts.pager', res);
          cache.set('payouts.request', st.request);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (st.loading = false)));
  }, [st]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <div>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/billing/">{t('BILLING')}</Link>
        </li>
        <li className="breadcrumb-item active">{t('WITHDRAWALS')}</li>
      </ul>
      <div className="d-flex mb-3 justify-content-between align-items-center">
        <h4 className="mb-0">{t('WITHDRAWALS_REQUESTS')}</h4>
        <Button
          text="CREATE_APPLICATION"
          icon={['fas', 'plus']}
          outline
          onClick={() => openCreatePayoutRequestModal().then(fetch)}
        />
      </div>
      <div className="card">
        <div className="card-body">
          <Loadable loading={st.loading}>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t('METHOD')}</th>
                  <th className="text-right">{t('RECEIPT_AMOUNT')}</th>
                  <th className="text-right">{t('COMMISSION')}</th>
                  <th className="text-right">{t('WRITEOFF_AMOUNT')}</th>
                  <th>{t('APPLICATION_STATUS')}</th>
                  <th>{t('APPLICATION_DATE')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {st.pager?.data.map((r) => (
                  <tr key={r.id}>
                    <td>
                      <span className="badge bg-success">{r.id}</span>
                    </td>
                    <td>{r.details?.title}</td>
                    <td className="text-right bold">
                      {r.amount} <span className="text-muted small">{r.currency}</span>
                    </td>
                    <td className="text-right">
                      {r.fee} <span className="text-muted small">{r.currency}</span>
                    </td>
                    <td className="text-right">
                      {r.cost} <span className="text-muted small">RUB</span>
                    </td>
                    <td>
                      <div className={`payout-status-${r.status} bold`}>{t(PayoutStates[r.status])}</div>
                      {r.reason && <div className="small text-danger">{r.reason}</div>}
                    </td>
                    <td>{moment(r.createdAt).format('DD.MM.YYYY, HH:MM')}</td>
                    <td>
                      <div className="d-flex justify-content-end">
                        {[EPayoutStatus.Failed].includes(r.status) && (
                          <Button
                            text="REPEAT"
                            icon={['fas', 'refresh']}
                            size="xs"
                            outline
                            onClick={() => retryPayment(r).then(fetch)}
                          />
                        )}

                        {[EPayoutStatus.Pending, EPayoutStatus.Failed].includes(r.status) && (
                          <Button
                            title="CANCEL"
                            size="sm"
                            outline
                            color="danger"
                            icon={['fas', 'times']}
                            onClick={() =>
                              cancelPayment(r).then(() => {
                                fetch();
                                session.fetch().then();
                              })
                            }
                            className="ms-1"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Empty show={st.pager?.data.length === 0 && !st.loading} />
            <Pagination
              pager={st.pager}
              onPageChange={(page) =>
                runInAction(() => {
                  st.request.page = page;
                  fetch();
                })
              }
              onLimitChange={(limit) =>
                runInAction(() => {
                  st.request.page = 1;
                  st.request.limit = limit;
                  fetch();
                })
              }
            />
          </Loadable>
        </div>
      </div>
    </div>
  );
});

export default PayoutRequests;
