import React, {FC, useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Button from "../components/Button";
import Loadable from "../components/Loadable";
import {API} from "../modules/api";

interface Props {
}

const ApiKeyPage: FC<Props> = () => {
  const {t} = useTranslation();
  const [apiKey, setApiKey] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getApiKey();
  }, []);

  const getApiKey = useCallback(async () => {
    try {
      const res = await API.Users.getApiKey();
      setApiKey(res || '');
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }, []);

  const createApiKey = useCallback(async () => {
    try {
      setLoading(true);
      const res = await API.Users.setApiKey();
      setApiKey(res);
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }, []);

  const deleteApiKey = useCallback(async () => {
    try {
      setLoading(true);
      await API.Users.deleteApiKey()
      setApiKey('');
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }, []);
  return (
    <Loadable loading={loading}>
      <div className='page__title__line mt-2 pt-1'>
        <h1 className='mb-0 page-title'>{t('API_KEY')}</h1>
      </div>
      <div className='page__title__line mt-4'>
        {!apiKey
          ?
          <Button text={'CREATE'} onClick={createApiKey} loading={loading} size={'sm'}/>
          :
          <div>
            <div className={'bold-24'} id='monetize-apiKey'>{apiKey}</div>
            <div className={'text-success monetize-apiKey-success'} id='monetize-apiKey'>{t('SUCCESS_EXTENSION')}</div>
            <div className='d-flex mt-3'>
              <Button text={'UPDATE'} onClick={createApiKey} loading={loading} size={'sm'}/>
              <Button color='danger' text={'REMOVE'} onClick={deleteApiKey} loading={loading} size={'sm'}
                      className='ms-3'/>
            </div>
          </div>
        }

      </div>

    </Loadable>
  );
}

export default ApiKeyPage;