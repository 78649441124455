import {FC, useRef} from 'react';
import {generatePassword} from '../modules/utils';
import {useTranslation} from "react-i18next";
import {Icon} from "./fontawesome";

interface Props {
  label?: string;
  id?: string;
  checked: boolean;

  onChange(checked: boolean): void;

  disabled?: boolean;
  className?: string;
}

const Checkbox: FC<Props> = ({label, checked, onChange, disabled, className, id}) => {
  const {t} = useTranslation();
  const _id = useRef('check_' + generatePassword());
  return (
    <div className={`form-check ${className ?? ''}`}>
      <input
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange(e.target.checked)}
        className="form-check-input"
        type="checkbox"
        id={id || _id.current}
      />
      <div className='form-check-custom'>
        <Icon icon={['fas', 'check']} />
      </div>
      {label
        ?
        <label className="form-check-label cursor-pointer ps-2" htmlFor={id || _id.current}>
          {t(label)}
        </label>
        :
        null
      }
    </div>
  );
};

export default Checkbox;
