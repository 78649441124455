import Button from '../../../components/Button';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import { Link, useNavigate } from 'react-router-dom';
import usdt from '../../../assets/images/payment-usdt.png';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import CopyToClipboard from '../../../components/CopyToClipboard';
import { IBillingConfig } from '../../../modules/rest';
import Loadable from '../../../components/Loadable';
import {useTranslation} from "react-i18next";

enum Tab {
  amount,
  payment,
  confirm,
}

const ChargeUsdt = () => {
  const {t} = useTranslation();
  const [tab, setTab] = useState<Tab>(Tab.amount);

  const [config, setConfig] = useState<IBillingConfig>();
  const [amount, setAmount] = useState(1000);
  const [loading, setLoading] = useState(false);
  const [hash, setHash] = useState('');
  const navigate = useNavigate();

  const submitAmount = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setTab(Tab.payment);
    },
    [setTab]
  );

  const submitConfirm = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Billing.confirmUsdtTransaction({ hash })
        .then(() => {
          navigate('/billing/charge/success/', { replace: true });
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [hash, setLoading, navigate]
  );

  useEffect(() => {
    API.Billing.getConfig().then(setConfig).catch(toast.error);
  }, [setConfig]);

  if (!config) return <Loader text="GET_SERVER_SETTINGS" />;

  const total = (amount / config.usdtRate).toFixed(4);

  return (
    <div>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/billing/">{t('BILLING')}</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/billing/charge/">{t('REFILL')}</Link>
        </li>
        <li className="breadcrumb-item active">USDT (TRC20)</li>
      </ul>

      <div>
        <img src={usdt} height={50} alt="USDT" />
      </div>

      {tab === Tab.amount && (
        <div>
          <form onSubmit={submitAmount} style={{ width: 350 }} className="my-3">
            <div className="mb-3">
              <label className="req">{t('SUM')}</label>
              <div className="input-group">
                <input
                  required
                  type="number"
                  className="form-control"
                  autoFocus
                  step={0.01}
                  min={100}
                  value={amount || ''}
                  disabled={loading}
                  onChange={(e) => setAmount(Number(e.target.value))}
                />
                <div className="input-group-text">RUB</div>
              </div>
              <div className="form-text">
                1 USDT = {config.usdtRate} ₽, {t('to_pay')} {total} USDT
              </div>
            </div>

            <div className="d-flex mt-4">
              <Button outline type="submit" text="GO_TO_PAYMENT" disabled={amount < 100 || loading} />
              <Button
                outline
                onClick={() => navigate('/billing/charge/', { replace: true })}
                text="CANCEL"
                color="secondary"
                disabled={loading}
                className="ms-2"
              />
            </div>
          </form>
        </div>
      )}

      {tab === Tab.payment && (
        <div className="d-flex mt-3">
          <div>
            <Loadable loading={loading}>
              <div className="lead">
                <div className="fw-bold mb-3">TRC-20</div>
                <div className="mb-3">
                  <div>{t('PAYMENT_WALLET')}</div>
                  <div>
                    <code>{config.usdtAddress}</code> <CopyToClipboard data={config.usdtAddress} />
                  </div>
                </div>
                <div>
                  <div>{t('SUM_IN', {currency: 'USDT'})}</div>
                  <div>
                    <code>{total}</code> <CopyToClipboard data={total} />
                  </div>
                </div>
              </div>
              <form onSubmit={submitConfirm} style={{ width: 600 }} className="my-3">
                <div className="mb-3">
                  <label className="req">{t('TRANSACTION_HASH')}</label>

                  <input
                    type="text"
                    className="form-control"
                    autoFocus
                    required
                    value={hash}
                    minLength={64}
                    maxLength={64}
                    placeholder="0be7f67a7e7942997428e1cc56894996da648fa0c982d6af363f8e1a6a4e3df7"
                    onChange={(e) => setHash(e.target.value)}
                  />
                </div>

                <div className="d-flex mt-4">
                  <Button outline type="submit" text="ACCEPT_TRANSACTION" disabled={amount < 2 || loading} />
                  <Button outline onClick={() => setTab(Tab.amount)} text="CANCEL" color="secondary" className="ms-2" />
                </div>
              </form>
            </Loadable>
          </div>

          <div className="ms-5">
            <QRCode value={`${config.usdtAddress}?amount=${total}`} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChargeUsdt;
