import { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon } from './fontawesome';
import './CopyToClipboard.scss';
import {useTranslation} from "react-i18next";

const CopyToClipboard: FC<{ data: string }> = ({ data }) => {
  const {t} = useTranslation();
  const [copied, setCopied] = useState(false);
  const copy = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
    navigator.clipboard.writeText(data).then(() => {
      toast.success(t('COPIED'));
    });
  }, [data]);

  return (
    <button onClick={copy} className={`w-copy ${copied ? 'w-copy--copied' : ''}`} title={t('CLIP_COPY') || ''}>
      {copied ? <Icon icon={['fas', 'check']} fixedWidth /> : <Icon icon={['fas', 'copy']} fixedWidth />}
    </button>
  );
};
export default CopyToClipboard;
