import { EContractStatus, IContract, IUpload } from '../../modules/rest';
import asyncModal from 'react-async-modal';
import { FC, FormEvent, useCallback, useState } from 'react';
import Button from '../../components/Button';
import InputFile from '../../components/InputFile';
import { download } from '../../modules/utils';
import Loadable from '../../components/Loadable';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import {useTranslation} from "react-i18next";

export enum SignSide {
  One,
  Two,
}

interface Props {
  contract: IContract;
  side: SignSide;

  resolve(contract: IContract | false): void;
}

const ContractModal: FC<Props> = (props) => {
  const {t} = useTranslation();
  const [doc, setDoc] = useState<IUpload | null>(null);
  const [loading, setLoading] = useState(false);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Contracts.signContract(props.contract.id, { uploadId: doc!.id })
        .then((res) => {
          toast.success(t('CONTRACT_SENT'));
          props.resolve(res);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [setLoading, props, doc, t]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <h5 className="mb-4">{t('TREATY')} №{props.contract?.fullTag}</h5>
        <div className="mb-3">
          {t('CONTRACT_STATUS')}:{' '}
          <span className="bold">
            {props.contract.status === EContractStatus.Blank && t('NOT_SIGNED')}
            {props.contract.status === EContractStatus.SignedOne && t('SIGNED_BY_ONE')}
            {props.contract.status === EContractStatus.SignedTwo && t('CONTRACT_SIGNED')}
          </span>
        </div>

        {props.side === SignSide.One && (
          <>
            <div className="mb-3">
              <div className="mb-2">
                <b>{t('STEP')} 1:</b> {t('CONTRACT_STEP_1_1')}
              </div>
              <div>
                <Button
                  icon={['fas', 'download']}
                  outline
                  size="sm"
                  text={'DOWNLOAD_FORM'}
                  onClick={() => download(props.contract.blank!)}
                />
              </div>
            </div>
          </>
        )}

        {props.side === SignSide.Two && (
          <>
            <div className="mb-3">
              <div className="mb-2">
                <b>{t('STEP')} 1:</b> {t('CONTRACT_STEP_1_2')}
              </div>
              <div>
                <Button
                  icon={['fas', 'download']}
                  outline
                  size="sm"
                  text={'DOWNLOAD_FORM'}
                  onClick={() => download(props.contract.signedOne!)}
                />
              </div>
            </div>
          </>
        )}

        <div className="mb-3">
          <b>{t('STEP')} 2:</b> {t('CONTRACT_STEP_2')}
        </div>

        <div className="mb-3">
          <div className="mb-2">
            <b>{t('STEP')} 3:</b> {t('CONTRACT_STEP_3')}
          </div>
          <InputFile value={doc} onChange={setDoc} accept=".pdf, .zip" text="UPLOAD_SCAN" />
        </div>

        <div className="d-flex justify-content-end mt-3">
          <Button
            text="SIGN_CONTRACT"
            type="submit"
            color="success"
            outline={!doc}
            disabled={!doc}
            icon={['fas', 'check']}
          />
          <Button text="CANCEL" color="secondary" className="ms-2" outline onClick={() => props.resolve(false)} />
        </div>
      </form>
    </Loadable>
  );
};

const signContractModal = (contract: IContract, side: SignSide): Promise<IContract | false> => {
  return asyncModal(ContractModal, { contract, side }, { styles: { modal: { width: 600 } } });
};

export { signContractModal };
