import {observer, useLocalObservable} from 'mobx-react';
import Button from '../../../components/Button';
import React, {FC, FormEvent, useCallback} from 'react';
import Loadable from '../../../components/Loadable';
import {
  ECountry,
  EReviewStatus,
  IAddAuthorRequest,
  IAuthor,
  IPassport,
  IUpdateAuthorRequest, IUpload
} from '../../../modules/rest';
import {runInAction} from 'mobx';
import {API} from '../../../modules/api';
import {toast} from 'react-toastify';
import {Countries} from '../../../modules/directory';
import moment from 'moment';
import {useTranslation} from "react-i18next";
import InputFile from "../../../components/InputFile";

interface State {
  loading: boolean;
  request: IAddAuthorRequest|IUpdateAuthorRequest;
  passportScan: IUpload | null;
}

interface Props {
  mode: 'add'|'edit';
  author?: IAuthor;
  onChange(author: IAuthor): void;
}

const AuthorCompose: FC<Props> = observer(({author, mode, onChange}) => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    loading: false,
    passportScan: author?.passportScan ?? null,
    request: {
      nationality: author?.nationality ?? ECountry.RU,
      firstName: author?.firstName ?? '',
      middleName: author?.middleName ?? '',
      lastName: author?.lastName ?? '',
      alias: author?.alias ?? '',
      passport: {
        series: author?.passport?.series ?? '',
        number: author?.passport?.number ?? '',
        issuedBy: author?.passport?.issuedBy ?? '',
        issuedAt: author?.passport?.issuedAt ? moment(author?.passport?.issuedAt).format('YYYY-MM-DD') : '',
      },
      postAddress: author?.postAddress ?? '',
      regAddress: author?.regAddress ?? '',
      phone: author?.phone ?? '',
      urls: author?.urls ?? [],
      passportScanId: author?.passportScan?.id
    },
  }));

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      runInAction(() => (st.loading = true));

      let promise;

      if (mode === 'add') promise = API.Authors.addAuthor(st.request as IAddAuthorRequest);
      else promise = API.Authors.updateAuthor(author!.id, st.request as IUpdateAuthorRequest);

      promise
        .then((author) => {
          toast.success(
            t(author.status === EReviewStatus.InReview ? 'SUCCESS_SAVE_PROFILE_AND_SEND' : 'SUCCESS_SAVE_PROFILE')
          );
          onChange(author);
        })
        .catch(toast.error)
        .finally(() => runInAction(() => (st.loading = false)));
    },
    [st, author, mode, onChange, t]
  );

  return (
    <Loadable loading={st.loading}>
      <h4 className="mb-3">{t('CONTRACT_FORM')}</h4>
      <form onSubmit={submit} className="card">
        <div className="row card-body">
          <div className="col">
            <div className="mb-3">
              <label className="req">{t('CITIZENSHIP')}</label>
              <select
                required
                className="form-select"
                value={st.request.nationality}
                onChange={(e) => runInAction(() => (st.request.nationality = e.target.value as ECountry))}
              >
                <option value="ru">{t(`countries:${ECountry.RU}`)}</option>
                <option value="by">{t(`countries:${ECountry.BY}`)}</option>
                <option value="kz">{t(`countries:${ECountry.KZ}`)}</option>
                <option value="ua">{t(`countries:${ECountry.UA}`)}</option>
                <option value="-" disabled>
                  -------
                </option>
                {Object.entries(Countries).map(([k, v]) => (
                  <option value={k} key={k}>
                    {v}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label className="req">{t('SURNAME')}</label>
              <input
                required
                type="text"
                className="form-control"
                placeholder={t('SURNAME_PLACEHOLDER') || ''}
                value={st.request.lastName}
                maxLength={50}
                onChange={(e) => runInAction(() => (st.request.lastName = e.target.value))}
              />
            </div>

            <div className="mb-3">
              <label className="req">{t('FIRST_NAME')}</label>
              <input
                required
                type="text"
                className="form-control"
                placeholder={t('FIRST_NAME_PLACEHOLDER') || ''}
                value={st.request.firstName}
                maxLength={50}
                onChange={(e) => runInAction(() => (st.request.firstName = e.target.value))}
              />
            </div>

            <div className="mb-3">
              <label className="req">{t('PATRONYMIC')}</label>
              <input
                required
                type="text"
                className="form-control"
                placeholder={t('PATRONYMIC_PLACEHOLDER') || ''}
                value={st.request.middleName}
                maxLength={50}
                onChange={(e) => runInAction(() => (st.request.middleName = e.target.value))}
              />
            </div>

            <div className="mb-3">
              <label className="req">{t('NICKNAME')}</label>
              <input
                required
                type="text"
                className="form-control"
                placeholder={t('NICKNAME_PLACEHOLDER') || ''}
                value={st.request.alias}
                maxLength={50}
                onChange={(e) => runInAction(() => (st.request.alias = e.target.value))}
              />
            </div>
          </div>

          <div className="col">
            <div className="mb-3">
              <label className="req">{t('PHONE')}</label>
              <input
                required
                type="tel"
                className="form-control"
                placeholder={t('PHONE_PLACEHOLDER') || ''}
                value={st.request.phone}
                maxLength={50}
                onChange={(e) => runInAction(() => (st.request.phone = e.target.value))}
              />
            </div>

            <div className="mb-3">
              <label className="req">{t('PASSPORT')}</label>
              <div className="input-group">
                <input
                  required
                  type="text"
                  className="form-control w-15"
                  placeholder={t('SERIES') || ''}
                  value={st.request.passport!.series}
                  maxLength={4}
                  onChange={(e) => runInAction(() => (st.request.passport!.series = e.target.value))}
                />
                <input
                  required
                  type="text"
                  className="form-control w-20"
                  placeholder={t('NUMBER') || ''}
                  value={st.request.passport!.number}
                  maxLength={8}
                  onChange={(e) => runInAction(() => (st.request.passport!.number = e.target.value))}
                />
                <input
                  required
                  type="date"
                  className="form-control w-25"
                  placeholder={t('ISSUE_DATE') || ''}
                  value={st.request.passport!.issuedAt}
                  onChange={(e) => runInAction(() => (st.request.passport!.issuedAt = e.target.value))}
                />
                <input
                  required
                  type="text"
                  className="form-control w-40"
                  placeholder={t('ISSUED_BY') || ''}
                  value={st.request.passport!.issuedBy}
                  onChange={(e) => runInAction(() => (st.request.passport!.issuedBy = e.target.value))}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="req">{t('PASSPORT_SCAN')}</label>
              <InputFile value={st.passportScan} onChange={(file) => runInAction(() => {
                st.passportScan = file;
                st.request.passportScanId = file?.id;
              })} accept=".zip, .pdf, image/jpeg" deletable={false} />
              <div className="form-text">
                {t('PASSPORT_SCAN_INFO')}
              </div>
            </div>

            <div className="mb-3">
              <label className="req">{t('REG_ADDRESS')}</label>
              <input
                required
                type="text"
                className="form-control"
                placeholder={t('REG_ADDRESS_PLACEHOLDER') || ''}
                value={st.request.regAddress}
                maxLength={255}
                onChange={(e) => runInAction(() => (st.request.regAddress = e.target.value))}
              />
            </div>

            <div className="mb-3">
              <label className="req">{t('MALLING_ADDRESS')}</label>
              <input
                required
                type="text"
                className="form-control"
                placeholder={t('REG_ADDRESS_PLACEHOLDER') || ''}
                value={st.request.postAddress}
                maxLength={255}
                onChange={(e) => runInAction(() => (st.request.postAddress = e.target.value))}
              />
            </div>

            <div className="mb-3">
              <label className="req">{t('LINKS_COMMUNITY')}</label>
              <textarea
                required
                className="form-control"
                placeholder={t('LINKS_COMMUNITY_PLACEHOLDER') || ''}
                value={st.request.urls!.join('\n')}
                maxLength={512}
                onChange={(e) => runInAction(() => (st.request.urls = e.target.value.split('\n')))}
              />
            </div>
          </div>
        </div>

        <div className="card-footer p-3">
          <Button
            type="submit"
            text={mode === 'add' ? 'CREATE_PROFILE' : 'UPDATE_PROFILE'}
            icon={['fas', 'user-plus']}
          />
        </div>
      </form>
    </Loadable>
  );
});

export default AuthorCompose;
