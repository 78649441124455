import { makeAutoObservable, runInAction } from 'mobx';
import { EUserRole, IPayoutDetails, ITracksLimits, IUser } from './rest';
import { API } from './api';
import { toast } from 'react-toastify';
import version from './version';
import i18n from 'i18next';

export interface IAccount {
  id: number;
  email: string;
  role: EUserRole;
  token: string;
}

class Session {
  public user: IUser | null = null;
  public ready: boolean = false;
  public accounts: IAccount[] = [];
  public location: string = window.location.pathname;
  public limits?: ITracksLimits;
  public payoutDetails?: IPayoutDetails[];

  constructor() {
    version.init();
    const m = window.location.pathname.match(/^\/as\/([0-9A-z=]+)$/);
    if (m) {
      localStorage.setItem('token', m[1]);
    }
    makeAutoObservable(this);
    setInterval(this.fetch, 60000);
    localStorage.setItem('return', this.location);
    this.accounts = JSON.parse(window.localStorage.getItem('accounts') || '[]');
    this.fetch().finally(() => runInAction(() => (this.ready = true)));
    setInterval(() => {
      const location = window.location.pathname;
      if (location !== this.location) runInAction(() => (this.location = location));
    }, 200);
    API.Tracks.getLimits()
      .then((limits) => {
        runInAction(() => this.limits = limits);
      }).catch(toast.error);
  }

  fetch = (): Promise<any> => {
    const token = window.localStorage.getItem('token');
    if (token) {
      API.setToken(token);
      return API.Users.getMe()
        .then((res) => {
          API.setToken(res.token);
          window.localStorage.setItem('token', res.token);
          runInAction(() => (this.user = res.user));
          this.addAccount(res.token, res.user);
          if (res.user?.email.match(/^[a-z0-9]{8,32}@monetize\.club$/)) {
            toast.warning(i18n.t('MUST_ACTUAL_EMAIL'), { toastId: 'warning_email', autoClose: false });
          }
        })
        .catch(() => {
          if (API.getStatusCode() === 401) {
            API.setToken(null);
            window.localStorage.removeItem('token');
            runInAction(() => (this.user = null));
            this.removeAccountByToken(token);
          }
        });
    } else {
      return Promise.resolve();
    }
  };

  logout = (): void => {
    if (!this.user) return;
    this.removeAccountByUser(this.user);
    if (this.accounts.length > 0) {
      this.switchAccount(this.accounts[this.accounts.length - 1]);
    } else {
      API.setToken(null);
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('email');
      window.localStorage.removeItem('accounts');
      runInAction(() => {
        this.user = null;
        this.accounts = [];
      });
    }
  };

  addAccount = (token: string, user: IUser): IAccount => {
    this.removeAccountByToken(token);
    const account: IAccount = { id: user.id, email: user.email, role: user.role, token };
    runInAction(() => {
      this.accounts.push(account);
      window.localStorage.setItem('accounts', JSON.stringify(this.accounts));
    });
    return account;
  };

  removeAccountByToken = (token: string): void => {
    runInAction(() => {
      this.accounts = this.accounts.filter((a) => a.token !== token);
      window.localStorage.setItem('accounts', JSON.stringify(this.accounts));
    });
  };

  removeAccountByUser = (user: IUser): void => {
    runInAction(() => {
      this.accounts = this.accounts.filter((a) => a.id !== user.id);
      window.localStorage.setItem('accounts', JSON.stringify(this.accounts));
    });
  };

  switchAccount = (a: IAccount): void => {
    window.localStorage.setItem('token', a.token);
    window.location.replace('/');
  };

  addAccountLogin = (): void => {
    window.localStorage.removeItem('token');
    window.location.replace('/login');
  };

  editPayoutDetails = (details: IPayoutDetails[]): void => {
    runInAction(() => {
      this.payoutDetails = details;
    });
  };


}

export default new Session();
