import './BarChart.scss';
import {views} from '../modules/utils';
import moment from 'moment';
import {useTranslation} from "react-i18next";

const BarChart = ({data}: { data: number[] }) => {
  const {t} = useTranslation();
  if (!data.length) return null;
  const max = Math.max(...data);
  if (!max) return null;
  const k = 24 / max;
  return (
    <div className="bar-chart">
      {data.map((value, index) => {
        const day = index > 0 ? moment().add(-index, 'days').fromNow(false) : t('today');
        return (
          <div className="bar-chart__bar" key={index} title={`${t('VIEWS')}: ${views(value)} (${day})`}>
            <div className="bar-chart__bar__value" style={{height: value * k}}></div>
            <div className="bar-chart__bar__popover">
              <span>{views(value)}</span>
              <span className='mx-1 text-muted'>&#8226;</span>
              <span className='bar-chart__bar__popover-date text-muted'>{day}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BarChart;
