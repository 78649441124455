import Button from '../components/Button';
import { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  submitText?: string;
  submitColor?: 'success' | 'primary';
  submitIcon?: IconProp;
  disabled?: boolean;
  outline?: boolean;
  cancelText?: string;
  onCancelClick(): void;
}

const ModalActions: FC<Props> = ({
  submitText,
  submitColor,
  submitIcon,
  cancelText,
  onCancelClick,
  disabled,
  outline,
}) => {
  return (
    <div className="d-flex justify-content-end mt-4">
      <Button
        text={submitText ?? 'SAVE'}
        color={submitColor ?? 'primary'}
        icon={submitIcon ?? ['fas', 'save']}
        outline={outline ?? false}
        disabled={disabled ?? false}
        type="submit"
      />
      <Button text={cancelText ?? 'CANCEL'} color="secondary" className="ms-2" outline onClick={onCancelClick} />
    </div>
  );
};

export default ModalActions;
